import { Col, Divider, Dropdown, Row, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  DotsVerticalOutline,
  DuplicateOutline,
  ExternalLink,
  ExternalLinkOutline,
  PencilAltOutline,
  TrashOutline,
} from "heroicons-react";
import axios from "axios";
import config from "../../confiq/confiq";
import { useUserRole } from "../../context/userContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ConfirmationModal from "../Modal/confirmationDelete";
import { ArrowUpRight } from "@phosphor-icons/react";

export const Deal = (props) => {
  const { fetchData } = props;
  const userRole = useUserRole();
  const [strapiUrl, setStrapiUrl] = useState("");
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [deal, setDeal] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, []);

  useEffect(() => {
    setDeal(props.product);
  }, [props.product]);

  const handleCopyPromoCode = (promoCode) => {
    message.success(`Copied to clipboard.`);
  };

  const formatValidityDate = (dateString) => {
    if (!dateString) return "Unknown";
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const handleCancel = () => {
    setIsOpenConfirmationModal(false);
  };

  const handleOk = async (selectedProjectId) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}api/deals/remove/${selectedProjectId}`,
        {},
        {
          headers: config.headers,
        }
      );

      if (response?.data?.statusCode === 201) {
        window.location.reload();
        fetchData();
      } else {
        message.error(
          "Oops! Something went wrong. deal could not be deleted. Please try again."
        );
      }
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };
  return (
    <>
      <Row gutter={[16, 16]} >
        {deal?.map((deal, index) => {
          const dealId = deal?.id;

          const handleEditClick = (deal) => {
            window.open(
              `${strapiUrl}admin/content-manager/collectionType/api::deal.deal/${dealId}`
            );
          };

          const handleDeleteClick = async () => {
            setIsOpenConfirmationModal(true);
            setSelectedProjectId(deal?.id);
          };

          const handleCardClick = () => {
            if (userRole !== "Admin") {
              const payload = {
                metric_id: deal?.id,
                metric_type: "deal",
                view_count: 1,
              };

              axios
                .post(`${config.apiUrl}api/metrics/save`, payload, {
                  headers: config.headers,
                })
                .catch((error) => {
                  console.error("Error validating token:", error);
                });
            }
          };

          return (
            <Col
              span={8}
              md={8}
              key={index}
              className="deal-card-col"
              style={{ minWidth: "315px" }}
            >
              <div
                className={`storiesCard dealsCard dealcard${index % 4
                  } p-5 border rounded-md dark-gray  mb-6`}
                key={deal?.id}
              >
                <div>
                  <div className="flex justify-between items-center">
                    <div className="flex justify-between gap-2 items-center">
                      <div className="w-11 h-11 flex items-center partner-img">
                        {deal?.attributes?.vendor?.data?.attributes
                          ?.vendor_Logo && (
                            <div className=" ">
                              <img
                                src={
                                  deal?.attributes?.vendor?.data?.attributes
                                    ?.vendor_Logo?.data[0]?.attributes?.url
                                }
                                alt=""
                              />
                            </div>
                          )}
                      </div>
                      <div className="text-base	font-medium text-black capitalize content-threeline content-oneline">
                        {
                          deal?.attributes?.vendor?.data?.attributes
                            ?.vendor_Name
                        }
                      </div>
                    </div>
                    <div
                      onClick={(deal) => {
                        deal?.prdealDefault();
                      }}
                    >
                      {userRole && userRole === "Admin" && (
                        <div
                          onClick={(deal) => deal?.stopPropagation()}
                          className="w-8 flex justify-end"
                        >
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: "1",
                                  icon: (
                                    <PencilAltOutline
                                      color="#1F2937"
                                      size={16}
                                    />
                                  ),
                                  label: "Edit",
                                  onClick: handleEditClick,
                                },
                                {
                                  key: "2",
                                  icon: (
                                    <TrashOutline color="#1F2937" size={16} />
                                  ),
                                  label: "Delete",
                                  onClick: handleDeleteClick,
                                },
                              ],
                            }}
                            placement="bottomRight"
                          >
                            <div
                              className="cursor-pointer"
                              onClick={(deal) => deal?.stopPropagation()}
                            >
                              <span className="ant-dropdown-link">
                                <DotsVerticalOutline
                                  onClick={(deal) => deal?.stopPropagation()}
                                />
                              </span>
                            </div>
                          </Dropdown>
                        </div>
                      )}
                    </div>
                  </div>
                  <Divider />
                  <div className="text-base font-medium text-[#55AF68] mb-3 content-threeline content-oneline">
                    {deal?.attributes?.deal}
                  </div>
                  {deal?.attributes?.promocode &&
                    <div className="mb-3  Grayish flex flex-wrap gap-2 items-center">
                      Promo code :
                      <CopyToClipboard
                        text={deal?.attributes?.promocode}
                        onCopy={() =>
                          handleCopyPromoCode(deal?.attributes?.promocode)
                        }
                      >
                        <span
                          className="uppercase flex flex-wrap gap-1"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="whitespace-promo">
                            {deal?.attributes?.promocode}
                          </div>
                          <DuplicateOutline
                            width={16}
                            className="text-[#5D5FF3]"
                          />
                        </span>
                      </CopyToClipboard>
                    </div>
                  }
                  <div className=" mb-2 content-threeline content-fiveline">
                    {deal?.attributes?.summary}
                  </div>
                </div>
                <div className="flex justify-between gap-2  w-full mt-auto">
                  <div>
                    {userRole === "Admin" ? (
                      <div className="flex flex-row gap-5 text-sm items-center steel-gray mt-auto">
                        <div className="flex flex-row items-center gap-1">
                          <ArrowUpRight width={22} />
                          {deal?.view_count === null ? 0 : deal?.view_count}
                        </div>
                      </div>
                    ) : (
                      <a
                        href={
                          deal?.attributes?.redirection_URL.startsWith("https")
                            ? deal?.attributes?.redirection_URL
                            : `https://${deal?.attributes?.redirection_URL}`
                        }
                        target="_blank"
                        rel="noreferrer"
                        className="inline-block w-full mt-auto"
                        onClick={handleCardClick}
                      >
                        <div className="purple-saas22 flex gap-1 flex-wrap items-center cursor-pointer">
                          Get Discount  <ArrowUpRight />
                        </div>
                      </a>
                    )}
                  </div>
                  <div>
                    Validity:{" "}
                    {formatValidityDate(deal?.attributes?.validity_Date)}
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      {isOpenConfirmationModal && (
        <ConfirmationModal
          handleOk={handleOk}
          handleCancel={handleCancel}
          selectedProjectId={selectedProjectId}
          open={isOpenConfirmationModal}
          modalDesc="Are you sure want to delete this deal?"
        />
      )}
    </>
  );
};
