import React, { useState } from 'react';
import { Tabs } from 'antd';
import BuyerProfile from './buyerProfile';
import Tools from './tools';
import PartnerProfile from './partnerProfile';
import { useUserRole } from '../../context/userContext';


export default function Profile() {
    const userRole = useUserRole();
    const savedActiveKey = localStorage.getItem('profileActiveTabKey');
    const [activeKey, setActiveKey] = useState(savedActiveKey || '1');


    const items = [
        {
            key: '1',
            label: 'My Profile ',
            children:
                userRole && userRole === 'Buyer' ? <BuyerProfile /> : <PartnerProfile />
        },
        {
            key: '2',
            label: 'Tools',
            children: <Tools />,
        },
    ];

    const handleTabChange = (key) => {
        localStorage.setItem('profileActiveTabKey', key);
        setActiveKey(key);
    };

    return (
        <div>
            <div className='block md:hidden mb-3'>
                <div className='text-xl font-semibold'> Profile</div>
            </div>
            <Tabs
                defaultActiveKey="1"
                activeKey={activeKey}
                items={items}
                className="primary-tab "
                onChange={handleTabChange}
            />
        </div>
    );
}