import React from 'react';
import { Modal, Button, Input } from 'antd';
import { ErrorMessage } from '../../pages/auth/signError';

const DeleteRequestModal = ({
    isVisible,
    onClose,
    onDelete,
    deletionReason,
    setDeletionReason
}) => {

    const handleDeleteRequest = () => {
        onDelete();
        onClose();
    };

    return (
        <Modal
            title="Delete Request"
            visible={isVisible}
            onCancel={onClose}
            footer={[
                <Button key="delete" type="primary" danger onClick={handleDeleteRequest}>
                    Delete
                </Button>,
            ]}
            centered
        >
            <ErrorMessage error={'Confirm deletion of this request and its data. Action cannot be undone'} />
            <p className='mb-2'>Reason for Deletion:</p>
            <Input.TextArea
                value={deletionReason}
                onChange={(e) => setDeletionReason(e.target.value)}
                rows={3}
            />
        </Modal>
    );
};

export default DeleteRequestModal;
