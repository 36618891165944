import React, { useEffect, useState } from 'react';
import { EmptyImage } from '../assets/img';
import { Spin } from 'antd';

function EmptyState({ labelMessage, belowContent }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {loading ?
                <div className='flex items-center justify-center'>
                    <Spin className='loader' tip='Loading...' />
                </div>
                :
                <div className='text-center justify-center'>
                    <div className='text-center flex justify-center'>
                        <img src={EmptyImage} alt='' />
                    </div>
                    <div className='midnight-blue font-semibold mb-2 capitalize'> {labelMessage || "Empty"}</div>
                    <div className='dark-gray'>{belowContent}</div>
                </div>
            }
        </>
    );
}

export default EmptyState;