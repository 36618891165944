import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import axios from "axios";
import config from "../../confiq/confiq";
import {
    Spin
} from "antd";
import { Event } from "../../components/Card/event";
import "./index.scss";
import SavedEmpty from "../../components/SavedEmpty";
import { StaticContent } from ".";

export const Saved = forwardRef((props, ref) => {
    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(false);
    const PAGE_SIZE = 15;

    const fetchData = useCallback(async () => {

        try {
            setLoading(true);
            const response = await axios.get(
                `${config.apiUrl}api/bookmark/event?page=1&pageSize=${PAGE_SIZE}&ind=null&fun=null&cat=null&search=null`,
                {
                    headers: config.headers,
                }
            );
            setProduct(response?.data?.data?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [
        PAGE_SIZE]
    );

    useImperativeHandle(
        ref,
        () => ({
            fetchData: fetchData,
        }),
        [fetchData]
    );

    useEffect(() => {
        fetchData();
    }, [
        PAGE_SIZE,
        fetchData,
    ]);


    return (
        <div className="mt-2">
            <StaticContent />
            {loading ? (
                <Spin className="loader" tip="Loading..." />
            ) : product && product?.length > 0 ? (
                <Event
                    product={product}
                    fetchData={fetchData}
                    setLoading={setLoading}
                />
            ) : (
                <SavedEmpty />
            )}
        </div >
    );
});