import React, { useCallback, useEffect, useState } from "react";
import { Spin, Table, DatePicker } from "antd";
import axios from "axios";
import config from "../../confiq/confiq";
import { Link } from "react-router-dom";
import moment from "moment";
import StatusTag from "../../components/statusTag";
import SavedEmpty from "../../components/SavedEmpty";

const { RangePicker } = DatePicker;

const Inquiries = (props) => {
  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const dateFormat = "DD-MMM-YYYY";
  const getData = useCallback(
    async (start, end) => {
      try {
        const startDate = start ? start : null;
        const endDate = end ? end : null;
        const response = await axios.get(
          `${config.apiUrl}api/admin/inquiry?startDate=${startDate}&endDate=${endDate}`,
          {
            headers: config.headers,
          }
        );
        setRequestData(response?.data?.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDateRange]
  );

  useEffect(() => {
    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateRangeChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedDateRange(dates);
      getData(
        `${moment(dateStrings[0]).format("YYYY-MM-DD")}T00:00:00.000Z`,
        `${moment(dateStrings[1]).format("YYYY-MM-DD")}T23:59:59.000Z`
      );
    } else {
      getData();
    }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text) => (
        <Link to={`/inquiries/${text}`} className="royal-blue">
          {text}
        </Link>
      ),
    },
    {
      title: "Title",
      dataIndex: "request_title",
      key: "request_title",
    },
    {
      title: "Buyer name",
      dataIndex: "user_details",
      key: "user_details",
      render: (text, record) => {
        return `${record?.user_details?.first_name || ""} ${
          record?.user_details?.last_name || ""
        }`;
      },
    },
    {
      title: "Start Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => {
        const formattedDate = moment(date).format("DD-MMM-YYYY");
        return formattedDate;
      },
    },
    {
      title: "Budget Range",
      dataIndex: "budget_range",
      key: "budget_range",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <StatusTag status={status} />,
    },
  ];

  return (
    <>
      <div className="flex items-center justify-between mb-4 ">
        <h4 className="text-xl font-semibold text-black mb-4">Inquiries</h4>
        <div className="flex flex-row items-center gap-2 event-page">
          <RangePicker
            value={selectedDateRange}
            format={dateFormat}
            className="range-picker"
            disabled={!requestData || requestData?.length <= 0}
            onChange={handleDateRangeChange}
            allowClear
          />
        </div>
      </div>
      <Spin spinning={loading}>
        <Table dataSource={requestData} locale={{ emptyText: <div className="w-40 -mt-14 m-auto"> <SavedEmpty /></div> }} columns={columns} />
      </Spin>
    </>
  );
};

export default Inquiries;
