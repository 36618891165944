import React, { useEffect, useState } from 'react';
import { ToolEmpty } from '../../assets/img';
import { Button, Col, Row, Spin, message } from 'antd';
import './index.scss';
import axios from 'axios';
import config from '../../confiq/confiq';
import { Link } from 'react-router-dom';
import { X } from 'heroicons-react';


function Tools() {
    const [toolsData, setToolsData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.apiUrl}api/tools/getAll?page=1&pageSize=50&search=null`, {
                headers: config.headers,
            });

            const tools = response?.data?.data;
            setToolsData(tools);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    const selectedItems = toolsData?.filter(item => item?.is_selected === true);
    const idArray = selectedItems.map(item => item.id);
    const toolDataMap = [...new Set(selectedItems?.map(item => item?.attributes?.category))];

    const handleRemoveTool = async (toolId) => {
        const updatedIdArray = idArray.filter(id => id !== toolId);

        const payload = {
            "tool_id": updatedIdArray
        }

        try {
            await axios.post(`${config.apiUrl}api/tools/save`, payload,
                {
                    headers: config.headers,
                });
            message.success('Tool removed successfully.');
            fetchData();
        } catch (error) {
            console.error('Error removing tool:', error);
            message.error('Failed to remove tool.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? (
                <Spin className='loader' tip='Loading...' />
            ) : (
                <>
                    {selectedItems.length === 0 ? (
                        <div className='flex flex-col justify-center items-center gap-4 max-w-[400px] text-center m-auto h-calc'>
                            <img src={ToolEmpty} alt='Tool Empty' />
                            <div className='font-semibold text-[#111827]'>It looks like there aren’t any tools added to the list!</div>
                            {/* <div>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium. </div> */}
                            <Link to={'/profile/add'}>
                                <Button type='primary'>Add Tool</Button>
                            </Link>
                        </div>
                    ) : (
                        <>
                            <div className='flex justify-between items-center'>
                                <div className='font-medium	text-[14px]'>{selectedItems.length} Tools Added</div>
                                <div>
                                    <Link to={'/profile/add'}>
                                        <Button type='primary'>Add Tool</Button>
                                    </Link>
                                </div>
                            </div>
                            <div className='flex flex-col gap-4'>
                                {toolDataMap?.map((category, index) => (
                                    <div key={index} className='tools-section mb-3'>
                                        <h6 className='font-semibold text-base md:text-lg text-black mb-2'>{category}</h6>
                                        <Row gutter={[16, 16]}>
                                            {selectedItems?.filter(item => item?.attributes?.category === category)
                                                .map((tool) => (
                                                    <Col span={12} md={4} key={tool.id}>
                                                        <div className='pl-2 tool-item flex gap-2 flex-row items-center justify-between '>
                                                            <div className='flex flex-row gap-1 md:gap-3 items-center w-4/5'>
                                                                <img src={tool?.attributes?.image?.data?.attributes?.url} alt={tool.name} className='logo-image' />
                                                                <div className='toolName w-11/12'> {tool?.attributes?.name}</div>
                                                            </div>
                                                            <div className='text-end cursor-pointer' onClick={() => handleRemoveTool(tool.id)}>
                                                                <X width={14} />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ))}
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default Tools;