import { Select } from "antd";
import React from "react";

const { Option } = Select;

export default function FilterShowtext({
  filteredProduct,
  product,
  selectedFunction,
  selectedIndustry,
  selectedCategory,
  resetFilters,
  handleFilterChange,
  setFunctionFilter,
  setSelectedFunction,
  setIndustryFilter,
  setSelectedIndustry,
  setCategoryFilter,
  setSelectedCategory,
  functionTrue,
  showingName,
  guideTrue,
  categoryTrue
}) {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="grid md:flex flex-row items-center gap-2 responsive-filter w-full">
        <div className="hidden md:flex">
          {(selectedFunction || selectedIndustry || selectedCategory) && (
            <div className="cursor-pointer purple-saas22" onClick={resetFilters}>
              Clear Filters
            </div>
          )}
        </div>
        {functionTrue && (
          <Select
            value={selectedFunction}
            onChange={(value) =>
              handleFilterChange(value, setFunctionFilter, setSelectedFunction)
            }
            style={{ width: 150 }}
            placeholder={"Function"}
          >
            <Option value="" disabled>
              Function
            </Option>
            {functionOptions?.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        )}

        <Select
          value={selectedIndustry}
          onChange={(value) =>
            handleFilterChange(value, setIndustryFilter, setSelectedIndustry)
          }
          style={{ width: 150 }}
          placeholder={"Industry"}
        >
          <Option value="" disabled>
            Industry
          </Option>
          {industryOptions?.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
        {categoryTrue && (
          <Select
            value={selectedCategory}
            onChange={(value) =>
              handleFilterChange(value, setCategoryFilter, setSelectedCategory)
            }
            style={{ width: 150 }}
            placeholder={"Category"}
          >
            <Option value="" disabled>
              Category
            </Option>

            {guideTrue ? (
              <>
                {guideCategoryOptions?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </>
            ) : (
              <>
                {categoryOptions?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </>
            )}
          </Select>
        )}
      </div>
    </div>
  );
}

export const functionOptions = [
  { value: "Sales", label: "Sales" },
  { value: "Marketing", label: "Marketing" },
  { value: "Customer Service & Customer Experience", label: "Customer Service & Customer Experience" },
  { value: "Employee Experience", label: "Employee Experience" },
  { value: "Productivity", label: "Productivity" },
  { value: "Human Resources", label: "Human Resources" },
  { value: "Governance, Risk, and Compliance", label: "Governance, Risk, and Compliance" },
];

export const industryOptions = [
  { value: "Technology", label: "Technology" },
  { value: "Telecommunications", label: "eCommerce" },
  { value: "Finance", label: "Finance" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Retail & Ecommerce", label: "Retail & Ecommerce" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Education", label: "Education" },
  { value: "Travel & Hospitality", label: "Travel & Hospitality" },
  { value: "Transportation & Logistics", label: "Transportation & Logistics" },
  { value: "Media & Entertainment", label: "Media & Entertainment" },
  { value: "Energy & Utilities", label: "Energy & Utilities" },
];

export const categoryOptions = [
  { value: "AI", label: "AI" },
  { value: "Startup", label: "Startup" },
  { value: "Leadership", label: "Leadership" },
  { value: "Health", label: "Health" },
  { value: "Productivity", label: "Productivity" },
  { value: "Hiring", label: "Hiring" },
];

export const guideCategoryOptions = [
  { value: "Customer Experience", label: "Customer Experience    " },
  { value: "Marketing Automation", label: "Marketing Automation" },
  { value: "Sales/CRM", label: "Sales/CRM" },
  { value: "CyberSecurity", label: "CyberSecurity" },
  { value: "Productivity", label: "Productivity" },
  { value: "ERP", label: "ERP" },
];
