import React, { useEffect } from 'react';
import {
    Button,
    Col,
    Row,
} from 'antd';
import {
    GIcon,
    signInIllustration2
} from '../../assets/img';
import config from '../../confiq/confiq';
import { AuthLogo } from './signIn';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminSignIn = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const emailFromURL = queryParams.get("email");

            if (!emailFromURL) {
                console.error("Email not found in URL");
                return;
            }

            try {
                const response = await axios.post(
                    `${config.apiUrl}api/user/confirm`,
                    { email: emailFromURL },
                    {
                        headers: config.headers,
                    }
                );

                if (response?.data?.statusCode === 400) {
                    navigate("/linkExpired");
                }
            } catch (error) {
                console.error("Error confirming email:", error);
            }
        };

        fetchData();
    }, [location, navigate]);

    return (
        <div className='container mx-auto sign-page'>
            <AuthLogo />
            <>
                <Row gutter={16}>
                    <Col xs={24} sm={12} className='flex items-center text-center justify-center'>
                        <div>
                            <div className="sign-card">
                                <div className='mb-8'>
                                    <h2 className="text-3xl font-bold mb-3 text-black">Sign in to your account</h2>
                                    <div className='text-[#6B7280]'>
                                        Access our platform by signing in with your Google account
                                    </div>
                                </div>
                                <div>
                                    <a href={`${config.apiUrl}api/auth/google`}
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        <Button block className="flex items-center justify-center google-btn">
                                            <img src={GIcon} alt='google-icon' className='g-icon' /> Google
                                        </Button>
                                    </a>
                                </div>
                                <div className='mt-4'>
                                    By signing up, you agree to our <a href="https://www.saas22.com/terms-and-conditions" className="text-blue-600 font-semibold" target="_blank" rel="noopener noreferrer">Terms & conditions</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={12} className='hidden md:flex items-center text-center justify-center'>
                        <img src={signInIllustration2} alt='signInIllustration' className='w-3/4' />
                    </Col>
                </Row>
            </>
        </div >
    );
};

export default AdminSignIn;