import axios from 'axios';
import config from '../../confiq/confiq';

export const getTickets = async (searchQuery, startDate, endDate, company) => {
    try {
        const response = await axios.get(`${config.apiUrl}api/vendor/getTickets?startDate=${startDate}&endDate=${endDate}&company=${company}&search=${searchQuery}`, {
            headers: config.headers,
        });
        return {
            tickets: response?.data?.data?.tickets || [],
            totalCount: response?.data?.data?.total || 0,
        };
    } catch (error) {
        console.error("Error fetching data:", error);
        return {
            tickets: [],
            totalCount: 0,
        };
    }
};

export const statusMapping = {
    2: 1,
    3: 2,
    4: 3,
    5: 4,
};

export const updateTickets = async (payload) => {
    try {
        const response = await axios.post(`${config.apiUrl}api/vendor/updateTickets`, payload, {
            headers: config.headers,
        });
        return response;
    } catch (error) {
        console.error('Error submitting form:', error);
        throw error;
    }
};

export const getLeadsStage = (statusMatch) => {
    switch (statusMatch) {
        case 1: return "New";
        case 2: return "Engaged";
        case 3: return "Qualified";
        case 4: return "Opportunity";
        case 5: return "Win";
        case 6: return "Loss";
        default: return "";
    }
};

export const getStatusMatch = (leadsStage) => {
    switch (leadsStage) {
        case "New": return 1;
        case "Engaged": return 2;
        case "Qualified": return 3;
        case "Opportunity": return 4;
        case "Win": return 5;
        case "Loss": return 6;
        default: return "";
    }
};

export const fetchDataLocation = async (setCompanyData) => {
    try {
        const response = await axios.get(`${config.apiUrl}api/user/companies`, {
            headers: config.headers,
        });
        setCompanyData(response?.data?.data);
    } catch  {
        // console.log(error)
    }
};