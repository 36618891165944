import {
  Button,
  Drawer,
  Form,
  Input,
  Select,
  message
} from "antd";
import axios from "axios";
import {
  useEffect,
  useState
} from "react";
import config from "../../confiq/confiq";
import moment from "moment";
import TextArea from "antd/es/input/TextArea";
import { useNavigate } from "react-router-dom";

const { Option } = Select;

export const NewRequestDrawer = ({
  visible,
  onClose,
  serviceTitle,
  serviceID,
  description,
  serviceSlug
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  // const [fileList, setFileList] = useState([]);
  const [country, setCountry] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}api/user/freshdesk/location`, {
          headers: config.headers,
        });
        setCountry(response?.data?.data);
      } catch {
        // console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    if (isSubmitting) {
      return;
    }
    const formData = new FormData();
    formData.append('project_id', serviceID ? serviceID : "");
    formData.append('project_slug', serviceSlug ? serviceSlug : "");
    formData.append('request_title', values?.RequestTitle);
    formData.append('currency', values?.length ? values.length : "EUR");
    formData.append('budget_range', values?.amount ? values.amount : "");
    formData.append('location', "Austria");
    formData.append('start_date', moment(values?.startDate).format("YYYY-MM-DD"));
    formData.append('description', description ? description : values?.additionalInfo);
    formData.append('additional_details', values?.additionalInfo);

    // if (fileList.length > 0) {
    //   fileList.forEach((file) => {
    //     formData.append('attachments[]', file.originFileObj);
    //   });
    // }

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${config.apiUrl}api/user/request`,
        formData,
        {
          headers: {
            ...config.headers,
            'Content-Type': 'multipart/form-data'
          }
        }
      );

      if (response?.data?.statusCode === 201) {
        message.success("Request submitted successfully");
        navigate(`/projects/my-request/${response?.data?.data?.ticket_id}`);
        // onClose();
        // window.location.reload();
      } else if (response?.data?.statusCode === 400) {
        message.error("Request not submitted successfully");
      }

    } catch (error) {
      console.error(error);
      message.error("An error occurred while submitting the request");
    } finally {
      setIsSubmitting(false);
    }
  };

  // const handleFileChange = ({ fileList }) => {
  //   const filteredList = fileList.filter(file => {
  //     const isImageOrPdf = file.type.startsWith('image/') || file.type === 'application/pdf';
  //     if (!isImageOrPdf) {
  //       message.error(`${file.name} is not a valid file type (only images and PDFs are allowed).`);
  //     }
  //     return isImageOrPdf;
  //   });
  //   setFileList(filteredList);
  // };

  return (
    <Drawer
      title="New Request"
      width={400}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      maskClosable={false}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{
          RequestTitle: serviceTitle,
          budgetRange: "",
          length: "EUR",
        }}
        disabled={isSubmitting}
      >
        <Form.Item
          name="RequestTitle"
          label={serviceTitle ? "Service requested" : "What do you need help with"}
          rules={[
            {
              required: true,
              message: "Please enter the request title",
            },
          ]}
        >
          <Input
            placeholder="Eg: Saas B2B Optimization"
            disabled={!!serviceTitle}
          />
        </Form.Item>

        <Form.Item label="Budget Range">
          <Input.Group compact className="gap-4 flex">
            <Form.Item name="length" noStyle>
              <Select
                style={{ width: "25%" }}
                placeholder="Currency"
                defaultValue="EUR"
              >
                <Option value="EUR">EUR</Option>
                <Option value="USD">USD</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="amount"
              noStyle
              rules={[
                {
                  pattern: /^[0-9]*$/,
                  message: "Please enter a valid numeric value.",
                },
              ]}
            >
              <Input style={{ width: "75%" }} placeholder="Eg: 100" />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name="location"
          label="Location"
          rules={[
            {
              required: true,
              message: "Please select a location",
            },
          ]}
        >
          <Select
            placeholder="Select Country"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {country && country.map((location) => (
              <Option key={location?.id} value={location?.name}>
                {location?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="additionalInfo"
          label="Additional Info"
          rules={[
            {
              max: 250,
              message: "Maximum 250 characters allowed!",
            },
            {
              required: true,
              message: "Please provide additional information",
            },
          ]}
        >
          <TextArea placeholder="Elaborate here" maxLength={250} rows={4} />
        </Form.Item>

        {/* <Form.Item name="fileAttachment" label="File Attachment">
          <Upload
            onChange={handleFileChange}
            fileList={fileList}
            multiple
          >
            <Button className='cursor-pointer purple-saas22 p-0 border-0 h-auto shadow-none border-none upload-button-custom'
              disabled={fileList.length >= 5}
            >
              <span className='purple-saas22'>+ Upload files</span>
            </Button>
          </Upload>
        </Form.Item> */}

        <Form.Item className="text-end">
          <Button type="primary" htmlType="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
