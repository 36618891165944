import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  message
} from "antd";
import { welcomeIllustration } from "../../assets/img";
import {
  useLocation,
  useNavigate
} from "react-router-dom";
import axios from "axios";
import config from "../../confiq/confiq";
import { useAuth } from "../../context";
import { ErrorMessage } from "./signError";
import { AuthLogo } from "./signIn";

function WelcomeBackSignIn() {
  const [emailError, setEmailError] = useState(null);
  const { login, setToken } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const email = location?.state?.email;

  const onFinish = async (values) => {
    const payload = {
      email: values?.email,
      password: values?.password,
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}api/user/email/login`,
        payload
      );

      if (response?.data?.statusCode === 201) {
        const userToken = response?.data?.access_token;
        const userResponseRole = response?.data?.role_name;

        if (userToken && userResponseRole) {
          setToken(userToken);
          login(userResponseRole);

          switch (userResponseRole) {
            case "Admin":
            case "Partner":
              navigate("/home");
              window.location.reload();
              break;
            case "Buyer":
              const onboardingStatus = response?.data?.onboarding_status;

              if (onboardingStatus === true) {
                navigate("/home");
                window.location.reload();
              } else if (onboardingStatus === false) {
                navigate("/onboardingForm");
                window.location.reload();
                message.warning("Please update onboarding form first");
              } else {
                console.error('Unknown onboarding status');
              }
              break;
            default:
              console.error('Unknown role');
          }

        } else {
          console.error('userToken or userRole is not available');
        }

      } else if (response?.data?.statusCode === 403) {
        setEmailError("Wrong email or password");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleForgot = () => {
    navigate("/passwordRecovery", { state: { email } });
  };

  return (
    <div className="container mx-auto sign-page">
      <AuthLogo />
      <Row gutter={16} >
        <Col
          xs={24}
          sm={12}
          className="flex items-center text-center justify-center"
        >
          <div>
            <ErrorMessage error={emailError} />
            <div className="sign-card">
              <div className="mb-8">
                <h2 className="text-3xl font-bold mb-3 text-black">
                  Welcome back
                </h2>
              </div>
              <Form
                name="signinForm"
                onFinish={onFinish}
                layout="vertical"
                initialValues={{ email: email }}
              >
                <Form.Item label="Business Email" name="email">
                  <Input placeholder="Enter your business email" disabled />
                </Form.Item>
                <div className="relative">
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your password!",
                      },
                    ]}
                    hasFeedback
                  >
                    <Input.Password placeholder="Enter your password" />
                  </Form.Item>
                  <span className="purple-saas22 cursor-pointer absolute forgot-password-div" onClick={handleForgot}>
                    Forgot your password?
                  </span>
                </div>
                <Form.Item className="mt-10">
                  <Button htmlType="submit" className="purple-btn" block>
                    Continue
                  </Button>
                </Form.Item>
                <div className="mt-2">
                  By signing up, you agree to our{" "}
                  <a
                    href="https://www.saas22.com/terms-and-conditions"
                    className="text-blue-600 font-semibold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & conditions
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={12}
          className="hidden md:flex items-center text-center justify-center"
        >
          <img src={welcomeIllustration} alt="signInIllustration" className="h-3/5"/>
        </Col>
      </Row>
    </div>
  );
}

export default WelcomeBackSignIn;
