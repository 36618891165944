import React from 'react';
import { Button, Modal } from 'antd';
import { Locksignin } from '../../assets/img';
import { useNavigate } from 'react-router-dom';

const SignInPopup = ({ visible, onClose }) => {
    const navigate = useNavigate();
    const handleSignIn = () => {
        const state = { signinHide: true };
        navigate('/signin', { state })
    };

    return (
        <Modal
            title="Sign In"
            visible={visible}
            onCancel={onClose}
            width={450}
            className='footer-text-center signin'
            footer={[
                <div>
                    <span className='text-[#333333] font-semibold' >New to Saas22? </span>
                    <span className='purple-saas22 font-semibold cursor-pointer' onClick={() => navigate("/applyToJoinForm")}>Apply to join</span>
                </div>
            ]}
            centered
        >
            <div className='text-center flex flex-col gap-4 items-center justify-center px-10 py-2'>
                <img src={Locksignin} alt='sign in' className='' />
                <span>To explore and access the web app, please sign in to your account.</span>
                <div className='text-center flex justify-center mt-2'>
                    <Button onClick={handleSignIn} className='purple-saas22 font-semibold cursor-pointer' type='primary' >Sign In</Button>
                </div>
            </div>
        </Modal>
    );
};

export default SignInPopup;