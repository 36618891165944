import { Dropdown, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  BookmarkOutline,
  CalendarOutline,
  DotsVerticalOutline,
  EyeOutline,
  PencilAltOutline,
  StarOutline,
  TrashOutline,
} from "heroicons-react";
import { useAuth } from "../../context";
import { postFavourtie } from "../../assets/img";
import config from "../../confiq/confiq";
import axios from "axios";
import { useUserRole } from "../../context/userContext";
import ConfirmationModal from "../Modal/confirmationDelete";
import SignInPopup from "../Modal/signIn";
import moment from "moment";
import { extractDomainName } from "../../utils";
import { ArrowUpRight } from "@phosphor-icons/react";

export const WebfeedCard = (props) => {
  const { fetchData, setLoading } = props;
  const userRole = useUserRole();
  const { userToken } = useAuth();
  const [strapiUrl, setStrapiUrl] = useState("");
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [project, setProject] = useState([]);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, []);

  
  useEffect(() => {
    setProject(props.product);
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, [props.product]);

  const updateBookmarkStatus = (storyId, isBookmarked) => {
    setProject((prevproject) =>
      prevproject?.map((story) =>
        story?.id === storyId ? { ...story, isBookmarked } : story
      )
    );
  };


  const handleCancel = () => {
    setIsOpenConfirmationModal(false);
  };

  const handleOk = async (selectedProjectId) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}api/webFeed/remove/${selectedProjectId}`,
        {},
        {
          headers: config.headers,
        }
      );
      if (response?.data?.statusCode === 201) {
        message.success("webFeed deleted successfully.");
        fetchData();
        setLoading(true);
      } else {
        message.error(
          "Oops! Something went wrong. webFeed could not be deleted. Please try again."
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };

  return (
    <>
      {project.map((webFeed, index) => {
        const webFeedId = webFeed?.id;

        const handleEditClick = (event) => {
          window.open(
            `${strapiUrl}admin/content-manager/collectionType/api::web-feed.web-feed/${webFeedId}`
          );
        };

        const handleDeleteClick = async () => {
          setIsOpenConfirmationModal(true);
          setSelectedProjectId(webFeed?.id);
        };

        const postBookmark = async () => {
          if (!userRole || !userToken) {
            setShowSignInPopup(true);
          } else {
            const payload = {
              bookmark_id: webFeed?.id,
              bookmark_type: "webfeed",
            };

            try {
              const response = await axios.post(
                `${config.apiUrl}api/bookmark/save`,
                payload,
                {
                  headers: config.headers,
                }
              );
              if (response?.data?.statusCode === 201) {
                updateBookmarkStatus(webFeedId, true);
              } else {
                message.error(
                  "Sorry, we couldn't add this to your saved items. Please try again later."
                );
              }
            } catch (error) {
              console.error("Error validating token:", error);
            }
          }
        };

        const removeBookmark = async () => {
          if (!userRole || !userToken) {
            setShowSignInPopup(true);
          } else {
            const payload = {
              bookmark_id: webFeed?.id,
              bookmark_type: "webfeed",
            };
            try {
              const response = await axios.post(
                `${config.apiUrl}api/bookmark/remove`,
                payload,
                {
                  headers: config.headers,
                }
              );
              if (response?.data?.statusCode === 201) {
                updateBookmarkStatus(webFeedId, false);
              } else {
                message.error(
                  "Sorry, we couldn't remove this project from your favorites. Please try again later."
                );
              }
            } catch (error) {
              console.error("Error validating token:", error);
            }
          }
        };

        const handleCardClick = () => {
          if (userRole !== "Admin") {
            const payload = {
              metric_id: webFeed?.id,
              metric_type: "webfeed",
              view_count: 1,
            };
            axios
              .post(`${config.apiUrl}api/metrics/save`, payload, {
                headers: config.headers,
              })
              .catch((error) => {
                console.error("Error validating token:", error);
              });
          }
        };

        const externalLink = webFeed?.attributes?.externalLink;
        const domainName = externalLink ? extractDomainName(externalLink) : "";

        return (
          <div
            key={webFeed.id}
            onClick={handleCardClick}
            className="cursor-pointer"
            rel="noopener noreferrer"
          >
            <div
              className="storiesCard p-5 border rounded-md dark-gray  mb-6"
              key={webFeed?.id}
            >
              <div className="flex items-self-start justify-between mb-2">
                <a
                  href={
                    webFeed?.attributes?.externalLink.startsWith("https")
                      ? webFeed?.attributes?.externalLink
                      : `https://${webFeed?.attributes?.externalLink}`
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="inline-block	w-full"
                >
                  <div className="text-lg md:text-xl font-semibold text-black" style={{ display: 'inline' }}>
                    {webFeed?.attributes?.title}
                    <span style={{ display: 'inline-block', marginLeft: '4px', verticalAlign: 'middle' }}>
                      <ArrowUpRight width={22} />
                    </span>
                  </div>
                </a>
                {userToken &&
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    className="flex justify-end w-20 md:w-32"
                  >
                    {userRole === "Admin" ? (
                      <div
                        onClick={(event) => event?.stopPropagation()}
                        className="w-8 flex justify-end"
                      >
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: "1",
                                icon: (
                                  <PencilAltOutline color="#1F2937" size={16} />
                                ),
                                label: "Edit",
                                onClick: handleEditClick,
                              },
                              {
                                key: "2",
                                icon: <TrashOutline color="#1F2937" size={16} />,
                                label: "Delete",
                                onClick: handleDeleteClick,
                              },
                            ],
                          }}
                          placement="bottomRight"
                        >
                          <div
                            className="cursor-pointer"
                            onClick={(event) => event?.stopPropagation()}
                          >
                            <span className="ant-dropdown-link">
                              <DotsVerticalOutline
                                onClick={(event) => event?.stopPropagation()}
                              />
                            </span>
                          </div>
                        </Dropdown>
                      </div>
                    ) : (
                      <div
                        onClick={(event) => event?.stopPropagation()}
                        className="cursor-pointer"
                      >
                        {webFeed?.isBookmarked === false ? (
                          <StarOutline onClick={postBookmark} />
                        ) : (
                          <img
                            src={postFavourtie}
                            alt="post fav"
                            className="h-6"
                            onClick={removeBookmark}
                          />
                        )}
                      </div>
                    )}
                  </div>
                }
              </div>
              <a
                href={
                  webFeed?.attributes?.externalLink.startsWith("https")
                    ? webFeed?.attributes?.externalLink
                    : `https://${webFeed?.attributes?.externalLink}`
                }
                target="_blank"
                rel="noreferrer"
              >
                <div className="mb-4 content-threeline">
                  {webFeed?.attributes?.summary}
                </div>
                <div className="flex flex-row gap-4 items-end md:items-center  justify-between">
                  <div className="steel-gray grid md:flex  justify-between flex-row gap-2">
                    <span className="">
                      {domainName && <>{domainName} </>}
                    </span>
                    <span className='text-[#D0D4DA] hidden md:block font-medium'>|</span>

                    <div className="flex flex-row gap-2 steel-gray items-center ">
                      <CalendarOutline size={18} />
                      Published {webFeed?.attributes?.publishedAt && moment(webFeed?.attributes?.publishedAt).fromNow()}
                    </div>
                  </div>
                  {userRole === "Admin" && (
                    <div className="flex flex-row gap-5 text-sm items-center steel-gray">
                      <div className="flex flex-row items-center gap-1">
                        <EyeOutline size={18} />
                        {webFeed?.view_count === null ? 0 : webFeed?.view_count}
                      </div>
                      <div className="flex flex-row items-center gap-1">
                        <BookmarkOutline size={18} />
                        {webFeed?.fav_count === null ? 0 : webFeed?.fav_count}
                      </div>
                    </div>
                  )}
                </div>
              </a>
            </div>
          </div>
        );
      })}
      <SignInPopup
        visible={showSignInPopup}
        onClose={() => setShowSignInPopup(false)}
      />
      {isOpenConfirmationModal && (
        <ConfirmationModal
          handleOk={handleOk}
          handleCancel={handleCancel}
          selectedProjectId={selectedProjectId}
          open={isOpenConfirmationModal}
          modalDesc="Are you sure want to delete this webfeed?"
        />
      )}
    </>
  );
};


