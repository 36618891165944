import { message } from "antd";

export const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export function isValidUrl(url) {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
}

export const getCurrentTimeGreeting = () => {
  const currentHour = new Date().getHours();

  if (currentHour >= 0 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 17) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export const isOnline = () => {
  try {
    return navigator?.onLine;
  } catch (error) {
    console.error("Error checking online status:", error);
    return true;
  }
};
export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const validateLinkedInUrl = (_, value) => {
  // const linkedInUrlRegex = /https:\/\/www\.linkedin\.com\/(company|in)\/[^/]+\/?/;
  // if (value && !linkedInUrlRegex.test(value)) {
  //     return Promise.reject(new Error('Please enter a valid LinkedIn URL'));
  // }
  // return Promise.resolve();
};

export const LabelText = ({ label, text }) => (
  <div className="mb-5">
    <div className="Grayish mb-1 capitalize">{label}</div>
    <div className="font-semibold text-[#333333] capitalize">{text}</div>
  </div>
);

export const dateFormat = "DD-MMM-YYYY";

export function extractDomainName(url) {
  try {
    const hostname = new URL(url).hostname.toLowerCase();
    let parts = hostname.split('.');
    if (parts[0] === 'www') {
      parts = parts.slice(1);
    }

    if (parts.length >= 2) {
      const domain = parts[parts.length - 2];
      return domain;
    } else if (parts.length === 1) {
      return parts[0];
    } else {
      return null;
    }
  } catch (error) {
    console.error("Invalid URL:", error);
    return null;
  }
}