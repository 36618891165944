import { Row, Col, Form, Input, Button, Radio, Checkbox, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import {
  getServicePartner,
  getAreaList,
  validateEmail,
  postPartnerProgram,
  postPartnerProgramRegister,
} from "./apiService";
import { Link, useNavigate } from "react-router-dom";
import { Saas22Logo } from "../../assets/img";
import { useCountryTimeZoneData, useUserInfo } from "../../CommonApiService/commonApi";
import { useAuth } from "../../context";

const { TextArea } = Input;
const { Option } = Select;

export const PartnerProgramFormPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { userToken } = useAuth();
  const { countryWithTimezone } = useCountryTimeZoneData(userToken);
  const { userInfo } = useUserInfo(userToken);
  const [disabledFields, setDisabledFields] = useState({});
  const { login } = useAuth();
  const [loading, setLoading] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const userToken = urlParams.get('access_token');
        const userRole = urlParams.get('role_name');

        if (userToken && userRole) {
          localStorage.setItem('userToken', userToken);
          localStorage.setItem('userRole', userRole);
          login(userRole);

          switch (userRole) {
            case 'Partner':
              navigate('/partnerProgramForm');
              if (window.location.pathname === '/partnerProgramForm') {
                window.location.reload();
              }
              break;
            default:
              break;
          }
        } else {
          console.error('Incomplete or missing query parameters');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [login, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    getServicePartner()
      .then((data) => {
        let newList = [];
        // eslint-disable-next-line array-callback-return
        data?.data?.map((item) => {
          newList.push({
            value: item.id,
            label: item.name,
          });
        });
      })
      .catch((error) => console.error("Error fetching data:", error));

    getAreaList()
      .then((data) => {
        let newList = [];
        // eslint-disable-next-line array-callback-return
        data?.data?.map((item) => {
          newList.push({
            value: item.id,
            label: item.name,
          });
        });
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const initialValues = {
      firstName: userInfo?.first_name,
      lastName: userInfo?.last_name,
      email: userInfo?.email,
      title: userInfo?.title,
      company: userInfo?.company,
      website: userInfo?.partner_details?.website,
      country: userInfo?.country_timezone,
      phonenumber: userInfo?.phone,
      linkedURL: userInfo?.social_links?.linkedin,
      preferenceIdentity: userInfo?.partner_details?.type || "SaaS Vendor",
      partner_feature_consent: userInfo?.partner_details?.blog_subscription === false ? "no" : "yes",
      servicesOffered: userInfo?.partner_details?.service,
      additionalInfo: userInfo?.partner_details?.description,
      updates_subscription: userInfo?.partner_details?.updates_subscription !== false,
      agreed_to_terms: userInfo?.partner_details?.agreed_to_terms !== false
    };

    form.setFieldsValue(initialValues);

    const disabledFieldsState = {};
    Object.keys(initialValues).forEach((key) => {
      if (initialValues[key]) {
        disabledFieldsState[key] = true;
      }
    });
    setDisabledFields(disabledFieldsState);

  }, [form, userInfo]);

  const onFinish = async (values) => {
    const emailBody = { email: values?.email };

    const payload = {
      first_name: values?.firstName,
      last_name: values?.lastName,
      type: values?.preferenceIdentity,
      email: values?.email,
      company: values?.company || "",
      title: values?.title || "",
      phone: values?.phonenumber,
      country_timezone: values?.country,
      social_links: { linkedin: values?.linkedURL },
      website: values?.website,
      service: values?.servicesOffered,
      description: values?.additionalInfo,
      updates_subscription: values?.updates_subscription,
      agreed_to_terms: values?.agreed_to_terms,
      blog_subscription: values?.partner_feature_consent === "yes" ? true : values?.partner_feature_consent === "no" ? false : true,
      image_id: userInfo?.image_id || null,
    };

    try {
      if (userInfo && userInfo.first_name && userInfo.last_name) {
        payload.is_new_user = false;
        await postPartnerProgramRegister(payload);
        navigate("/home");
      } else {
        const data = await validateEmail(emailBody);
        if (data?.statusCode === 400) {
          form.setFields([{ name: "email", errors: ["Email already exists"] }]);
        } else if (data?.statusCode === 201) {
          await postPartnerProgram(payload);
          navigate("/successPage");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleLogoOnclick = () => {
    window.location.href = 'https://www.saas22.com/partners';
  }

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <div className="pt-8 items-center flex flex-col gap-6 partner-page">
      <div className='auth-logo-parent fixed top-0 left-0 bg-white w-full pl-6 py-2 pt-3 z-10'>
        <img src={Saas22Logo} alt='auth-logo' className='auth-logo cursor-pointer' onClick={handleLogoOnclick} />
      </div>

      {loading ? (
        <div className='flex items-center justify-center'>
          <Spin className='loader' tip='Loading...' />
        </div>
      ) : (

        <div className="mt-10 md:mt-0 md:w-10/12 w-full">
          <div className="mb-5 ">
            <h1 className="text-xl md:text-3xl font-bold mb-2 text-center">
              {userInfo && (userInfo?.first_name && userInfo?.last_name) ? "Partner Onboarding Program "
                : "Apply to Saa22 Partner Program"}
            </h1>
            <div className="text-center">Join our network of AI and SaaS experts and help us create the world's most loved software marketplace</div>
          </div>
          <Form
            name="partner_program"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            className="bg-whitemx-auto rounded px-2 md:px-8 pb-8 mb-4 "
          >
            <Form.Item className="mb-2">
              <p className="text-lg md:text-xl font-semibold">Personal Information</p>
            </Form.Item>
            <Row gutter={16}>
              <Col span={24} md={12}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    { required: true, message: "" },
                    { max: 30, message: 'Maximum 30 characters allowed' },
                  ]}
                >
                  <Input placeholder="Enter the first name" disabled={disabledFields.firstName} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true, message: "" },
                  { max: 30, message: 'Maximum 30 characters allowed' },]}
                >
                  <Input placeholder="Enter the last name" disabled={disabledFields.lastName} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    { required: true, message: "" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input placeholder="Enter your work email" disabled={disabledFields.email} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[{ required: true, message: "" },
                  { max: 50, message: 'Maximum 50 characters allowed' },]}
                >
                  <Input placeholder="Eg: Product Manager" disabled={disabledFields.title} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="company"
                  label="Company"
                  rules={[{ required: true, message: "" },
                  { max: 100, message: 'Maximum 100 characters allowed' },]}
                >
                  <Input placeholder="Enter the company name" disabled={disabledFields.company} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="website"
                  label="Website"
                  rules={[
                    { required: true, message: "" },
                    () => ({
                      validator(_, value) {
                        const urlPattern =
                          /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[\dA-Za-z]{2,}(\.[\dA-Za-z]{2,})(\.[\dA-Za-z]{2,})?/;

                        if (value && !urlPattern.test(value)) {
                          return Promise.reject(
                            new Error("Please enter a valid URL")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input placeholder="Website URL" disabled={disabledFields.website} />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="country"
                  label="Country / Timezone"
                >
                  <Select
                    showSearch
                    placeholder="Select Country / Timezone"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countryWithTimezone && countryWithTimezone?.map((location) => (
                      <Option key={location?.id} value={location['country&timezone']}>
                        {location['country&timezone']}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="phonenumber"
                  label="Phone Number"
                  rules={[
                    { max: 15, message: 'Maximum 15 characters allowed' },
                    () => ({
                      validator(_, value) {
                        const phoneNumerRegex = /^[0-9]{8,15}$/;
                        if (value && !phoneNumerRegex.test(value)) {
                          return Promise.reject(
                            new Error("Please enter a valid Phone number")
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder="Enter the phone number"
                  />
                </Form.Item>
              </Col>
              <Col span={24} md={12}>
                <Form.Item
                  name="linkedURL"
                  label="LinkedIn URL"
                >
                  <Input placeholder="Enter the URL" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item className="mb-2">
              <p className="text-xl font-semibold ">Partnership Type</p>
            </Form.Item>
            <Form.Item
              name="preferenceIdentity"
              label="Select the type that aligns with your preferences or identity"
              rules={[{ required: true, message: "" }]}
            >
              <Radio.Group >
                <Radio value="SaaS Vendor" className="w-full md:w-auto">SaaS Vendor</Radio>
                <Radio value="Channel Partner / Agency" className="w-full md:w-auto">Channel Partner / Agency</Radio>
                <Radio value="Individual / Consultant / Freelancer" className="w-full md:w-auto">Individual / Consultant / Freelancer</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name="servicesOffered"
              label="Services Provided"
            >
              <TextArea
                maxLength={400}
                style={{ height: 120, resize: "none" }}
                placeholder="Eg: ERP Consulting and SI, Web Development, ... "
              />
            </Form.Item>
            <Form.Item
              name="additionalInfo"
              label="Additional Information"
              className="mb-2"
              rules={[{ max: 250, message: 'Maximum 250 characters allowed' },]}
            >
              <TextArea
                maxLength={400}
                style={{ height: 120, resize: "none" }}
                placeholder="Please share a bit about your company and how you think we could work together"
              />
            </Form.Item>
            <Form.Item name="updates_subscription" valuePropName="checked">
              <Checkbox defaultChecked={userInfo?.partner_details?.updates_subscription !== false}>
                I'd like to sign up for the latest news and updates from SaaS22
              </Checkbox>
            </Form.Item>
            <Form.Item
              name="partner_feature_consent"
              label="We will be running a blog which features Partners from across the globe. Would you like to be featured?"
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Radio.Group >
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>
            {userInfo && (userInfo?.first_name && userInfo?.last_name) ? "" :
              <>
                <Form.Item
                  name="agreed_to_terms"
                  onChange={handleCheckboxChange}
                >
                  <Checkbox>
                    I have read & agreed to SaaS22's <Link to={'https://www.saas22.com/terms-and-conditions'} className="underline font-medium">terms & conditions</Link> and <Link to={'https://www.saas22.com/privacy-policy'} className="underline font-medium">privacy policy</Link>.
                  </Checkbox>
                </Form.Item>
              </>
            }
            <Form.Item className="flex justify-center gap-4 items-center ">
              <div className="flex justify-center items-center">
                {userInfo && (userInfo?.first_name && userInfo?.last_name) ?
                  <Button className="ml-4" type="primary" htmlType="submit">
                    Confirm
                  </Button> :
                  <>
                    <Button
                      type="primary"
                      className="ant-btn-secondary"
                      htmlType="button"
                    >
                      Cancel
                    </Button>
                    <Button className="ml-4" type="primary" htmlType="submit" disabled={!isChecked}>
                      Submit
                    </Button>
                  </>
                }
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
    </div>
  );
};