import React from 'react';
import { searchEmptyImage } from '../assets/img';
import SavedEmpty from './SavedEmpty';

function SearchEmpty({ filteredProduct }) {
    return (
        <div className='text-center justify-center'>
            {filteredProduct === 0 || filteredProduct === undefined || filteredProduct === null ?
                <SavedEmpty /> :
                <>
                    <div className='text-center flex justify-center py-4 mb-5'>
                        <img src={searchEmptyImage} alt='' className='h-[200px] w-auto' />
                    </div>
                    <div className='midnight-blue font-semibold mb-2'>No search results found</div>
                    <div className='dark-gray'>No content matching your criteria. Try searching for something else.</div>
                </>
            }
        </div>
    );
}

export default SearchEmpty;