import React, {
    useEffect,
    useState
} from 'react';
import {
    Dropdown,
    Space,
    Tag,
} from 'antd';
import {
    Saas22Logo,
    SignInIcon,
    downHeaderArrow
} from '../../assets/img';
import './Header.scss';
import {
    Menu,
} from 'heroicons-react';
import { useAuth } from '../../context';
import {
    useNavigate
} from 'react-router-dom';
import axios from 'axios';
import config from '../../confiq/confiq';
import { useUserRole } from '../../context/userContext';
import MobileMenu from '../Sidebar/mobileMenu';
import SignInPopup from '../Modal/signIn';

function Header(props) {
    const [open, setOpen] = useState(false);
    const [showSignInPopup, setShowSignInPopup] = useState(false);

    const { login, logout } = useAuth();
    const userRole = useUserRole();
    const [userProfile, setuseProfile] = useState();
    const navigate = useNavigate();
    const { userToken } = useAuth();

    useEffect(() => {
        axios.get(`${config.apiUrl}api/user/info`, {
            headers: config.headers,
        }
        ).then(response => {
            setuseProfile(response?.data?.data);
        }).catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);

    const handleLogout = () => {
        const logoutRedirect = {
            Admin: '/admin/signin',
            Partner: '/signin',
        };

        const redirectPath = logoutRedirect[userRole] || '/signin';
        const state = userRole ? {} : { signinHide: true };
        navigate(redirectPath, { state });
        localStorage.clear();
        window.location.reload();
        setTimeout(() => {
            login(null);
        }, 1000);
    };

    const handleLogoOnclick = () => {
        if (userToken) {
            navigate('/home')
        } else {
            navigate('/resources')
        }
    }

    return (
        <div className=' bg-white Saas-header h-16  px-6 md:px-10 xs:px-2 flex items-center shadow-md justify-between '>
            <div className='flex'>
                <Space size={[24, 16]} className='header-gap' wrap style={{ cursor: 'pointer' }}>
                    <div className='w-4 min-w-20px '>
                        <div className='hidden md:block'>
                            {props?.isSidebar ? (
                                <div onClick={props?.sidebarOpen} >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </div>
                            ) : (
                                <Menu onClick={props?.sidebarClose} />
                            )}
                        </div>
                        <div className='block md:hidden'>
                            {open ?
                                <div onClick={() => setOpen(false)} >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                :
                                <Menu onClick={() => setOpen(true)} />
                            }
                        </div>
                    </div>
                    <img src={Saas22Logo} alt='Logo' className='h-5 md:h-7 ' onClick={handleLogoOnclick} />
                    {(userRole === 'Partner' && userProfile?.type === "expert") ? (
                        <Tag className='expert-tag'>Expert</Tag>
                    ) : userRole === 'Partner' ? (
                        <Tag color='blue'>Partner</Tag>
                    ) : userRole === 'Admin' ? (
                        <Tag className='admin-tag'>Admin</Tag>
                    ) : (
                        ''
                    )}
                </Space>
            </div>
            <div>
                {userProfile ?
                    <div className='flex gap-4 items-center'>
                        <Dropdown menu={
                            {
                                items: [
                                    {
                                        key: '1',
                                        label:
                                            <div onClick={() => {
                                                handleLogout();
                                                logout();
                                            }} >Logout</div>
                                    },
                                ]
                            }}>
                            <Space size={[8, 4]} className='cursor-pointer'>

                                <div className='h-8 w-8 rounded-full borde flex items-center justify-center overflow-hidden'>
                                    <img src={userProfile.image ? userProfile?.image?.path : SignInIcon} alt='User Avatar' />
                                </div>
                                <img src={downHeaderArrow} alt='Logo' className='hidden sm:inline xs:inline' />
                            </Space>
                        </Dropdown>
                    </div>
                    :
                    <Space size={[4, 16]} className='cursor-pointer purple-saas22 font-bold flex justify-between items-center' onClick={() => {
                        if (!userRole || !userToken) {
                            setShowSignInPopup(true);
                        } else {
                            handleLogout();
                            logout();
                        }
                    }} >
                        <img src={SignInIcon} alt='Logo' />
                        <span className='text-sm' >Sign In</span>
                    </Space>
                }
                <MobileMenu
                    setOpen={setOpen}
                    open={open}
                />
                <SignInPopup
                    visible={showSignInPopup}
                    onClose={() => setShowSignInPopup(false)}
                />
            </div>
        </div>
    );
}

export default Header;