import React, {
    useEffect,
    useState
} from 'react';
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Spin,
    message
} from 'antd';
import {
    passwordIllustration
} from '../../assets/img';
import axios from 'axios';
import config from '../../confiq/confiq';
import { useNavigate } from 'react-router-dom';
import { AuthLogo } from './signIn';
import { ErrorMessage } from './signError';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [authData, setAuthData] = useState();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const emailFromURL = queryParams.get('email');
        const randomNumber = queryParams.get('reset_pass');

        const fetchData = async () => {
            if (emailFromURL && randomNumber) {
                await validateToken(emailFromURL, randomNumber);
                setLoading(false);
            } else {
                console.error('Email or randomNumber is missing in the URL');
            }
        };

        fetchData();
    }, []);

    const validateToken = async (email, randomNumber) => {
        setEmail(email)
        if (randomNumber) {
            const payload = {
                "email": email,
                "reset_pass": randomNumber
            };
            try {
                const response = await axios.post(`${config.apiUrl}api/user/email/verify`, payload);
                if (response?.data?.statusCode === 201) {
                    setAuthData(response?.data?.data);
                    message.success('Email verified successfully');
                } else {
                    setEmailError('Email not verified');
                }
            } catch (error) {
                console.error('Error validating token:', error);
            }
        } else {
            console.error('Token is missing in the URL');
        }
    };


    const onFinish = async (values) => {
        try {
            const payload = {
                auth0_ref: authData,
                email: email,
                password: values.password
            };
            const response = await axios.post(`${config.apiUrl}api/user/password/reset`, payload);

            if (response?.data?.statusCode === 201) {
                navigate("/signin");
                message.error("reset password updated successsfully");
            } else if (response?.data?.statusCode === 400) {
                message.error("reset password not updated");
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };


    return (
        <div className='container mx-auto sign-page'>
            <AuthLogo />
            <Row gutter={16}>
                <Col xs={24} sm={12} className='flex items-center text-center justify-center'>
                    {loading ? (
                        <Spin className='loader' tip='Loading...' />
                    ) : (
                        <div>
                            <ErrorMessage error={emailError} />
                            <div className="sign-card">
                                <div className='mb-8'>
                                    <h2 className="text-3xl font-bold mb-1 text-black">Reset Your Password</h2>
                                    <div className='text-[#6B7280]'>What would you like your new password to be?</div>
                                </div>
                                <Form
                                    name="signinForm"
                                    onFinish={onFinish}
                                    layout="vertical"
                                    form={form}
                                >
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your password!',
                                            },
                                            {
                                                min: 8,
                                                message: 'Password must be at least 8 characters long.',
                                            },
                                        ]}
                                        hasFeedback
                                    >
                                        <Input.Password placeholder="Enter your password" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Confirm Password"
                                        name="confirmPassword"
                                        dependencies={['password']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your password!',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('The two passwords do not match!');
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password placeholder="Retype your password" />
                                    </Form.Item>
                                    <Form.Item className='mt-10'>
                                        <Button htmlType="submit" className='purple-btn' block>
                                            Change Password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    )}
                </Col>
                <Col xs={24} sm={12} className='hidden md:flex items-center text-center justify-center'>
                    <img src={passwordIllustration} alt='signInIllustration' className='h-3/5' />
                </Col>
            </Row>
        </div>
    );
};

export default ResetPassword;