import React, {
    useEffect,
    useState
} from 'react';
import axios from 'axios';
import {
    Col,
    Row,
    Input,
    Button,
    Form,
    Upload,
    Spin,
    Select,
    message
} from 'antd';
import config from '../../confiq/confiq';
import { getBase64 } from '../../utils';
import { UploadButton } from '../../components/common';
import { useAuth } from '../../context';
import { useCountryTimeZoneData } from '../../CommonApiService/commonApi';

const { Option } = Select;

function BuyerProfile() {
    const [buyerUserData, setBuyerUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const [imageUrl, setImageUrl] = useState();
    const [imageId, setImageId] = useState();
    const { userToken } = useAuth();
    const { countryWithTimezone } = useCountryTimeZoneData(userToken);

    useEffect(() => {
        axios
            .get(`${config.apiUrl}api/user/info`, {
                headers: config.headers,
            })
            .then((response) => {
                setBuyerUserData(response?.data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
                setLoading(false);
            });
    }, [imageUrl]);

    const handleSave = (values) => {

        setLoading(true);
        const postData = {
            first_name: values?.firstName,
            last_name: values?.lastName,
            company: values?.companyName,
            title: values?.title,
            phone: values?.phoneNumber,
            country_timezone: values?.location || buyerUserData?.country_timezone,
            social_links: {
                linkedin: values?.linkedInURL,
            },
            about_company: values?.about_company,
            image_id: imageId !== undefined && imageId !== null ? String(imageId) : buyerUserData?.image_id,
        };

        axios.post(`${config.apiUrl}api/user/info`, postData, {
            headers: config.headers,
        }).then(response => {
            setLoading(false);
            window.location.reload()
        }).catch(error => {
            setLoading(false);
        });
    };

    const onValuesChange = (changedValues, allValues) => {
        form.setFieldsValue({ isFormChanged: true });
    };

    const handleChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            getBase64(info.file.originFileObj, (url) => {
                setImageUrl(url);
            });
            const formData = new FormData();
            formData.append('file', info.file.originFileObj);
            formData.append('type', 'profile');

            axios.post(`${config.apiUrl}api/upload`, formData, {
                headers: config.headers,
            })
                .then(response => {
                    if (response?.data?.statuscode === 201) {
                        const newImageId = response?.data?.image_id;
                        setImageId(newImageId);
                    }
                })
                .catch(error => {
                    console.error('Error uploading file to API:', error);
                });
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };


    const handleRemoveImage = () => {
        setLoading(true);
        setImageUrl(null);
        setImageId(null);
        axios.post(`${config.apiUrl}api/user/info`, {
            image_id: null,
        }, {
            headers: config.headers,
        }).then(response => {
            setLoading(false);
            window.location.reload();
        }).catch(error => {
            setLoading(false);
            console.error('Error removing image from API:', error);
        });
    };

    const beforeUpload = (file) => {
        if (file.length > 1) {
            message.error('You can only upload one file at a time!');
            return false;
        }
        return true;
    };
    const propsLogo = {
        name: "file",
        listType: "picture-card",
        className: "avatar-uploader",
        showUploadList: false,
        action: `${config.apiUrl}api/upload`,
        headers: config.headers,
        method: "POST",
        beforeUpload,
        accept: 'image/*',
        onChange: handleChange,
    };

    const propsLogo2 = {
        name: "file",
        showUploadList: false,
        action: `${config.apiUrl}api/upload`,
        headers: config.headers,
        method: "POST",
        onChange: handleChange,
        beforeUpload,
        accept: 'image/*',
    }


    if (loading) {
        return <Spin className='loader' tip='Loading...' />;
    }

    return (
        <div className="w-full md:w-3/4">
            <Form
                form={form}
                onFinish={handleSave}
                onValuesChange={onValuesChange}
                layout="vertical"
                initialValues={{
                    firstName: buyerUserData?.first_name,
                    lastName: buyerUserData?.last_name,
                    countryTimezone: buyerUserData?.timezone?.name,
                    phoneNumber: buyerUserData?.phone,
                    linkedInURL: buyerUserData?.social_links?.linkedin,
                    companyName: buyerUserData?.company,
                    website: buyerUserData?.social_links?.website,
                    email: buyerUserData?.email,
                    title: buyerUserData?.title,
                    location: buyerUserData?.country_timezone,
                    about_company: buyerUserData?.about_company,
                }}
                onFieldsChange={(changedFields) => {
                    const isFormChanged = changedFields.some((field) => field.name[0] !== 'isFormChanged');
                    form.setFieldsValue({ isFormChanged });
                }}
            >
                <div className="mb-5">
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                name="profilePicture"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => e.fileList}
                                className='add-remove-profile'
                            >
                                <div className='flex items-center gap-4'>
                                    <Upload
                                        {...propsLogo}
                                        className='bd-radius'

                                    >
                                        {imageUrl ? (
                                            <img
                                                src={imageUrl}
                                                alt="profile"
                                                style={{
                                                    width: '100%',
                                                }}
                                            />
                                        ) : (
                                            buyerUserData?.image ? (
                                                <img
                                                    src={buyerUserData?.image?.path}
                                                    alt="profile"
                                                    className='img-url'
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            ) : (
                                                <UploadButton loading={loading} />
                                            )
                                        )}
                                    </Upload>

                                    <div>
                                        <Upload  {...propsLogo2} showUploadList={false}>
                                            <span className='font-semibold text=[14px] cursor-pointer text-[#5D5FF3] mr-4'>Upload Image</span>
                                        </Upload>
                                        <span onClick={handleRemoveImage} className='font-semibold cursor-pointer text=[14px] text-[#EA5A54]'>Remove</span>
                                    </div>

                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                    { required: true, message: "" },
                                    { max: 30, message: 'Maximum 30 characters allowed' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[{ required: true, message: "" },
                                { max: 30, message: 'Maximum 30 characters allowed' },]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                label="Company"
                                name="companyName"
                                rules={[{ required: true, message: "" },
                                { max: 100, message: 'Maximum 100 characters allowed' },]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: '' }, { max: 50, message: 'Maximum 50 characters allowed' },]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                label="Email"
                                name="email"
                                disabled
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                label="Phone Number"
                                name="phoneNumber"
                                rules={[
                                    { required: true, message: "" },
                                    { max: 15, message: 'Maximum 15 characters allowed' },
                                    () => ({
                                        validator(_, value) {
                                            const phoneNumerRegex = /^[0-9]{8,15}$/;
                                            if (value && !phoneNumerRegex.test(value)) {
                                                return Promise.reject(
                                                    new Error("Please enter a valid Phone number")
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="location"
                                label="Country / Timezone"
                                rules={[{ required: true, message: '' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Country / Timezone"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countryWithTimezone && countryWithTimezone?.map((location) => (
                                        <Option key={location?.id} value={location['country&timezone']}>
                                            {location['country&timezone']}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                label="LinkedIn URL"
                                name="linkedInURL"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={24}>
                            <Form.Item
                                name="about_company"
                                label="Tell us more about your company"
                                rules={[
                                    { max: 100, message: 'Maximum 100 characters allowed' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
            </Form>
        </div>
    );
}

export default BuyerProfile;