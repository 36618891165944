import React from "react";
import { Modal, Button } from "antd";

const ConfirmationModal = (props) => {
  const { selectedProjectId, open, handleOk, modalDesc, handleCancel } = props;
  return (
    <Modal
      title="Confirm Deletion"
      width={400}
      open={open}
      centered
      footer={() => (
        <div className="flex justify-end">
          <Button
            key="delete"
            type="primary"
            danger
            onClick={() => {
              handleOk(selectedProjectId);
            }}
          >
            Delete
          </Button>
        </div>
      )}
      onCancel={handleCancel}
    >
      <p className="mt-4">{modalDesc}</p>
    </Modal>
  );
};
export default ConfirmationModal;
