import {
  Col,
  Collapse,
  Form,
  Row,
  Spin,
  message,
} from "antd";
import {
  ArrowLeft,
  ChevronLeftOutline,
  ChevronUpOutline,
  ChevronDownOutline
} from "heroicons-react";
import React, {
  useEffect,
  useState
} from "react";
import {
  useNavigate,
  useParams
} from "react-router-dom";
import axios from "axios";
import config from "../../confiq/confiq";
import NotesRequest from "../request/notesRequest";
import Reply from "../request/reply";
import { RequestSummaryDetails } from "../request/requestSummary";
import { ResponderDetails } from "../request/respondedDetails";
import { useUserRole } from "../../context/userContext";
import { ArrowUpRight } from "@phosphor-icons/react";

const MyRequestDetail = () => {
  const [requestData, setRequestData] = useState(null);
  const [conversation, setConversation] = useState([]);
  const [textAreaHeightOverall, setTextAreaHeightOverall] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [textAreaValueNotes, setTextAreaValueNotes] = useState('');
  const [textAreaValueReply, setTextAreaValueReply] = useState('');
  const [replyEnabled, setReplyEnabled] = useState(true);
  const [textAreaHeight, setTextAreaHeight] = useState(34);
  const [bottomNotesVisible, setBottomNotesVisible] = useState(false);
  const [fetchNote, setFetchNote] = useState(null);
  const [editingNoteId, setEditingNoteId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formnotes] = Form.useForm();
  const [formReply] = Form.useForm();
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const userRole = useUserRole();

  const fetchData = (requestId) => {
    axios.get(`${config.apiUrl}api/${userRole === "Buyer" ? "user" : "vendor"}/ticketDetails?ticket_id=${requestId}`, {
      headers: config.headers,
    }).then((response) => {
      if (response?.data?.statusCode === 201) {
        setRequestData(response?.data?.data);
        setLoading(false);
      }
    }).catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
  };

  const fetchConversation = (requestId) => {
    axios
      .get(`${config.apiUrl}api/${userRole === "Buyer" ? "user" : "vendor"}/conversations?ticket_id=${requestId}`, {
        headers: config.headers,
      })
      .then((response) => {
        if (response?.data?.statusCode === 201) {
          setConversation(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(id);
    fetchConversation(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (requestData) {
      form.setFieldsValue({
        status: requestData.status,
        leads_stage: requestData.leads_stage,
      });
    }
  }, [requestData, form]);

  const handleUpdateRequest = (values) => {
    const updateRequestPayload = {
      id: requestData?.id,
      status: values?.status,
    };

    axios.post(`${config.apiUrl}api/user/update/Request`, updateRequestPayload, {
      headers: config.headers,
    }).then((response) => {
      if (response?.data?.statusCode === 201) {
        fetchData(requestData?.id);
        message.success("Request Status updated successfully");
        setRequestData((prevData) => ({
          ...prevData,
          status: values?.status,
        }));
      }
    }).catch((error) => {
      console.error("Error updating request:", error);
    }).finally(() => {
      setLoading(true);
    });
  };

  if (loading) {
    return <Spin className="loader" tip="Loading..." />;
  }

  const items = [
    {
      key: '1',
      label: userRole === "Partner" ? "Requestor Details" : "Request Summary",
      children: <RequestSummaryDetails requestData={requestData} fetchData={fetchData} />,
    },
    {
      key: '2',
      label: userRole === "Partner" ? "Additional Details" : "Responder Details",
      children: <ResponderDetails requestData={requestData} />,
    },
  ];

  const handleReplyClick = () => {
    setReplyEnabled(false);
    setTextAreaHeightOverall(null);
    setTextAreaHeight(35);
    setBottomNotesVisible(false);
  };

  const handleCancel = () => {
    formnotes.resetFields();
    setTextAreaHeight(35);
    setBottomNotesVisible(false);
    setEditingNoteId(null);
  };

  const handleTextAreaFocus = () => {
    setReplyEnabled(true);
    setTextAreaHeight(140);
    setBottomNotesVisible(true);
  };

  const handleOverall = () => {
    if (textAreaHeightOverall === null || textAreaHeightOverall !== 250) {
      setTextAreaHeightOverall(250);
      setReplyEnabled(true);
      setTextAreaHeight(140);
      setBottomNotesVisible(true);
    } else {
      setTextAreaHeightOverall(null);
      setTextAreaHeight(35);
      setBottomNotesVisible(false);
      setEditingNoteId(null);
    }
  };

  return (
    <div>
      {requestData &&
        <Form
          form={form}
          onFinish={handleUpdateRequest}
          initialValues={{
            status: requestData?.status,
            leads_stage: requestData?.leads_stage
          }}
        >
          <div className="flex md:hidden items-center gap-2 mb-2 font-semibold" onClick={() => navigate(-1)}>
            <ArrowLeft width={16} /> Back
          </div>
          <Row gutter={[16, 16]} className="res-row-class">
            <Col span={24} md={15} lg={16} xl={17} offset={1} className="test1 custom-scrollerbar2" >
              <div className="flex flex-row items-start justify-between gap-5 mb-0 mt-5 md:mt-0">
                <div className="flex flex-row gap-2">
                  <div className="hidden md:block back-icon-align">
                    <ChevronLeftOutline onClick={() => navigate(-1)} className="cursor-pointer" />
                  </div>
                  <div>
                    <h3 className="text-lg md:text-xl text-black font-bold mb-3 content-threeline content-twoline">
                      {requestData?.title || "N/A"}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="conversation-box custom-scrollerbar2"
                style={userRole !== "Buyer" ? {
                  height: textAreaHeightOverall !== null ? `calc(100vh - 470px)` : `calc(100vh - 265px)`
                } : { height: 'calc(100vh - 140px)' }}
              >
                <div className="mb-3 pl-8">
                  {requestData?.summary === requestData?.additional_details ? " " :
                    <div style={{ display: 'inline' }}>
                      {requestData?.summary}
                      <span style={{ display: 'inline-block', marginLeft: '4px', verticalAlign: 'middle' }}>
                        <div
                          className="purple-saas22 flex items-center gap-0 cursor-pointer"
                          onClick={() => navigate(`/projects/${requestData?.project_id}/${requestData?.project_slug}`)}
                        >
                          View project<ArrowUpRight width={22} />
                        </div>
                      </span>
                    </div>
                  }
                </div>
                <Reply
                  requestData={requestData}
                  id={id}
                  setLoading={setLoading}
                  conversation={conversation}
                  fetchConversation={fetchConversation}
                  form={formReply}
                  fileList={fileList}
                  setFileList={setFileList}
                  textAreaValue={textAreaValueReply}
                  setTextAreaValue={setTextAreaValueReply}
                  replyEnabled={replyEnabled}
                  setReplyEnabled={setReplyEnabled}
                  handleReplyClick={handleReplyClick}
                />
              </div>
              {userRole !== "Buyer" &&
                <div className=" bottom-0 w-full pr-5 notes-collapse">
                  <NotesRequest
                    id={id}
                    setLoading={setLoading}
                    textAreaHeightOverall={textAreaHeightOverall}
                    setTextAreaHeightOverall={setTextAreaHeightOverall}
                    form={formnotes}
                    textAreaValue={textAreaValueNotes}
                    setTextAreaValue={setTextAreaValueNotes}
                    textAreaHeight={textAreaHeight}
                    setTextAreaHeight={setTextAreaHeight}
                    bottomNotesVisible={bottomNotesVisible}
                    setBottomNotesVisible={setBottomNotesVisible}
                    fetchNote={fetchNote}
                    setFetchNote={setFetchNote}
                    handleOverall={handleOverall}
                    handleCancel={handleCancel}
                    editingNoteId={editingNoteId}
                    setEditingNoteId={setEditingNoteId}
                    handleTextAreaFocus={handleTextAreaFocus}
                  />
                </div>
              }
            </Col>
            <Col span={24} md={8} lg={7} xl={6} className="test1 custom-scrollerbar2" >
              <Collapse
                items={items}
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) => isActive ? <ChevronUpOutline /> : <ChevronDownOutline />}
              />
            </Col>
          </Row>
        </Form>
      }
    </div >
  );
};

export default MyRequestDetail;