import {
  Button,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Spin,
  Tag,
  message,
} from "antd";
import { ChevronLeftOutline, PaperClipOutline } from "heroicons-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../confiq/confiq";
import moment from "moment";
import DeleteRequestModal from "../../components/Modal/deleteRequest";
import StatusTag from "../../components/statusTag";
import { SignInIcon } from "../../assets/img";
import { RequestSummarySet } from "../../components/labelText";

const { Option } = Select;

const InquiriesDetail = (props) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [requestData, setRequestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [deletionReason, setDeletionReason] = useState("");
  const [selectedStatus, setSelectedStatus] = useState();
  const navigate = useNavigate();

  const fetchData = (requestId) => {
    axios
      .get(`${config.apiUrl}api/user/request/${requestId}`, {
        headers: config.headers,
      })
      .then((response) => {
        setRequestData(response.data.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleDeleteRequest = () => {
    const deleteRequestPayload = {
      id: requestData?.id,
      status: "deleted",
      delete_reason: deletionReason,
    };

    axios
      .post(`${config.apiUrl}api/user/update/Request`, deleteRequestPayload, {
        headers: config.headers,
      })
      .then((response) => {
        navigate("/projects");
        message.success("Deleted successfully");
      })
      .catch((error) => {
        console.error("Error deleting request:", error);
      })
      .finally(() => {
        setIsDeleteModalVisible(false);
      });
  };
  const handleUpdateRequest = (values) => {
    const updateRequestPayload = {
      id: requestData?.id,
      status: values?.status,
    };

    axios
      .post(`${config.apiUrl}api/user/update/Request`, updateRequestPayload, {
        headers: config.headers,
      })
      .then((response) => {
        fetchData(requestData?.id);
        message.success("Request Status updated successfully");
        setRequestData((prevData) => ({
          ...prevData,
          status: values?.status,
        }));
      })
      .catch((error) => {
        console.error("Error updating request:", error);
      })
      .finally(() => {
        form.resetFields();
      });
  };

  if (loading) {
    return <Spin className="loader" tip="Loading..." />;
  }

  return (
    <div>
      <Form
        form={form}
        onFinish={handleUpdateRequest}
        initialValues={{
          status: requestData?.status,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={24} md={15} offset={1}>
            <div className="flex flex-row items-center justify-between gap-5 mb-5">
              <div className="flex flex-row gap-4">
                <div className="back-icon-align">
                  <Link to={"/inquiries"} className="text-black">
                    <ChevronLeftOutline />
                  </Link>
                </div>
                <h3 className="text-xl text-black font-bold capitalize">
                  {requestData?.request_title}
                </h3>
              </div>
            </div>
            <div className="flex flex-row gap-3">
              <div className="w-[30px]">
                <div className="request-img-h">
                  <img src={requestData?.user_details?.image?.path || SignInIcon} alt="img" />
                </div>
              </div>
              <div>
                <div className="flex flex-row gap-3 mb-4">
                  <div className="tmidnight-blue font-semibold">
                    {requestData?.user_details?.first_name}{" "}
                    {requestData?.user_details?.last_name}
                  </div>
                  <div className="text-[#6B7280]">
                    {moment(requestData?.created_at).format(
                      "DD-MMM-YYYY h:mm A"
                    )}
                  </div>
                </div>
                <div>{requestData?.description}</div>
              </div>
            </div>
          </Col>
          <Col span={0} md={1} className="vertical-line-h">
            <Divider type="vertical" style={{ height: "100%" }} />
          </Col>
          <Col span={24} md={7} >
            <h6 className="font-bold text-xl text-black mb-4">
              {" "}
              Request Summary
            </h6>
            <div className="mb-5">
              <RequestSummarySet label={"ID"} text={requestData?.id} />
              <RequestSummarySet
                label={"Start Date"}
                text={moment(requestData?.created_at).format("DD-MMM-YYYY")}
              />
              <RequestSummarySet
                label={"Requestor"}
                text={`${requestData?.user_details?.first_name} ${requestData?.user_details?.last_name}`}
              />
              <RequestSummarySet
                label={"Budget Range"}
                text={requestData?.budget_range}
              />
              <RequestSummarySet
                label={"Location"}
                text={requestData?.location}
              />
              <div className="mb-4">
                <div className="w-52 text-[#585757] mb-2">Attachments</div>
                <div className="font-semibold text-[#333333]">
                  {requestData?.attachment &&
                    requestData?.attachment?.map((attach) => (
                      <div key={attach.id} className="uploaded-files">
                        <div className="uploaded-file uploaded-file-style flex justify-between items-center mb-3">
                          <div className="flex flex-row gap-2 items-center">
                            <PaperClipOutline color="#7B7B7B" height={15} />
                            <a
                              href={attach?.path}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {attach?.name || "Attachment"}
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {requestData?.status === "deleted" ? (
                <>
                  <div className="mb-4">
                    <div className="w-52 text-[#585757] mb-2">Status</div>
                    <div className="font-semibold text-[#333333]">
                      <StatusTag status={requestData?.status} />
                    </div>
                  </div>

                  <div className="mb-4">
                    <div className="w-52 text-[#585757] mb-2">
                      Reason for deletion
                    </div>
                    <div className="font-semibold text-[#333333]">
                      {requestData?.delete_reason}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mb-4">
                    <div className="w-52 text-[#585757] mb-2">Status</div>
                    <div className="font-semibold text-[#333333]">
                      <Form.Item name="status">
                        <Select
                          style={{ width: "100%" }}
                          placeholder="Select Status"
                          onChange={(value) => setSelectedStatus(value)}
                        >
                          <Option value="open">
                            <Tag className="blue-tag">Open</Tag>
                          </Option>
                          <Option value="close">
                            <Tag className="close-tag">Close</Tag>
                          </Option>
                          <Option value="inprogress">
                            <Tag className="approved-tag">In progress</Tag>
                          </Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <Button
                      type="secondary"
                      htmlType="submit"
                      disabled={selectedStatus === undefined}
                    >
                      Update
                    </Button>
                  </div>
                </>
              )}
            </div>

            <DeleteRequestModal
              isVisible={isDeleteModalVisible}
              onDelete={handleDeleteRequest}
              deletionReason={deletionReason}
              setDeletionReason={setDeletionReason}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default InquiriesDetail;
