import React, {
    useEffect,
    useState
} from 'react';
import axios from 'axios';
import {
    Col,
    Row,
    Spin,
    Upload,
    message
} from 'antd';
import config from '../../confiq/confiq';
import { LabelText } from '../../utils';
import { useUserInfo } from '../../CommonApiService/commonApi';
import { useAuth } from '../../context';
import { UploadButton } from '../../components/common';
import { useUserRole } from '../../context/userContext';

function PartnerProfile() {
    const [partnerUserData, setPartnerUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { userToken } = useAuth();
    const [imageUrl,] = useState();
    const [, setImageId] = useState();
    const { userInfo } = useUserInfo(userToken);
    const userRole = useUserRole();

    useEffect(() => {
        axios
            .get(`${config.apiUrl}api/user/info`, {
                headers: config.headers,
            })
            .then((response) => {
                setPartnerUserData(response?.data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
                setLoading(false);
            });
    }, []);

    const handleChange = async (info) => {
        try {
            if (info?.file?.status === 'done') {
                message.success(`${info?.file?.name} file uploaded successfully`);
                const formData = new FormData();
                formData.append('file', info?.file?.originFileObj);
                formData.append('type', 'profile');

                const response = await axios.post(`${config?.apiUrl}api/upload`, formData, {
                    headers: config?.headers,
                });

                if (response?.data?.statuscode === 201) {
                    const newImageId = response?.data?.image_id;
                    setImageId(newImageId);

                    const postData = {
                        first_name: partnerUserData?.first_name,
                        last_name: partnerUserData?.last_name,
                        company: partnerUserData?.company,
                        title: partnerUserData?.title,
                        phone: partnerUserData?.phone,
                        country_timezone: partnerUserData?.country_timezone,
                        social_links: {
                            linkedin: partnerUserData?.social_links?.linkedin,
                        },
                        image_id: String(response?.data?.image_id),
                    };

                    await axios.post(`${config?.apiUrl}api/user/info`, postData, {
                        headers: config?.headers,
                    });

                    setLoading(false);
                    window.location.reload();
                }
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        } catch (error) {
            console.error('Error handling file upload:', error);
        }
    };

    const propsLogo = {
        name: "file",
        listType: "picture-card",
        accept: 'image/*',
        className: "avatar-uploader",
        showUploadList: false,
        action: `${config.apiUrl}api/upload`,
        headers: config.headers,
        method: "POST",
        onChange: handleChange,
    };


    const beforeUpload = (file) => {
        if (file.length > 1) {
            message.error('You can only upload one file at a time!');
            return false;
        }
        return true;
    };
    const propsLogo2 = {
        name: "file",
        showUploadList: false,
        action: `${config.apiUrl}api/upload`,
        headers: config.headers,
        method: "POST",
        onChange: handleChange,
        beforeUpload,
        accept: 'image/*',
    }


    const handleRemoveImage = () => {
        setImageId(null);
        axios.post(`${config.apiUrl}api/user/info`, {
            image_id: null,
        }, {
            headers: config.headers,
        }).then(response => {
            if (response?.data?.statusCode === 201) {
                window.location.reload();
            }
        }).catch(error => {
            setLoading(false);
            console.error('Error removing image from API:', error);
        });
    };

    if (loading) {
        return <Spin className='loader' tip='Loading...' />;
    }

    return (
        <div className="w-full md:w-3/4">
            <div className="mb-10 flex flex-row gap-5 items-center">
                <div className='add-remove-profile'>
                    <Upload
                        {...propsLogo}
                        className='bd-radius'
                    >
                        {imageUrl ? (
                            <img
                                src={imageUrl}
                                alt="profile"
                                style={{
                                    width: '100%',
                                }}
                            />
                        ) : (
                            partnerUserData?.image ? (
                                <img
                                    src={partnerUserData?.image?.path}
                                    alt="profile"
                                    className='img-url'
                                    style={{
                                        width: '100%',
                                    }}
                                />
                            ) : (
                                <UploadButton loading={loading} />
                            )
                        )}
                    </Upload>
                </div>
                <div>
                    <div className='font-semibold text-[16px] capitalize'>{`${partnerUserData?.first_name} ${partnerUserData?.last_name}`}</div>
                    <div className='mb-2'>{partnerUserData?.title}</div>
                    <div className='block md:flex gap-2'>
                        To edit any information -
                        <span className='purple-saas22 font-medium cursor-pointer'>
                            <a href="mailto:dev@saas22.com">Write to us</a>
                        </span>
                    </div>
                    <div className='w-fit text-center mt-3'>
                        <Upload  {...propsLogo2} showUploadList={false}>
                            <span className='font-semibold text=[14px] cursor-pointer text-[#5D5FF3] mr-4'>Upload Image</span>
                        </Upload>
                        <span onClick={handleRemoveImage} className='font-semibold cursor-pointer text=[14px] text-[#EA5A54]'>Remove</span>
                    </div>

                </div>

            </div>

            <div className="mb-5">
                <h3 className="text-xl text-black font-semibold capitalize mb-4">Personal Information</h3>
                <Row gutter={[16, 0]}>
                    <Col span={24} md={12}>
                        <LabelText label={"First Name"} text={partnerUserData?.first_name|| 'N/A'} />
                    </Col>
                    <Col span={24} md={12}>
                        <LabelText label={"Last Name"} text={partnerUserData?.last_name|| 'N/A'} />
                    </Col>
                    <Col span={24} md={12}>
                        <LabelText label={"Country / Timezone"} text={partnerUserData?.country_timezone || 'N/A'} />
                    </Col>
                    <Col span={24} md={12}>
                        <LabelText label={"Phone Number"} text={partnerUserData?.phone|| 'N/A'} />
                    </Col>
                    <Col span={24} md={12}>
                        <LabelText
                            label={"LinkedIn URL"}
                            text={
                                partnerUserData?.social_links?.linkedin ? (
                                    <a
                                        href={
                                            partnerUserData.social_links.linkedin.startsWith("https")
                                                ? partnerUserData.social_links.linkedin
                                                : `https://${partnerUserData.social_links.linkedin}`
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="purple-saas22"
                                    >
                                        View Profile
                                    </a>
                                ) : (
                                    "N/A"
                                )
                            }
                        />
                    </Col>
                </Row>
            </div>
            <div className="mb-5">
                <h3 className="text-xl text-black font-semibold capitalize mb-4">Company Information</h3>
                <Row gutter={[16, 0]}>
                    {(userRole === "Partner" && userInfo?.type !== "expert") &&
                        <>
                            <Col span={24} md={12}>
                                <LabelText label={"Company Name"} text={partnerUserData?.company || 'N/A'} />
                            </Col>
                            <Col span={24} md={12}>
                                <LabelText label={"Website"} text={partnerUserData?.partner_details?.website || 'N/A'} />
                            </Col>
                        </>
                    }

                    <Col span={24} md={12}>
                        <LabelText label={"Email"} text={
                            <div className="lowercase">{partnerUserData?.email || "N/A"}</div>
                        } />
                    </Col>
                    <Col span={24} md={12}>
                        <LabelText label={"Title"} text={partnerUserData?.title|| 'N/A'} />
                    </Col>
                    {(userRole === "Partner" && userInfo?.type !== "expert") ?
                        <Col span={24} md={12}>
                            <LabelText label={"Services Offered"} text={partnerUserData?.partner_details?.service || 'N/A'} />
                        </Col> :
                        <Col span={24} md={12}>
                            <LabelText label={"Area of Expertise"} text={partnerUserData?.partner_details?.description || 'N/A'} />
                        </Col>
                    }
                </Row>
            </div>
        </div>
    );
}

export default PartnerProfile;