import { Spin } from "antd";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { ResourceCard } from "../../../components/Card/resource";
import axios from "axios";
import config from "../../../confiq/confiq";
import Pagination from "../../../components/paginationSet";
import SavedEmpty from "../../../components/SavedEmpty";
import { StaticContent } from ".";

export const Favourites = forwardRef((props, ref) => {
  const [product, setProduct] = useState(null);
  const [searchQuery] = useState(null);
  const [functionFilter] = useState(null);
  const [industryFilter] = useState(null);
  const [categoryFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredProduct, setFilteredProduct] = useState();

  const PAGE_SIZE = 15;

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const response = await axios.get(
        `${config.apiUrl}api/bookmark/story?page=${currentPage}&pageSize=${PAGE_SIZE}&ind=${industryFilter}&fun=${functionFilter}&cat=${categoryFilter}&search=${searchQuery}`,
        {
          headers: config.headers,
        }
      );
      setTotalPages(
        Math.ceil(response?.data?.data?.meta?.pagination?.total / PAGE_SIZE)
      );
      setProduct(response?.data?.data?.data);
      setFilteredProduct(response?.data?.data?.meta?.pagination?.total);
    } catch (error) {
      console.error("Error fetching bookmark data:", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    PAGE_SIZE,
    industryFilter,
    functionFilter,
    categoryFilter,
    searchQuery,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      fetchData: fetchData,
    }),
    [fetchData]
  );

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    PAGE_SIZE,
    industryFilter,
    functionFilter,
    categoryFilter,
    searchQuery,
    fetchData,
  ]);

  const handlePaginationClick = (type) => {
    setCurrentPage((prevPage) =>
      type === "prev" ? prevPage - 1 : prevPage + 1
    );
  };

  return (
    <div className="mt-2">
      <StaticContent />
      {loading ? (
        <Spin className="loader" tip="Loading..." />
      ) : product && product.length > 0 ? (
        <ResourceCard
          product={product}
          fetchData={fetchData}
          setLoading={setLoading}
          pageName={"story"}
          modalDesc="Are you sure want to delete this story?"
        />
      ) : (
        <SavedEmpty />
      )}
      {product && product.length > 0 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePaginationClick={handlePaginationClick}
          product={product}
          filteredProduct={filteredProduct}
          showingName={"stories"}
        />
      ) : (
        ""
      )}
    </div>
  );
});
