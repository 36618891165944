import React, {
    useEffect,
    useState
} from 'react';
import {
    Button,
    Col,
    Form,
    Input,
    Row,
    Spin,
    message
} from 'antd';
import {
    passwordIllustration
} from '../../assets/img';
import axios from 'axios';
import config from '../../confiq/confiq';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context';
import { AuthLogo } from './signIn';

const SetPassword = () => {
    const [email, setEmail] = useState('');
    const { login, userRole } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();
    const location = useLocation();

    const state = location.state || {};

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const randomNumber = queryParams.get('randomNumber');

        const fetchData = async () => {
            await validateToken(randomNumber);
            setLoading(false);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateToken = async (randomNumber) => {
        if (randomNumber) {
            const payload = {
                "email_verify": randomNumber,
            };
            try {
                const response = await axios.post(`${config.apiUrl}api/user/email/verify`, payload);
                if (response?.data?.statusCode === 201) {
                    setEmail(response?.data?.data, () => {
                        form.setFieldsValue({ email: response?.data?.data });
                    });
                    message.success('Email verified successfully');
                } else {
                    message.error('Email not verified');
                }
            } catch (error) {
                console.error('Error validating token:', error);
            }
        } else {
            console.error('Token is missing in the URL');
        }
    };

    const onFinish = async (values) => {
        const payload = {
            email: values?.email,
            password: values?.password
        };

        try {
            const response = await axios.post(`${config.apiUrl}api/user/email/register`, payload);
            if (response?.data?.statusCode === 201) {
                const userToken = response?.data?.access_token;
                const state = {
                    email: response?.data?.email,
                }
                localStorage.setItem('userToken', userToken);
                login(response?.data?.role_name);
                if (response?.data?.role_name && userToken) {
                    const navigatePath = response?.data?.role_name === "Partner" ? '/partnerProgramForm' : '/onboardingForm';
                    navigate(navigatePath, state);
                    message.success('Registration successful');
                    window.location.reload();
                }

            } else if (response?.data?.statusCode === 409) {
                message.error('The user already exists.');
            } else if (response?.data?.statusCode === 400) {
                message.error("Email not verified");
            }
        } catch (error) {
            console.error('Error during registration:', error);
        }
    };

    return (
        <div className='container mx-auto sign-page'>
            <AuthLogo />
            <Row gutter={16}>
                <Col xs={24} sm={12} className='flex items-center text-center justify-center'>
                    {loading ? (
                        <Spin className='loader' tip='Loading...' />
                    ) : (
                        <div className="sign-card">
                            <div className='mb-8'>
                                <h2 className="text-3xl font-bold mb-3 text-black">Set your password</h2>
                            </div>
                            <Form
                                name="signinForm"
                                onFinish={onFinish}
                                layout="vertical"
                                initialValues={{ email: email || state.email }}
                                form={form}
                            >
                                <Form.Item
                                    label="Business Email"
                                    name="email"
                                    disabled
                                >
                                    <Input placeholder="Enter your business email" disabled />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your password!',
                                        },
                                        {
                                            min: 8,
                                            message: 'Password must be at least 8 characters long.',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input.Password placeholder="Enter your password" />
                                </Form.Item>

                                <Form.Item
                                    label="Confirm Password"
                                    name="confirmPassword"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('The two passwords do not match!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password placeholder="Retype your password" />
                                </Form.Item>
                                <Form.Item className='mt-10'>
                                    <Button htmlType="submit" className='purple-btn' block>
                                        Continue
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    )}
                </Col>
                <Col xs={24} sm={12} className='hidden md:flex items-center text-center justify-center'>
                    <img src={passwordIllustration} alt='signInIllustration' className='h-3/5' />
                </Col>
            </Row>
        </div >
    );
};

export default SetPassword;
