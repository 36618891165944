import { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../confiq/confiq';

const useUserInfo = (userToken) => {
    const [userInfo, setUserInfo] = useState({});
    const [userRole, setUserRole] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/user/info`, {
                    headers: config.headers,
                });
                const userdetail = response?.data?.data;
                setUserInfo(userdetail);
                setUserRole(userdetail?.role?.name)
            } catch {
                // console.log(error);
            }
        };

        fetchData();
    }, [userToken]);

    return { userInfo, userRole, setUserInfo };
};

const useCountryData = (userToken) => {
    const [country, setCountry] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/country/getAll`, {
                    headers: config.headers,
                });
                setCountry(response?.data?.data);
            } catch {
                // console.log(error)
            }
        };

        fetchData();
    }, [userToken]);

    return { country, setCountry };
};

const useCountryTimeZoneData = (userToken) => {
    const [countryWithTimezone, setCountryWithTimezone] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/country/timezone`, {
                    headers: config.headers,
                });
                setCountryWithTimezone(response?.data);
            } catch {
                // console.log(error);
            }
        };

        fetchData();
    }, [userToken]);

    return { countryWithTimezone };
};

export { useUserInfo, useCountryData, useCountryTimeZoneData };