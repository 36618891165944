import { Row, Form, Input, Button, message, Col } from 'antd';
import axios from 'axios';
import React from 'react';
import config from '../confiq/confiq';
import { useNavigate } from 'react-router-dom';
import { AuthLogo } from './auth/signIn';

function ApplyToJoin(props) {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const onFinish = async (values) => {
        const payload = {
            email: values.email,
            title: values.title,
            first_name: values.first_name,
            last_name: values.last_name,
            company: values.company,
            reason_to_join: values.reason_to_join,
            linkedin: values.linkedin
        }
        axios.post(`${config.apiUrl}api/user/applyToJoin`, payload, {
            headers: config.headers,
        })
            .then(response => {
                if (response?.data?.statusCode === 201) {
                    navigate("/successPage");
                }
                if (response?.data?.statusCode === 400) {
                    form.setFields([
                        {
                            name: 'email',
                            errors: [response?.data?.message],
                        },
                    ]);
                }
            })
            .catch(error => {
                console.error('Error submitting form:', error);
            });
    };
    const commonRule = [
        {
            required: true,
            message: "",
        },
    ];

    const onCancel = () => {
        form.resetFields();
        navigate(-1)
    };
    return (
        <div className='md:w-3/4 m-auto pt-8 md:pt-0 apply-join-centeralign grid items-center justify-center'>
            <AuthLogo />
            <Form form={form}
                onFinish={onFinish}
                layout="vertical"
                className='w-full'
            >
                <h2 className='text-2xl text-black font-bold text-center mb-5'>Apply to Join</h2>
                <Row gutter={[16, 5]} >
                    <Col span={24} md={12}>
                        <Form.Item name="first_name"
                            label="First Name"
                            rules={[
                                ...commonRule,
                                { max: 30, message: 'Maximum 30 characters allowed' },
                            ]}
                        >
                            <Input placeholder="Enter the first name" />
                        </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                        <Form.Item name="last_name"
                            label="Last Name"
                            rules={[
                                ...commonRule,
                                { max: 30, message: 'Maximum 30 characters allowed' },
                            ]}
                        >
                            <Input placeholder="Enter the last name" />
                        </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                        <Form.Item name="company"
                            label="Company Name"
                            rules={[
                                ...commonRule,
                                { max: 30, message: 'Maximum 30 characters allowed' },
                            ]}
                        >
                            <Input placeholder="Eg: Product Manager" />
                        </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                        <Form.Item name="title"
                            label="Title"
                            rules={[
                                ...commonRule,
                                { max: 30, message: 'Maximum 30 characters allowed' },
                            ]}
                        >
                            <Input placeholder="Eg: Product Manager" />
                        </Form.Item>
                    </Col>
                    <Col span={24} md={12}>
                        <Form.Item name="email"
                            label="Email"
                            rules={[
                                { required: true, message: "" },
                                {
                                    type: "email",
                                    message: "Please enter a valid email address",
                                },
                            ]}
                        >
                            <Input placeholder="Enter your work email" />
                        </Form.Item>
                    </Col>
                    <Col span={24} xs={24} sm={12}>
                        <Form.Item
                            name="linkedin"
                            label="LinkedIn URL"
                        >
                            <Input placeholder="Enter the URL" />
                        </Form.Item>
                    </Col>
                    <Col span={24} md={24}>
                        <Form.Item name="reason_to_join"
                            label="Why do you wish to join our community?"
                            rules={[
                                ...commonRule,
                                { max: 30, message: 'Maximum 30 characters allowed' },
                            ]}
                        >
                            <Input.TextArea rows={4} placeholder="Tell us more about your company" />
                        </Form.Item>
                    </Col>
                    <Col span={24} md={24}>
                        <Form.Item >
                            <div className='flex justify-center gap-2'>
                                <Button type="secondary" onClick={onCancel}>Cancel</Button>
                                <Button type="primary" htmlType="submit">Apply to Join</Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div >
    );
}

export default ApplyToJoin;
