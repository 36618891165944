import React from "react";
import { Button, Drawer, Select } from "antd";

const { Option } = Select;

const DealDrawer = ({
    selectedBrandType,
    setSelectedBrandType,
    category,
    setCategory,
    brands,
    handleFilterChange,
    resetFilters,
    setDealBrandFilter,
    setDealCategoryFilter,
    open,
    setOpen,
}) => {
    return (
        <Drawer
            title={"Filters"}
            placement={"bottom"}
            className="drawer-filter"
            height={450}
            visible={open}
            onClose={() => setOpen(false)}
            footer={
                <div className="flex md:hidden w-full">
                    <Button
                        type="secondary"
                        className="w-full"
                        onClick={() => {
                            resetFilters();
                            setOpen(false);
                        }}
                        disabled={!(selectedBrandType || category)}
                    >
                        Clear Filters
                    </Button>
                </div>
            }
        >
            <DealFilter
                selectedBrandType={selectedBrandType}
                setSelectedBrandType={setSelectedBrandType}
                category={category}
                setCategory={setCategory}
                brands={brands}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
                setDealBrandFilter={setDealBrandFilter}
                setDealCategoryFilter={setDealCategoryFilter}
            />
        </Drawer>
    );
};

export const DealFilter = ({
    selectedBrandType,
    setSelectedBrandType,
    category,
    setCategory,
    brands,
    handleFilterChange,
    resetFilters,
    setDealBrandFilter,
    setDealCategoryFilter
}) => {
    return (
        <div className="grid md:flex flex-row items-center gap-2">
            <div className="hidden md:flex">
                {(selectedBrandType || category) && (
                    <div className="cursor-pointer purple-saas22" onClick={resetFilters}>
                        Clear Filters
                    </div>
                )}
            </div>
            <Select
                showSearch
                value={category}
                onChange={(value) =>
                    handleFilterChange(value, setDealCategoryFilter, setCategory)
                }
                placeholder="Category"
                style={{ width: 150 }}
            >
                <Option value="" disabled>
                    Category
                </Option>
                <Option value="eCommerce">eCommerce</Option>
                <Option value="Consulting">Consulting</Option>
                <Option value="SaaS">SaaS</Option>
                <Option value="TBD">TBD</Option>
            </Select>
            <Select
                showSearch
                value={selectedBrandType}
                onChange={(value) =>
                    handleFilterChange(value, setDealBrandFilter, setSelectedBrandType)
                }
                placeholder="Company"
                style={{ width: 150 }}
            >
                <Option value="" disabled>
                    Company
                </Option>
                {brands &&
                    brands.map((item) => (
                        <Option
                            key={item.id}
                            value={item.attributes.vendor_Name}
                            className="capitalize"
                        >
                            {item.attributes.vendor_Name}
                        </Option>
                    ))}
            </Select>
        </div>
    );
};

export default DealDrawer;
