import { Progress } from "antd";
import { Link } from "react-router-dom";
import { Cell, Label, Pie, PieChart, Text, Tooltip } from "recharts";

const CustomTooltip = ({ active, payload, totalProjects }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-pie">
        <div>
          {payload.map((pld, index) => {
            const percentage = (pld.value / totalProjects) * 100;
            const formattedPercentage =
              percentage % 1 === 0
                ? `${percentage.toFixed(0)}%`
                : `${percentage.toFixed(2)}%`;
            return (
              <div key={index} style={{ display: "inline-block", padding: 10 }}>
                <div style={{ color: pld.fill }}>
                  {pld.name} - {formattedPercentage}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
  return null;
};

export default function HomeChart({ chartData, eventData, chart }) {
  const chartDataToShow = chartData?.slice(1);
  const totalProjects = chartDataToShow?.reduce(
    (acc, curr) => acc + curr.value,
    0
  );
  const COLORS = chart === "Requests" ? ["#5D5FF3", "#F7CF66", "#55AF68", "#EA5A54",] : ["#EA5A54", "#F7CF66", "#5D5FF3", "#55AF68"];

  return (
    <Link to={'/request'} className={`projects-stats mb-5 w-full cursor-pointer ${eventData.length > 0 ? "lg:w-3/4" : "md:w-full"
    }`}>
      <div
        
      >
        <div className="whitespace-nowrap text-lg md:text-xl font-bold mb-2 md:mb-3">
          {chart === "Requests" ? "Requests" : "Projects"}
          <span className="md:hidden"> • {totalProjects}</span>
        </div>
        <div className="flex justify-between items-center w-full gap-10">
          <div className="hidden md:flex relative cursor-pointer">
            <PieChart
              width={200}
              height={300}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              className="homeChart cursor-pointer"
            >
              <Tooltip
                content={<CustomTooltip totalProjects={totalProjects} />}
                cursor={{ fill: "transparent" }}
              />
              <Pie
                data={chartDataToShow}
                cx={120}
                cy={200}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={4}
                dataKey="value"
                className="cursor-pointer"
              >
                {chartData &&
                  chartData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                <Label
                  content={
                    <>
                      <Text
                        x={118}
                        dy={195}
                        className="text-2xl fill-black font-semibold"
                        style={{ textAnchor: "middle" }}
                      >
                        {totalProjects}
                      </Text>
                      <Text
                        x={chart === "Requests" ? 116 : 70}
                        dy={220}
                        className="text-base  font-medium"
                        style={{ textAnchor: "middle" }}
                      >
                        {chart === "Requests" ? "Total " : "Total Projects"}
                      </Text>
                    </>
                  }
                  position="center"
                  fill="#000000"
                  fontSize="13"
                  fontWeight="600"
                />
              </Pie>
            </PieChart>
          </div>
          <div className="w-full md:w-3/4 ">
            {chartData?.slice(1)?.map((entry, index) => (
              <div
                key={`progress-${index}`}
                className="flex gap-3 w-full items-center mb-2 md:mb-4"
              >
                <div className="whitespace-nowrap text-sm md:text-base flex gap-1 w-40">
                  {entry.name}
                  <span className="Grayish">({`${entry.value}`})</span>
                </div>
                <Progress
                  percent={(entry.value / chartData[0].value) * 100}
                  strokeColor={COLORS[index % COLORS.length]}
                  showInfo={false}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Link>
  );
}
