import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../context';
import { Button, Tabs } from 'antd';
import { AllStories } from './allstories';
import { MostRead } from './mostRead';
import { Trending } from './trending';
import { Favourites } from './favourite';
import { useUserRole } from '../../../context/userContext';

export default function Stories() {
    const [strapiUrl, setStrapiUrl] = useState('');
    const { userToken } = useAuth();
    const userRole = useUserRole();
    const savedActiveKey = localStorage.getItem('storyActiveTabKey');
    const [activeKey, setActiveKey] = useState(savedActiveKey || '1');

    const allStoriesRef = useRef(null);
    const mostReadRef = useRef(null);
    const trendingRef = useRef(null);
    const favouritesRef = useRef(null);

    useEffect(() => {
        const apiUrl = process.env.REACT_APP_STRAPI_URL;

        if (apiUrl) {
            setStrapiUrl(apiUrl);
        } else {
            console.error('REACT_APP_STRAPI_URL is not defined in the environment.');
        }
    }, []);

    const items = [
        {
            key: '1',
            label: 'All ',
            children: <AllStories ref={allStoriesRef} />,
        },
        {
            key: '2',
            label: 'Most Read',
            children: <MostRead ref={mostReadRef} />,
        },
        {
            key: '3',
            label: 'Trending',
            children: <Trending ref={trendingRef} />,
        },
        ...(userRole !== 'Admin' && userToken
            ? [
                {
                    key: '4',
                    label: 'Favourites',
                    children: <Favourites ref={favouritesRef} />,
                },
            ]
            : []),
    ];

    const handleCreateStory = () => {
        window.open(`${strapiUrl}admin/content-manager/collectionType/api::blog.blog/create`);
    };

    const handleTabChange = (key) => {
        localStorage.setItem('storyActiveTabKey', key);
        setActiveKey(key);
        allStoriesRef.current && allStoriesRef.current.fetchData();
        mostReadRef.current && mostReadRef.current.fetchData();
        trendingRef.current && trendingRef.current.fetchData();
        favouritesRef.current && favouritesRef.current.fetchData();
    };

    return (
        <div>
            <div className='block md:hidden '>
                <div className='text-xl font-semibold'> Stories</div>
            </div>
            <Tabs
                activeKey={activeKey}
                defaultActiveKey="1"
                items={items}
                className="primary-tab child-tab"
                tabBarExtraContent={
                    userRole === 'Admin' && (
                        <Button type="primary" onClick={handleCreateStory}>
                            Create New Story
                        </Button>
                    )
                }
                onChange={handleTabChange}
            />
        </div>
    );
}


export const StaticContent = () => {
    return (
        <>
            <div className='text-[#7B7B7B] mb-3'>Updates, growth strategies, in-depth insights and everything you need to know from the world of SaaS and AI</div>
        </>
    )
}