import React from "react";
import SavedEmpty from "../../assets/img/empty.svg";
import { AuthLogo } from "./signIn";

function InvalidLogin() {
    return (
        <div className="text-center mx-auto justify-center grid linkexpired flex-col">
            <AuthLogo />
            <div className=" flex items-center">
                <div className="grid gap-4">
                    <div className="mx-auto text-center flex justify-center">
                        <img src={SavedEmpty} alt="emailVerificationIllustration" />
                    </div>
                    <div className="text-black font-semibold">
                        Sorry, we couldn’t find your account!
                    </div>
                    <div className="text-secondary">
                        It seems you haven't registered with this account yet.
                    </div>
                </div>
            </div>
            <div className="bg-white fixed bottom-5">
            Need Support?{" "}
                <a href="https://www.saas22.com/contact-us" rel="noreferrer" target="_blank">
                    <span className="text-blue-600 font-semibold">Contact us</span>
                </a>
            </div>
        </div>
    );
}

export default InvalidLogin;
