import React from 'react';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import config from '../../confiq/confiq';
import { emailVerificationIllustration } from '../../assets/img';
import { AuthLogo } from './signIn';

function EmailVerification() {
    const location = useLocation();
    const resetPassword = location?.state?.reset;

    const handleResendEmail = async (reset) => {
        const endpoint = reset === "resetpassword" ? 'user/password/reset/email' : 'user/email';
        const payload = { email: location?.state?.email };

        try {
            const response = await axios.post(`${config.apiUrl}api/${endpoint}`, payload);

            if (response?.data?.statusCode === 201) {
                message.success('Resend Verification Email successfully');
            } else if (response?.data?.statusCode === 401) {
                message.error('Email already exists.');
            } else {
                // console.log('error');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='text-center mx-auto justify-center grid'>
            <AuthLogo />
            <div className='email-section-h'>
                <div className='grid gap-4'>
                    <div className='mx-auto'>
                        <img src={emailVerificationIllustration} alt='emailVerificationIllustration' />
                    </div>
                    <div className='text-black font-semibold'>Verification Email Sent</div>
                    <div>
                        <div className='text-secondary mb-1'>We have sent a verification link to</div>
                        <div className='text-black  font-semibold'>{location?.state?.email}</div>
                    </div>

                    <div className='text-secondary'>Click on the link to complete your verification process</div>
                </div>
            </div>
            <div className='bg-white gap-2 grid md:flex flex-row'>
                Didn’t receive an email?
                <span
                    className="text-blue-600 font-semibold cursor-pointer"
                    onClick={() => handleResendEmail(resetPassword)}
                >
                    Resend Verification Email
                </span>
            </div>
        </div>
    );
}

export default EmailVerification;