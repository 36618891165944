import { LoadingOutlined } from "@ant-design/icons";
import { SignInIcon } from "../assets/img";

export const UploadButton = ({ loading }) => (
    <div>
        {loading && <LoadingOutlined />}
        <div>
            <img src={SignInIcon} alt="" className="upload-default-image" />
        </div>
    </div>
);