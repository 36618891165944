import { useEffect, useState } from 'react';
import { Form, Select, Tag, message } from "antd";
import axios from "axios";
import config from "../../confiq/confiq";
import moment from "moment";
import { RequestSummarySet } from "../../components/labelText";
import { PlusOutline } from "heroicons-react";
import { useUserRole } from '../../context/userContext';
import ModalExternalLink from './modalExternalLink';

const { Option } = Select;

export const RequestSummaryDetails = ({ requestData, fetchData }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [links, setLinks] = useState([]);
    const userRole = useUserRole();
    useEffect(() => {
        const newLinks = [
            { label: requestData?.label_1, link: requestData?.link_1 },
            { label: requestData?.label_2, link: requestData?.link_2 },
            { label: requestData?.label_3, link: requestData?.link_3 },
        ].filter(link =>
            (link.label && link.label !== "null") || (link.link && link.link !== "null")
        );

        setLinks(newLinks.length > 0 ? newLinks : [{ label: requestData?.label_1, link: requestData?.link_1 }]);
    }, [requestData]);

    const statusOptions = [
        {
            value: 2, label: 'Open', className: userRole === "Buyer" ? "" : 'blue-tag', disabled: userRole === "Buyer"
        },
        { value: 3, label: 'Pending', className: 'pending-tag' },
        { value: 4, label: 'Resolved', className: userRole === "Buyer" ? "" : 'approved-tag', disabled: userRole === "Buyer" },
        { value: 5, label: 'Closed', className: 'revoked-tag' },
    ];


    const handleUpdateTicket = (field, value) => {
        const payload = {
            ticket_id: requestData?.ticket_id,
            [field]: value,
        };

        axios.post(`${config.apiUrl}api/vendor/updateTickets`, payload, {
            headers: config.headers,
        }).then((response) => {
            if (response?.data?.statusCode === 201) {
                if (field === 'status') {
                    message.success('Status updated successfully');
                } else if (field === 'leads_stage') {
                    message.success('Lead stage updated successfully');
                }
            }
        }).catch((error) => {
            console.error(`Error updating ${field}:`, error);
        });
    };

    const handleChangeStatus = (value) => {
        handleUpdateTicket('status', value);
    };

    const handleChangeLeadsStage = (value) => {
        handleUpdateTicket('leads_stage', value);
    };

    const handleModalOpen = () => {
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
        // setLinks([{ label: '', link: '' }]);
    };

    const handleAddLink = () => {
        if (links.length < 3) {
            setLinks(prevLinks => [...prevLinks, { label: '', link: '' }]);
        }
    };

    const handleLinkChange = (index, field, value) => {
        const updatedLinks = [...links];
        updatedLinks[index][field] = value;
        setLinks(updatedLinks);
    };

    const handleSaveLinks = () => {
        const payload = {
            ticket_id: requestData?.ticket_id,
            label_1: links.length > 0 && links[0].label !== "" ? links[0].label : "null",
            link_1: links.length > 0 && links[0].link !== "" ? links[0].link : "null",
            label_2: links.length > 1 && links[1].label !== "" ? links[1].label : "null",
            link_2: links.length > 1 && links[1].link !== "" ? links[1].link : "null",
            label_3: links.length > 2 && links[2].label !== "" ? links[2].label : "null",
            link_3: links.length > 2 && links[2].link !== "" ? links[2].link : "null",
        };

        axios.post(`${config.apiUrl}api/vendor/updateTickets`, payload, {
            headers: config.headers,
        }).then((response) => {
            if (response?.data?.statusCode === 201) {
                message.success('External links updated successfully');
                setModalVisible(false);
                setLinks([{ label: '', link: '' }]);
                fetchData(requestData?.ticket_id);
            } else {
                message.error('Failed to update external links');
            }
        }).catch((error) => {
            message.error('Failed to update external links');
        });
    };
    const externalLinks = [
        { label: requestData?.label_1, link: requestData?.link_1 },
        { label: requestData?.label_2, link: requestData?.link_2 },
        { label: requestData?.label_3, link: requestData?.link_3 },
    ].filter(link => link?.label && link?.link);

    return (
        <>
            <RequestSummarySet label={"ID"} text={requestData?.ticket_id || "-"} />
            <RequestSummarySet
                label={"Start Date"}
                text={moment(requestData?.created_at).format("DD-MMM-YYYY") || "-"}
            />
            <RequestSummarySet
                label={"Requestor"}
                text={requestData?.buyer_name || "-"}
            />
            <RequestSummarySet
                label={"Budget Range"}
                text={`${requestData?.currency || ""} ${requestData?.budget || ""}`.trim() || "-"}
            />
            {userRole !== 'admin' &&
                <div className="block flex-row gap-2 mb-4">
                    <div className="flex justify-between gap-2 items-center">
                        <div className=" text-[#585757]">External links</div>
                        {userRole === 'Partner' &&
                            <>
                                {externalLinks && externalLinks?.filter(link => link?.label !== "null" && link?.link !== "null")?.length === 3 ? (
                                    <div className="purple-saas22 flex items-center text-sm cursor-pointer" onClick={handleModalOpen}>
                                        <PlusOutline width={16} />Update link
                                    </div>
                                ) : (
                                    <div className="purple-saas22 flex items-center text-sm cursor-pointer" onClick={handleModalOpen}>
                                        <PlusOutline width={16} />Add link
                                    </div>
                                )}
                            </>
                        }
                    </div>
                    {externalLinks && externalLinks.length > 0 ? (
                        externalLinks
                            .filter(link => (link.label && link.label !== "null") || (link.link && link.link !== "null"))
                            .map((link, index) => (
                                <div key={index}>
                                    <a
                                        href={link.link !== "null" ? link.link : ''}
                                        target={link.link !== "null" ? "_blank" : "_self"}
                                        rel="noopener noreferrer"
                                        className={`font-semibold ${link.link !== "null" ? "purple-saas22" : "cursor-default"}`}
                                    >
                                        {link.label !== "null" ? link.label : "N/A"}
                                    </a>
                                </div>
                            ))
                    ) : (
                        <div className='font-semibold text-[#333333]'>N/A</div>
                    )}

                </div>
            }
            <div className="mb-4">
                <div className="w-52 text-[#585757] mb-2">Ticket Status</div>
                <div className="font-semibold text-[#333333]">
                    <Form.Item name="status">
                        <Select
                            style={{ width: "100%" }}
                            placeholder="Select Status"
                            onChange={handleChangeStatus}
                            defaultValue={requestData?.status}
                        >
                            {statusOptions.map(option => (
                                <Option key={option.value} value={option.value} disabled={option.disabled}>
                                    <Tag className={option.className}>{option.label}</Tag>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </div>
            </div>
            {userRole === 'Partner' &&
                <div className="mb-4">
                    <div className="w-52 text-[#585757] mb-2">Lead Stage</div>
                    <div className="font-semibold text-[#333333]">
                        <Form.Item name="leads_stage">
                            <Select
                                style={{ width: "100%" }}
                                placeholder="Select Status"
                                onChange={handleChangeLeadsStage}
                            >
                                <Option value="New">
                                    <Tag className="blue-tag">New</Tag>
                                </Option>
                                <Option value="Engaged">
                                    <Tag className="blue-tag">Engaged</Tag>
                                </Option>
                                <Option value="Qualified">
                                    <Tag className="blue-tag">Qualified</Tag>
                                </Option>
                                <Option value="Opportunity">
                                    <Tag className="blue-tag">Opportunity</Tag>
                                </Option>
                                <Option value="Win">
                                    <Tag className="blue-tag">Win</Tag>
                                </Option>
                                <Option value="Loss">
                                    <Tag className="blue-tag">Loss</Tag>
                                </Option>
                            </Select>
                        </Form.Item>
                    </div>
                </div>
            }
            {userRole === 'Partner' &&
                <ModalExternalLink
                    modalVisible={modalVisible}
                    handleModalClose={handleModalClose}
                    handleSaveLinks={handleSaveLinks}
                    links={links}
                    handleLinkChange={handleLinkChange}
                    handleAddLink={handleAddLink}
                />
            }
        </>
    );
};