import React, { useEffect, useState } from "react";
import MainLayout from "./layouts/MainLayout";
import AppRoutes from "./routes/appRoutes";
import { ConfigProvider } from "antd";
import "./pages/auth/auth.scss";
import "./components/Sidebar/Sidebar.scss";
import { AuthProvider } from "./context";
import "./App.scss";
import { isOnline } from "./utils";
import NoInternet from "./pages/noInternet";
import UserProvider from "./context/userContext";

function App() {
  const [online, setOnline] = useState(true);

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setOnline(isOnline());
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);

    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, []);

  const themeProvider = {
    token: {
      fontFamily: "Inter, sans-serif",
      colorPrimary: "#5d5ffc",
      colorPrimaryActive: "#5d5ffc",
      colorPrimaryBorder: "#5d5ffc",
      colorPrimaryHover: "#5d5ffc",
      colorText: "#333333",
      colorTextDescription: "#333333",
      colorBorder: "#E5E7EB",
    },
    components: {
      Tabs: {
        inkBarColor: "#F7CF66",
        itemActiveColor: "#333333",
        itemColor: "#333333",
      },
      Table: {
        borderColor: "#F9FAFB",
        bodySortBg: "#F9FAFB",
        headerBg: "#F9FAFB",
        cellFontSize: 14,
        headerColor: "#333333",
        headerSplitColor: "#F9FAFB",
      },
      Radio: {
        colorPrimary: "#010101",
        colorPrimaryActive: "#010101",
      },
      Checkbox: {
        colorPrimary: "#010101",
        colorPrimaryActive: "#010101",
        colorPrimaryHover: "#010101",
      },
    },
  };

  return (
    <div className="App">
      <AuthProvider>
        <ConfigProvider theme={themeProvider}>
          <UserProvider>
            <MainLayout online={online}>
              {online ? <AppRoutes /> : <NoInternet />}
            </MainLayout>
          </UserProvider>
        </ConfigProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
