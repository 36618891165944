import { RequestSummarySet } from "../../components/labelText"
import { useUserRole } from "../../context/userContext";

export const ResponderDetails = ({ requestData }) => {
    const userRole = useUserRole();
    const isEmpty = !requestData?.responder_name && !requestData?.responder_title && !requestData?.company_name;

    return (
        <>
            {userRole === 'Buyer' ?
                <>
                    {isEmpty ? (
                        <div className="text-center Grayish">Unassigned</div>
                    ) : (
                        <>
                            <RequestSummarySet
                                label={"Name"}
                                text={requestData?.responder_name || "-"}
                            />
                            <RequestSummarySet
                                label={"Title"}
                                text={requestData?.responder_title || "-"}
                            />
                            <RequestSummarySet
                                label={"Company Name"}
                                text={requestData?.responder_company || "-"}
                            />
                        </>
                    )}
                </>
                :
                <>
                    <RequestSummarySet
                        label={"Location"}
                        text={requestData?.buyer_location || "-"}
                    />
                    <RequestSummarySet
                        label={"Email"}
                        text={requestData?.buyer_email || "-"}
                    />
                    <RequestSummarySet
                        label={"Phone Number"}
                        text={requestData?.buyer_phone || "-"}
                    />
                    <RequestSummarySet
                        label={"Company Name"}
                        text={requestData?.company_name || "-"}
                    />
                    <RequestSummarySet
                        label={"Title"}
                        text={requestData?.buyer_designation || "-"}
                    />
                    <RequestSummarySet
                        label={"Company Size "}
                        text={requestData?.company_size || "-"}
                    />
                    <RequestSummarySet
                        label={"Industry"}
                        text={requestData?.industry || "-"}
                    />
                    <RequestSummarySet
                        label={"Opportunity Value"}
                        text={requestData?.opportunity_value || "-"}
                    />
                </>
            }
        </>
    )
}