import { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [userRole, setUserRole] = useState(() => {
        return localStorage.getItem('userRole') || null;
    });
    const [userToken, setUserToken] = useState(localStorage.getItem('userToken') || null);

    const setToken = (token) => {
        localStorage.setItem('userToken', token);
        setUserToken(token);
    };

    const clearToken = () => {
        localStorage.removeItem('userToken');
        setUserToken(null);
    };

    const login = (role) => {
        setUserRole(role);
        localStorage.setItem('userRole', role);
    };

    const logout = () => {
        setUserRole(null);
        localStorage.removeItem('userRole');
    };

    return (
        <AuthContext.Provider value={{
            userRole,
            userToken,
            login,
            logout,
            setToken,
            clearToken
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};