import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../confiq/confiq";
import { Dropdown, Spin, Table, message } from "antd";
import { DotsVerticalOutline } from "heroicons-react";
import { UserDetailsDrawer } from "./userDetailDrawer";
import moment from "moment";
import StatusTag from "../../components/statusTag";
import InviteUserInput from "../../components/inviteEmail";
import SavedEmpty from "../../components/SavedEmpty";

function ManageUserAdmin(props) {
  const [getAdminData, setGetAdminData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserRole, setSelectedUserRole] = useState(null);
  const [inviteEmails, setInviteEmails] = useState([]);
  const [emailList, setEmailList] = useState([]);

  const fetchData = () => {
    axios
      .get(`${config.apiUrl}api/admin/users/admin`, {
        headers: config.headers,
      })
      .then((response) => {
        let newArray = [];

        response?.data?.data?.map((data) => {
          newArray.push(data?.email);
        });
        setEmailList(newArray);
        setGetAdminData(response?.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <div
          className="royal-blue cursor-pointer"
          onClick={() => {
            setSelectedUserId(text);
            setSelectedUserRole(record?.role?.name);
          }}
        >
          {record.first_name || record.last_name || "N/A"}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Invited Date",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date) => {
        const formattedDate = moment(date).format("DD-MMM-YYYY");
        return formattedDate;
      },
    },
    {
      title: "INVITE Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <StatusTag status={status === "revoke" ? "revoked" : status === "approved" ? "accepted" : status} />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        let items = [];
        switch (record?.status?.toLowerCase()) {
          case 'approved':
            items = [
              {
                key: '1',
                label: "View",
                onClick: () => handleView(record),
              },
              {
                key: '3',
                label: "Revoke",
                onClick: () => handleRevokeAccess(record, "revoked"),
              },
            ];
            break;
          case 'revoke':
          case 'revoked':
            items = [
              {
                key: '1',
                label: "View",
                onClick: () => handleView(record),
              },
              {
                key: '2',
                label: "Reinstate",
                onClick: () => handleResendInvite(record),
              },
            ];
            break;
          default:
            items = [
              {
                key: '1',
                label: "View",
                onClick: () => handleView(record),
              },
              {
                key: '2',
                label: "Reinstate",
                onClick: () => handleResendInvite(record),
              },
              {
                key: '3',
                label: "Revoke",
                onClick: () => handleRevokeAccess(record, "revoked"),
              },
            ];
            break;
        }

        return (
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
          >
            <div className="cursor-pointer">
              <span className="ant-dropdown-link">
                <DotsVerticalOutline width={18} />
              </span>
            </div>
          </Dropdown>
        );
      },
    },
  ];
  const handleView = (record) => {
    setSelectedUserId(record.id);
    setSelectedUserRole(record?.role?.name);
  };

  const handleResendInvite = async (record) => {
    try {
      const payload = {
        data: [{ email: record.email, role: "admin" }],
      };

      await handleSendInvite(payload?.data?.map((item) => item?.email));
      message.success("Invite resent successfully.");
    } catch (error) {
      console.error("Error resending invite:", error);
      message.error("Failed to Reinstate. Please try again.");
    }
  };

  const handleRevokeAccess = async (record, text) => {
    const status =
      text === "approved" ? "approved" :
        text === "revoke" ? "revoked" :
          text === "rejected" ? "rejected" :
            record?.status === "revoke" ? "approved" : "revoked";

    const payload = {
      id: record.id,
      email: record.email,
      status: status,
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}api/admin/updateStatus`,
        payload,
        {
          headers: config.headers,
        }
      );

      if (response?.data?.statusCode === 201) {
        fetchData();
        message.success("Status updated succesfully");
      }
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleSendInvite = async (emailsArray) => {
    try {
      const payload = {
        data: emailsArray.map((email) => ({ email, role: "admin" })),
      };

      const response = await axios.post(
        `${config.apiUrl}api/admin/inviteUser`,
        payload,
        {
          headers: config.headers,
        }
      );

      if (response?.status === 201) {
        axios
          .get(`${config.apiUrl}api/admin/users/buyer`, {
            headers: config.headers,
          })
          .then((response) => {
            fetchData();
            setInviteEmails([]);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
          });
      }
    } catch (error) {
      console.error("Error sending invite:", error);
      message.error("Failed to send invite. Please try again.");
    }
  };

  return (
    <>
      <InviteUserInput
        handleSendInvite={handleSendInvite}
        setInviteEmails={setInviteEmails}
        emailList={emailList}
      />
      <Table dataSource={getAdminData} locale={{ emptyText: <div className="w-40 -mt-14 m-auto"> <SavedEmpty /></div> }} columns={columns} />

      {selectedUserId && (
        <UserDetailsDrawer
          userId={selectedUserId}
          userRole={selectedUserRole}
          handleRevokeAccess={handleRevokeAccess}
          onClose={() => setSelectedUserId(null)}
        />
      )}
    </>
  );
}

export default ManageUserAdmin;
