import {
    Button,
    Checkbox,
    Input,
    Form,
    message,
    Tag
} from 'antd';
import axios from 'axios';
import {
    ChevronDownOutline,
    ChevronUpOutline,
    ClipboardListOutline,
    LockClosedOutline,
    Pencil,
    Trash
} from 'heroicons-react';
import React, { useEffect, useState } from 'react';
import config from '../../confiq/confiq';
import moment from 'moment';
import ConfirmationModal from '../../components/Modal/confirmationDelete';

const { TextArea } = Input;

function NotesRequest({
    id,
    setLoading,
    textAreaHeightOverall,
    setTextAreaHeightOverall,
    form,
    textAreaValue,
    setTextAreaValue,
    textAreaHeight,
    setTextAreaHeight,
    bottomNotesVisible,
    setBottomNotesVisible,
    fetchNote,
    setFetchNote,
    handleOverall,
    handleCancel,
    editingNoteId,
    setEditingNoteId,
    handleTextAreaFocus
}) {
   
    const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
    const [noteToDelete, setNoteToDelete] = useState(null);
    const [visibleToMe, setVisibleToMe] = useState(false);

    const fetchNotes = (requestId) => {
        axios.get(`${config.apiUrl}api/vendor/notes?ticket_id=${requestId}`, {
            headers: config.headers,
        }).then((response) => {
            setFetchNote(response?.data?.data);
            setLoading(false);
        }).catch((error) => {
            console.error("Error fetching data:", error);
            setLoading(false);
        });
    };

    useEffect(() => {
        fetchNotes(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onFinish = (values) => {
        const payload = {
            "ticket_id": id,
            "private": values?.visibleToMe === true ? true : false,
            "body": values?.notes,
        };

        axios.post(`${config.apiUrl}api/vendor/notes`, payload, {
            headers: config.headers,
        }).then((response) => {
            if (response?.data?.statusCode === 201) {
                handleCancel();
                message.success('Notes created successfully');
                fetchNotes(id);
            }
        }).catch((error) => {
            console.error("Error creating note:", error);
            setLoading(false);
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
            .then((values) => {
                if (editingNoteId) {
                    handleUpdateNote();
                } else {
                    onFinish(values);
                }
            })
            .catch(() => {
                // console.log(errorInfo);
            });
    };

    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);
    };



    const handleTextAreaBlur = () => { };



    const handleEditNote = (noteId, noteBody, notePrivate) => {
        setEditingNoteId(noteId);
        setTextAreaValue(noteBody);
        form.setFieldsValue({ notes: noteBody, visibleToMe: notePrivate });
        setTextAreaHeight(140);
        setBottomNotesVisible(true);
    };

    const handleUpdateNote = () => {
        const payload = {
            "note_id": editingNoteId,
            "body": textAreaValue,
        };

        axios.post(`${config.apiUrl}api/vendor/note/update`, payload, {
            headers: config.headers,
        }).then((response) => {
            if (response?.data?.statusCode === 201) {
                handleCancel();
                message.success('Note updated successfully');
                fetchNotes(id);
            }
        }).catch((error) => {
            console.error("Error updating note:", error);
            setLoading(false);
        });
    };

    const handleDeleteNote = (noteId) => {
        const payload = {
            "note_id": noteId,
        };

        axios.post(`${config.apiUrl}api/vendor/note/delete`, payload, {
            headers: config.headers,
        }).then((response) => {
            if (response?.data?.statusCode === 201) {
                message.success('Note deleted successfully');
                fetchNotes(id);
            }
        }).catch((error) => {
            console.error("Error deleting note:", error);
            setLoading(false);
        });
    };

    const isSaveDisabled = !textAreaValue.trim();

    return (
        <>
            <div className='flex justify-between gap-2 items-center cursor-pointer bg-white notes-border-class-parent'
                onClick={handleOverall}  >
                <div>
                    <h4 className="text-lg font-semibold mb-4">
                        Notes {fetchNote && fetchNote.length > 0 && `(${fetchNote.length})`}
                    </h4>
                </div>
                <div>
                    {textAreaHeightOverall !== null ? <ChevronUpOutline /> : <ChevronDownOutline />}
                </div>
            </div>
            <div className=' w-full notes-border-class'
                style={{
                    height: textAreaHeightOverall,
                }}
            >
                <div className='notes-field'>
                    <Form
                        form={form}
                        name="notesForm"
                        layout="vertical"
                        onFinish={editingNoteId ? handleUpdateNote : onFinish}
                    >
                        <Form.Item name="notes" className='mb-0'
                            style={{
                                height: textAreaHeight,
                            }}
                        >
                            <TextArea
                                value={textAreaValue}
                                onChange={handleTextAreaChange}
                                placeholder={`${visibleToMe ? "Visible only to you" : "Visible to SaaS22 admin only. Use the reply button to respond to the customer"}`}
                                style={{
                                    height: textAreaHeight,
                                    resize: 'none',
                                }}
                                onFocus={handleTextAreaFocus}
                                onBlur={handleTextAreaBlur}
                                onClick={() => setTextAreaHeightOverall(250)}
                            />
                        </Form.Item>
                        {bottomNotesVisible &&
                            <div className='bottom-notes flex justify-between items-center'>
                                <Form.Item name="visibleToMe" valuePropName="checked" disabled={editingNoteId}>
                                    <Checkbox disabled={editingNoteId} onChange={(e) => setVisibleToMe(e.target.checked)}>Visible only to me</Checkbox>
                                </Form.Item>
                                <div className='flex gap-4 items-center'>
                                    <span
                                        className='text-[#333333] font-bold cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </span>
                                    <Button type="primary" onClick={handleSubmit} htmlType="submit" disabled={isSaveDisabled}>
                                        {editingNoteId ? "Update" : "Save"}
                                    </Button>
                                </div>
                            </div>
                        }
                    </Form>
                </div>
                {textAreaHeightOverall !== null &&
                    <>
                        {fetchNote && fetchNote.map((note) => (
                            <div className='mt-4 note-history relative cursor-pointer' key={note.id}>
                                <div className='flex flex-row gap-2'>
                                    <div className='Grayish'>
                                        <ClipboardListOutline width={20} />
                                    </div>
                                    <div>
                                        <div className='mb-2'>{note.body}</div>
                                        <div className='flex flex-row gap-2 mb-2 items-center'>
                                            {note?.privacy === true &&
                                                <div className='Grayish'>
                                                    <LockClosedOutline width={14} />
                                                </div>
                                            }
                                            <div className='flex flex-row gap-2 items-center'>
                                                {note.user_name}<Tag color='blue'>Partner</Tag>
                                            </div>
                                            <div>{moment(note.created_at).fromNow()}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='flex items-center justify-between gap-3 parent-icon absolute top-0 bottom-4 right-0'>
                                    <div className='edit-delteicon flex items-center justify-center'
                                        onClick={() => handleEditNote(note?.note_id, note?.body, note?.privacy)}>
                                        <Pencil className='edit-icon' />
                                    </div>
                                    <div className='edit-delteicon flex items-center justify-center'
                                        onClick={() => {
                                            setNoteToDelete(note?.note_id);
                                            setIsOpenConfirmationModal(true);
                                        }}>
                                        <Trash className='delete-icon' />
                                    </div>
                                </div>
                            </div>
                        ))}
                        {isOpenConfirmationModal && (
                            <ConfirmationModal
                                handleOk={() => {
                                    handleDeleteNote(noteToDelete);
                                    setIsOpenConfirmationModal(false);
                                }}
                                handleCancel={() => setIsOpenConfirmationModal(false)}
                                selectedProjectId={noteToDelete}
                                open={isOpenConfirmationModal}
                                modalDesc="Are you sure you want to delete this note?"
                            />
                        )}
                    </>
                }
            </div>
        </>
    );
}

export default NotesRequest;