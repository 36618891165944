import React, {
    useEffect,
    useState
} from 'react';
import {
    Link,
    useParams
} from 'react-router-dom';
import axios from 'axios';
import { ChevronLeftOutline } from 'heroicons-react';
import {
    Col,
    Row,
    Input,
    Button,
    Tag,
    Form,
    Upload,
    Spin,
    message,
    Select
} from 'antd';
import config from '../../confiq/confiq';
import { useCountryTimeZoneData } from '../../CommonApiService/commonApi';
import { useAuth } from '../../context';
import { UploadButton } from '../../components/common';
import { getBase64 } from '../../utils';

const { Option } = Select;
const { TextArea } = Input;

function PartnerEdit() {
    const [partnerUserData, setPartnerUserData] = useState(null);
    const [loading, setLoading] = useState(true);
    const { userToken } = useAuth();
    const { countryWithTimezone } = useCountryTimeZoneData(userToken);
    const [imageUrl, setImageUrl] = useState();
    const [imageId, setImageId] = useState();
    const [form] = Form.useForm();
    const { id } = useParams();

    useEffect(() => {
        axios
            .get(`${config.apiUrl}api/admin/userdetails/${id}`, {
                headers: config.headers,
            })
            .then((response) => {
                setPartnerUserData(response?.data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
                setLoading(false);
            });
    }, [id]);

    const handleSave = async (values) => {
        try {
            const payload = {
                id: parseInt(id),
                email: values?.email,
                first_name: values?.firstName,
                last_name: values?.lastName,
                social_links: {
                    linkedin: values?.linkedInURL,
                },
                phone: values?.phoneNumber,
                location: values.location || partnerUserData?.country_timezone,
                country_timezone: values.location || partnerUserData?.country_timezone,
                image_id: imageId !== undefined && imageId !== null ? String(imageId) : partnerUserData?.image_id,
                title: values?.title,
                partner_details: {
                    id: partnerUserData?.partner_details?.id,
                    company: values?.company,
                    website: values?.website,
                    service: values?.servicesOffered,
                },
            };

            const response = await axios.post(`${config.apiUrl}api/admin/edit/partner`, payload, {
                headers: config.headers,
            });

            if (response?.status === 201) {
                message.success('Partner details updated successfully');
                // navigate('/users')
            } else {
                message.error('Failed to update partner details');
            }
        } catch (error) {
            console.error('Error updating partner details:', error);
            message.error('Failed to update partner details');
        }
    };

    const onValuesChange = () => {
        form.setFieldsValue({ isFormChanged: true });
    };
    const beforeUpload = (file) => {
        if (file.length > 1) {
            message.error('You can only upload one file at a time!');
            return false;
        }
        return true;
    };

    const handleChange = (info) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            getBase64(info.file.originFileObj, (url) => {
                setImageUrl(url);
            });
            const formData = new FormData();
            formData.append('file', info.file.originFileObj);
            formData.append('type', 'profile');

            axios.post(`${config.apiUrl}api/upload`, formData, {
                headers: config.headers,
            })
                .then(response => {
                    if (response?.data?.statuscode === 201) {
                        const newImageId = response?.data?.image_id;
                        setImageId(newImageId);
                    }
                })
                .catch(error => {
                    console.error('Error uploading file to API:', error);
                });
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    };

    const propsLogo = {
        name: "file",
        listType: "picture-card",
        className: "avatar-uploader",
        showUploadList: false,
        action: `${config.apiUrl}api/upload`,
        headers: config.headers,
        method: "POST",
        beforeUpload,
        accept: 'image/*',
        onChange: handleChange,
    };

    if (loading) {
        return <Spin className='loader' tip='Loading...' />;
    }

    const propsLogo2 = {
        name: "file",
        showUploadList: false,
        action: `${config.apiUrl}api/upload`,
        headers: config.headers,
        method: "POST",
        onChange: handleChange,
        beforeUpload,
        accept: 'image/*',
    }


    const handleRemoveImage = () => {
        setImageId(null);
        axios.post(`${config.apiUrl}api/user/info`, {
            image_id: null,
        }, {
            headers: config.headers,
        }).then(response => {
            if (response?.data?.statusCode === 201) {
                window.location.reload();
            }
        }).catch(error => {
            setLoading(false);
            console.error('Error removing image from API:', error);
        });
    };
    return (
        <div className="w-3/4">
            <div className="flex flex-row gap-2 items-center mb-5">
                <Link to="/users" className="text-black">
                    <ChevronLeftOutline />
                </Link>
                <h3 className="text-lg text-black font-semibold capitalize flex gap-2 items-center">
                    Edit<Tag color="blue">Partner</Tag>
                </h3>
            </div>
            <Form
                form={form}
                onFinish={handleSave}
                onValuesChange={onValuesChange}
                layout="vertical"
                initialValues={{
                    firstName: partnerUserData?.first_name,
                    lastName: partnerUserData?.last_name,
                    location: partnerUserData?.country_timezone,
                    phoneNumber: partnerUserData?.phone,
                    linkedInURL: partnerUserData?.social_links?.linkedin,
                    company: partnerUserData?.partner_details?.company,
                    website: partnerUserData?.partner_details?.website,
                    email: partnerUserData?.email,
                    title: partnerUserData?.title,
                    isFormChanged: false,
                    servicesOffered: partnerUserData?.partner_details?.service
                }}
            >
                <div className="mb-5">
                    <h3 className="text-xl text-black font-semibold capitalize mb-4">Personal Information</h3>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="Profile Picture"
                                name="profilePicture"
                                valuePropName="fileList"
                                getValueFromEvent={(e) => e.fileList}
                            >
                                <div className='flex flex-row items-center gap-4'>
                                    <div>
                                        <Upload
                                            {...propsLogo}
                                            className='bd-radius w-auto'

                                        >
                                            {imageUrl ? (
                                                <img
                                                    src={imageUrl}
                                                    alt="profile"
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                />
                                            ) : (
                                                partnerUserData?.image ? (
                                                    <img
                                                        src={partnerUserData?.image?.path}
                                                        alt="profile"
                                                        className='img-url'
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    />
                                                ) : (
                                                    <UploadButton loading={loading} />
                                                )
                                            )}
                                        </Upload>
                                    </div>
                                    <div>
                                        <Upload  {...propsLogo2} showUploadList={false}>
                                            <span className='font-semibold text=[14px] cursor-pointer text-[#5D5FF3] mr-4'>Upload Image</span>
                                        </Upload>
                                        <span onClick={handleRemoveImage} className='font-semibold cursor-pointer text=[14px] text-[#EA5A54]'>Remove</span>
                                    </div>

                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: '' },
                                { max: 30, message: 'Maximum 30 characters allowed' },]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                    { max: 30, message: 'Maximum 30 characters allowed' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="location"
                                label="Country / Timezone"
                            >
                                <Select
                                    placeholder="Select Country / Timezone"
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countryWithTimezone && countryWithTimezone?.map((location) => (
                                        <Option key={location?.id} value={location['country&timezone']}>
                                            {location['country&timezone']}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Phone Number"
                                name="phoneNumber"
                                rules={[
                                    { required: true, message: "" },
                                    { max: 15, message: 'Maximum 15 characters allowed' },
                                    () => ({
                                        validator(_, value) {
                                            const phoneNumerRegex = /^[0-9]{8,15}$/;
                                            if (value && !phoneNumerRegex.test(value)) {
                                                return Promise.reject(
                                                    new Error("Please enter a valid Phone number")
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="LinkedIn URL"
                                name="linkedInURL"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <div>
                    <h3 className="text-xl text-black font-semibold capitalize mb-4">Company Information</h3>
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="Company Name"
                                name="company"
                                rules={[
                                    { max: 100, message: 'Maximum 100 characters allowed' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Website"
                                name="website"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Email"
                                name="email"
                                disabled
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[
                                    { max: 50, message: 'Maximum 50 characters allowed' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={24}>
                            <Form.Item
                                name="servicesOffered"
                                label="Services Provided"
                                rules={[{ required: true, message: "" }]}
                            >
                                <TextArea
                                    maxLength={400}
                                    style={{ height: 120, resize: "none" }}
                                    placeholder="Eg: ERP Consulting and SI, Web Development, ... "
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
                <Button type="primary" htmlType="submit">
                    Save
                </Button>
            </Form>
        </div>
    );
}

export default PartnerEdit;
