import handshake from "../../assets/img/handShake.svg";
import { AuthLogo } from "../auth/signIn";

const SuccessPage = () => {
  return (
    <div className="pt-8 items-center flex flex-col gap-6">
      <AuthLogo />
      <div className="flex flex-col justify-center items-center gap-10 p-14">
        <img src={handshake} alt="success" />
        <div className="flex flex-col justify-center items-center gap-6">
          <p className="text-base font-semibold">Application Submitted!</p>
          <div>
            <p className="text-base font-normal text-center">
              Your application has been submitted successfully.
            </p>
            <p className="text-base font-normal text-center">
              We'll get in touch with you soon.
            </p>
          </div>
          {/* <Button
            className="ml-4"
            type="primary"
            onClick={() => navigate("/resources")}
          >
            Back to SaaS22
          </Button> */}
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
