import React, { useState, useEffect } from 'react';
import { notFoundPage } from '../assets/img';
import { Button, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context';
import { useUserRole } from '../context/userContext';

function Notfound(props) {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { userToken } = useAuth();
    const userRole = useUserRole();

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    const handleRetry = () => {
        if (userRole && userToken) {
            navigate('/home');
        } else {
            navigate('/resources');
        }
    };

    return (
        <>
            {loading ? (
                <div className='flex items-center justify-center'>
                        <Spin className='loader' tip='Loading...' />
                </div>
            ) : (
                <div className='not-found-page flex items-center justify-center flex-col text-center gap-4'>
                    <img src={notFoundPage} alt='not found' className='mb-2' />
                    <div className='font-semibold'>404 - Page not found</div>
                    <div className='mb-2'>
                        The page you are looking for might be removed or temporarily unavailable
                    </div>
                    <Button type='primary' onClick={handleRetry}>
                        Retry
                    </Button>
                </div>
            )}
        </>
    );
}

export default Notfound;
