import {
  Col,
  Dropdown,
  Modal,
  Row,
  Space,
  Spin,
  Tabs,
  Tag,
  message,
} from "antd";
import axios from "axios";
import {
  ArrowLeft,
  ChevronLeftOutline,
  DotsVerticalOutline,
  GlobeAltOutline,
  LinkOutline,
  PencilAltOutline,
  StarOutline,
  TrashOutline,
} from "heroicons-react";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../confiq/confiq";
import {
  TwitterX,
  TwitterXBlack,
  linkedInPartnerinfo,
  postFavourtie,
} from "../../assets/img";
import { useAuth } from "../../context";
import { Share } from "@phosphor-icons/react/dist/ssr";
import {
  Envelope,
  FacebookLogo,
  LinkedinLogo,
  SlackLogo,
  WhatsappLogo,
} from "@phosphor-icons/react";
import CopyToClipboard from "react-copy-to-clipboard";
import { isValidUrl } from "../../utils";
import SignInPopup from "../../components/Modal/signIn";
import { useUserRole } from "../../context/userContext";
import { Helmet } from "react-helmet";

export default function ResourceDetailPage() {
  const [resourceDetail, setResourceDetail] = useState(null);
  const [, setHeadings] = useState([]);
  const [favorited, setFavorited] = useState(false);
  const [strapiUrl, setStrapiUrl] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [, setActiveHeading] = useState(null);
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [isBookmarked, setIsBookmarked] = useState();
  const { userToken } = useAuth();
  const userRole = useUserRole();
  const [showIndexModal, setShowIndexModal] = useState(false);

  const { pageName, id, slug } = useParams();

  const navigate = useNavigate();
  const currentUrl = window.location.href;

  const indexList = resourceDetail?.attributes?.Detail?.map(
    (detail) => detail.index
  );

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${config.apiUrl}api/${pageName}/${id}`,
        {
          headers: config.headers,
        }
      );
      const foundProject = response?.data?.data?.data;
      setMetrics(response?.data?.data?.metrics);
      setResourceDetail(foundProject);
      setIsBookmarked(response?.data?.data?.is_bookmarked);
      const headingRegex = /^#{1,6}\s(.+)$/gm;
      const matches = foundProject?.attributes?.detailInfo?.match(headingRegex);

      if (matches) {
        const extractedHeadings = matches?.map((match) =>
          match.replace(/^#\s/, "")
        );
        setHeadings(extractedHeadings);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, id, pageName]);

  const handleDeleteClick = async (id) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}api/${pageName}/remove/${id}`,
        {},
        {
          headers: config.headers,
        }
      );
      if (response?.data?.statusCode === 201) {
        navigate("/resources");
        message.success("Story deleted successfully");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        message.error("Story is not  deleted successfully");
      }
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };


  const handleMenuItemClick = async (item) => {
    switch (item.key) {
      case "1":
        break;
      case "2":
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            currentUrl
          )}`,
          "_blank"
        );
        break;
      case "3":
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            currentUrl
          )}&text=Your%20tweet%20text`,
          "_blank"
        );
        break;
      case "4":
        window.open(
          `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
            currentUrl
          )}`,
          "_blank"
        );
        break;
      case "5":
        window.open(
          `https://api.whatsapp.com/send?text=${encodeURIComponent(
            ""
          )}%20${encodeURIComponent(currentUrl)}`,
          "_blank"
        );
        break;
      case "6":
        window.open(
          `slack://open?team=your_slack_team&channel=your_slack_channel&text=${encodeURIComponent(
            ""
          )}`,
          "_blank"
        );
        break;
      case "7":
        window.open(
          `mailto:?subject=Your%20subject&body=${encodeURIComponent(
            `${currentUrl}`
          )}%20${encodeURIComponent(currentUrl)}`,
          "_blank"
        );
        break;
      default:
        break;
    }

    const payload = {
      metric_id: id,
      metric_type: pageName === "guides" ? "guide" : "story",
      share_count: 1,
    };
    try {
      const response = await axios.post(
        `${config.apiUrl}api/metrics/save`,
        payload,
        {
          headers: config.headers,
        }
      );
      if (response?.data?.statusCode === 201) {
        fetchData();
      }
    } catch (error) {
      console.error("Error saving metrics:", error);
    }
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "Copied successfully",
    });
  };

  const items = [
    {
      key: "1",
      label: (
        <CopyToClipboard text={currentUrl}>
          <div className="flex flex-row gap-2 items-center" onClick={success}>
            <LinkOutline size={16} /> Copy Link
          </div>
        </CopyToClipboard>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="flex flex-row gap-2 items-center"
          onClick={() => handleMenuItemClick(items[1])}
        >
          <FacebookLogo size={17} /> Share on Facebook
        </div>
      ),
    },

    {
      key: "3",
      label: (
        <div
          className="flex flex-row gap-2 items-center"
          onClick={() => handleMenuItemClick(items[2])}
        >
          <img src={TwitterXBlack} alt="twitter black" /> Share on X
        </div>
      ),
    },

    {
      key: "4",
      label: (
        <div
          className="flex flex-row gap-2 items-center"
          onClick={() => handleMenuItemClick(items[3])}
        >
          <LinkedinLogo size={17} /> Share on LinkedIn
        </div>
      ),
    },
    {
      key: "5",
      label: (
        <div
          className="flex flex-row gap-2 items-center"
          onClick={() => handleMenuItemClick(items[4])}
        >
          <WhatsappLogo size={17} /> Share on Whatsapp
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <CopyToClipboard text={currentUrl}>
          <div
            className="flex flex-row gap-2 items-center"
            onClick={() => handleMenuItemClick(items[5])}
          >
            <SlackLogo size={17} /> Share on Slack
          </div>
        </CopyToClipboard>
      ),
    },
    {
      key: "7",
      label: (
        <div
          className="flex flex-row gap-2 items-center"
          onClick={() => handleMenuItemClick(items[6])}
        >
          <Envelope size={17} /> Share via Mail
        </div>
      ),
    },
  ];

  const handleFavoriteClick = (event) => {
    event.preventDefault();
    setFavorited(!favorited);
  };

  const postBookmark = async () => {
    if (!userRole || !userToken) {
      setShowSignInPopup(true);
    } else {
      const payload = {
        bookmark_id: resourceDetail?.id,
        bookmark_type: pageName === "guides" ? "guide" : "story",
      };

      try {
        const response = await axios.post(
          `${config.apiUrl}api/bookmark/save`,
          payload,
          {
            headers: config.headers,
          }
        );
        if (response?.data?.statusCode === 201) {
          fetchData();
        } else {
          message.error(
            "Sorry, we couldn't add this project to your favorites. Please try again later."
          );
          setLoading(false);
        }
      } catch (error) {
        console.error("Error validating token:", error);
      }
    }
  };

  const removeBookmark = async () => {
    if (!userRole || !userToken) {
      setShowSignInPopup(true);
    } else {
      const payload = {
        bookmark_id: resourceDetail?.id,
        bookmark_type: pageName === "guides" ? "guide" : "story",
      };
      try {
        const response = await axios.post(
          `${config.apiUrl}api/bookmark/remove`,
          payload,
          {
            headers: config.headers,
          }
        );
        if (response?.data?.statusCode === 201) {
          fetchData();
        } else {
          message.error(
            "Sorry, we couldn't remove this from your saved items. Please try again later."
          );
          setLoading(false);
        }
      } catch (error) {
        console.error("Error validating token:", error);
      }
    }
  };

  const handleEditClick = () => {
    window.open(
      `${strapiUrl}admin/content-manager/collectionType/api::blog.blog/${id}`
    );
  };

  const AboutToolStack = [
    {
      key: "1",
      label: "About",
      children: <AboutTab resourceDetail={resourceDetail} />,
    },
    resourceDetail?.attributes?.tools?.data?.length > 0 && {
      key: "2",
      label: "Tool Stack",
      children: (
        <ToolStackTab2 resourceDetail={resourceDetail?.attributes?.tools} />
      ),
    },
  ];
  const headingRefs = useRef([]);

  const handleHeadingClick = (sectionId) => {
    const paddingOffset = 0;
    setActiveHeading(sectionId);

    const index = parseInt(sectionId.split("-")[1]);
    const indexElement = document.getElementById(`index-${index}`);
    if (indexElement) {
      indexElement.classList.add("active-section");
    }

    const element = document.getElementById(sectionId);
    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - paddingOffset;

      window.scrollTo({
        top: offsetPosition,
        // behavior: "smooth"
      });
    }
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const paddingOffset = 0;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const scrolledToBottom = (windowHeight + scrollPosition) >= (documentHeight - 1);

    for (let i = 0; i < headingRefs.current.length; i++) {
      const element = document.getElementById(`section-${i}`);
      if (element) {
        const topOffset = element.offsetTop - paddingOffset;
        const bottomOffset = topOffset + element.clientHeight;

        if (scrollPosition >= topOffset && scrollPosition < bottomOffset) {
          setActiveIndex(i);
          break;
        }
      }
    }

    if (scrolledToBottom && headingRefs?.current?.length > 1) {
      const lastSectionIndex = headingRefs?.current?.length - 1;
      setActiveIndex(lastSectionIndex);

      const lastSection = document.getElementById(`section-${lastSectionIndex}`);
      if (lastSection) {
        const lastSectionHeight = lastSection.clientHeight;
        const extraSpace = windowHeight - lastSectionHeight;

        window.scrollTo({
          top: documentHeight - windowHeight + extraSpace,
          // behavior: "smooth"
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleIndexModal = () => {
    setShowIndexModal(!showIndexModal);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{resourceDetail?.attributes?.meta_Title}</title>
        <meta name="description" content={resourceDetail?.attributes?.meta_Description} />
      </Helmet>
      <div
        className="flex md:hidden items-center gap-2 mb-2 font-semibold"
        onClick={() => navigate(-1)}
      >
        <ArrowLeft width={16} /> Back
      </div>
      {loading ? (
        <Spin className="loader" tip="Loading..." />
      ) : (
        <Row gutter={[40, 15]} className="story-detailpage">
          <Col
            md={6}
            lg={6}
            xl={6}
            className="sticky-left overflow-hidden hidden md:flex"
          >
            {indexList && indexList.length > 0 ? (
              <div className="index-parent">
                <h3 className="text-xl dark-gray font-bold mb-4 pl-16 pr-4">
                  Index
                </h3>
                <ul className="ul-index-max-h custom-scrollerbar2">
                  {indexList.map((index, i) => (
                    <li
                      key={i}
                      id={`index-${i}`}
                      onClick={() => handleHeadingClick(`section-${i}`)}
                      className={`mb-1 capitalize ${activeIndex === i ? "active-section" : ""
                        }`}
                      ref={(headingRef) =>
                        (headingRefs.current[i] = headingRef)
                      }
                    >
                      {i + 1}. {index}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </Col>
          <Col md={17} lg={17} xl={17} className="mw-detailcontent w-full">
            {contextHolder}
            {resourceDetail && (
              <>
                <div className="flex flex-row items-self-start justify-between gap-5 mb-5">
                  <div className="flex flex-row gap-4">
                    <div className="back-icon-align hidden md:flex cursor-pointer">
                      <ChevronLeftOutline onClick={() => navigate(-1)} />
                    </div>
                    <h3 className="text-[27px] text-black font-bold capitalize res-font-18">
                      {resourceDetail?.attributes?.title}
                    </h3>
                  </div>
                  {userToken && (
                    <div className="flex flex-row gap-4  back-icon-align">
                      {userRole === "Admin" ? (
                        <Dropdown
                          menu={{
                            items: [
                              {
                                key: "1",
                                icon: (
                                  <PencilAltOutline color="#1F2937" size={16} />
                                ),
                                label: "Edit",
                                onClick: handleEditClick,
                              },
                              {
                                key: "2",
                                icon: (
                                  <TrashOutline color="#1F2937" size={16} />
                                ),
                                label: "Delete",
                                onClick: () =>
                                  handleDeleteClick(resourceDetail?.id),
                              },
                            ],
                          }}
                          placement="bottomRight"
                        >
                          <div
                            className="cursor-pointer"
                            onClick={handleFavoriteClick}
                          >
                            <span className="ant-dropdown-link">
                              <DotsVerticalOutline />
                            </span>
                          </div>
                        </Dropdown>
                      ) : (
                        <div
                          onClick={handleFavoriteClick}
                          className="cursor-pointer w-12 flex justify-end"
                        >
                          {isBookmarked === false ? (
                            <StarOutline onClick={postBookmark} size={24} />
                          ) : (
                            <img
                              src={postFavourtie}
                              alt="post fav"
                              className="h-6"
                              onClick={removeBookmark}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="mb-5 flex justify-between items-center">
                  <div className="flex flex-wrap gap-2">
                    {resourceDetail?.attributes?.tags?.data?.map((tag) => (
                      <a href={tag?.attributes?.redirection_URL} target="_blank" key={tag?.id} rel="noreferrer" className={`${tag?.attributes.redirection_URL ? "cursor-pointer" : "cursor-default"}`}>
                        <Tag key={tag?.id} color="green">
                          {tag?.attributes?.name}
                        </Tag>
                      </a>
                    ))}
                  </div>
                  <div>
                    <Dropdown
                      menu={{
                        items,
                      }}
                    >
                      <Space className="gap-0 md:gap-2">
                        <Share size={22} />
                        <span className="hidden md:block">Share</span>
                      </Space>
                    </Dropdown>
                  </div>
                </div>
                <div className="mb-5">
                  {userRole === "Admin" && (
                    <Row gutter={[16, 16]} className="mb-5">
                      <DetailsInfoCard
                        label={"Views"}
                        value={
                          metrics?.view_count === null ? 0 : metrics?.view_count
                        }
                      />
                      <DetailsInfoCard
                        label={"Bookmarks"}
                        value={
                          metrics?.fav_count === null ? 0 : metrics?.fav_count
                        }
                      />
                      <DetailsInfoCard
                        label={"Requests"}
                        value={
                          metrics?.share_count === null
                            ? 0
                            : metrics?.share_count
                        }
                      />
                    </Row>
                  )}
                  {resourceDetail?.attributes?.partner?.data !== null && (
                    <div className="tab-secondary mb-5 story-page">
                      <Tabs defaultActiveKey="1" items={AboutToolStack} />
                    </div>
                  )}
                </div>
                <div className="mt-8">
                  {resourceDetail?.attributes?.summary}
                </div>
                <div className="mb-20">
                  <RenderContent resourceDetail={resourceDetail} />
                </div>
              </>
            )}
          </Col>
        </Row>
      )}
      <div className=" md:hidden index-mobileview" onClick={toggleIndexModal}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          class="w-6 h-6"
        >
          <path
            fill-rule="evenodd"
            d="M2.625 6.75a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0A.75.75 0 0 1 8.25 6h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75ZM2.625 12a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0ZM7.5 12a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12A.75.75 0 0 1 7.5 12Zm-4.875 5.25a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Zm4.875 0a.75.75 0 0 1 .75-.75h12a.75.75 0 0 1 0 1.5h-12a.75.75 0 0 1-.75-.75Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <Modal
        title="Index"
        visible={showIndexModal}
        className="mobile-index-modal"
        onCancel={toggleIndexModal}
        footer={null}
        centered
      >
        <ul className="mt-4">
          {indexList && indexList.length > 0 ? (
            indexList.map((index, i) => (
              <li
                key={i}
                id={`index-${i}`}
                onClick={() => handleHeadingClick(`section-${i}`)}
                className={`mb-3 capitalize ${activeIndex === i ? "active-section" : ""
                  }`}
                ref={(headingRef) => (headingRefs.current[i] = headingRef)}
              >
                {i + 1}. {index}
              </li>
            ))
          ) : (
            <li>No index found</li>
          )}
        </ul>
      </Modal>
      <SignInPopup
        visible={showSignInPopup}
        onClose={() => setShowSignInPopup(false)}
      />
    </>
  );
}

export const DetailsInfoCard = ({ label, value }) => (
  <Col span={8}>
    <div className="details-infocard midnight-blue border border-solid border-black rounded-lg p-2 md:px-4 md:py-4">
      <div className="mb-2">{label}</div>
      <div className="text-lg md:text-2xl font-medium">{value}</div>
    </div>
  </Col>
);

const AboutTab = ({ resourceDetail }) => {
  const partner = resourceDetail?.attributes?.partner?.data?.attributes;

  return (
    <>
      <div className="partnerInfo block lg:flex justify-between gap-4  mb-3">
        <div className="flex gap-4 w-full lg:w-2/5 mb-5 mrx-8">
          <div className="rounded-full detailpage-rounded">
            <img src={partner?.image?.data?.attributes?.url} alt="Partner" />
          </div>
          <div>
            <div className="midnight-blue text-sm font-semibold truncate overflow-hidden whitespace-nowrap max-w-150px">
              {" "}
              {partner?.name}
            </div>
            <div className="dark-gray">
              <div className="truncate overflow-hidden whitespace-nowrap max-w-150px	">
                {partner?.role}
              </div>

              <div className="truncate overflow-hidden whitespace-nowrap w-48">
                {partner?.company_Name}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-3/5">
          <Row gutter={[16, 16]} justify="space-between res-rowGap">
            <Col
              span={24}
              lg={8}
              className="text-center mrx-8 right-line res-right-line"
            >
              <div>Headquarters</div>
              <div className="md:text-sm font-medium ">
                {partner?.company_Headquarters}
              </div>
            </Col>
            <Col
              span={24}
              lg={8}
              className="text-center mrx-8  right-line res-right-line"
            >
              <div>Founded </div>
              <div className="md:text-sm font-medium">
                {partner?.company_FoundedYear}
              </div>
            </Col>
            <Col span={24} lg={8} className="text-center mrx-8 res-right-line">
              <div>Employees</div>
              <div className="md:text-sm font-medium">
                {partner?.company_No_Of_Employees.substring(1)}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="links ">
        <ul className="grid md:flex flex-row gap-2 justify-between">
          <li className="flex flex-row gap-2 items-center">
            <div className="partner-info-iconparent">
              <GlobeAltOutline size={17} color="#ffffff" />
            </div>
            {isValidUrl(partner?.website_URL) ? (
              <a
                href={partner?.website_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="purple-saas22 partner-card-link">
                  {partner?.website_URL}
                </div>
              </a>
            ) : (
              <div className="purple-saas22 partner-card-link">
                {partner?.website_URL}
              </div>
            )}
          </li>
          <li className="flex flex-row gap-2 items-center">
            <div className="partner-info-iconparent">
              <img src={linkedInPartnerinfo} alt={"TwitterX"} />
            </div>
            <a
              href={
                partner?.linkedin_Partner.startsWith("https")
                  ? partner?.linkedin_Partner
                  : `https://${partner?.linkedin_Partner}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="purple-saas22 partner-card-link">
                View Profile
              </div>
            </a>
          </li>
          <li className="flex flex-row gap-2 items-center">
            <div className="partner-info-iconparent">
              <img src={TwitterX} alt={"TwitterX"} />
            </div>
            <a
              href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                partner?.twitter
              )}&text=Your%20tweet%20text`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="purple-saas22 partner-card-link">
                {partner?.twitter}
              </div>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

const ToolStackTab2 = ({ resourceDetail }) => {
  const categories = [
    ...new Set(resourceDetail?.data?.map((item) => item?.attributes?.category)),
  ];

  return (
    <>
      <div className="flex flex-col gap-4">
        {categories.map((category, index) => (
          <div key={index} className="tools-section ">
            <h6 className="font-medium text-sm text-black mb-2">{category}</h6>
            <div className="flex flex-wrap gap-2">
              {resourceDetail?.data
                .filter((item) => item?.attributes?.category === category)
                .map((tool) => (
                  <div
                    key={tool.id}
                    className="tool-item flex gap-2 flex-row items-center"
                  >
                    {tool.attributes.image && (
                      <img
                        src={tool?.attributes?.image?.data?.attributes?.url}
                        alt={tool.attributes?.image?.data?.attributes?.url}
                        className="tool-image"
                      />
                    )}
                    <p>{tool?.attributes?.name}</p>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const RenderContent = ({ resourceDetail }) => {
  if (!resourceDetail) {
    return null;
  }

  const contentArray = Array.isArray(resourceDetail?.attributes?.Detail)
    ? resourceDetail?.attributes?.Detail
    : [];

  return contentArray?.map((component, index) => {
    const isLastIndex = index === contentArray.length - 1;

    switch (component.__component) {
      case "story-detail.index-and-content":
        return (
          <div
            key={index}
            id={`section-${index}`}
            className={`story-detail-section content-resources ${component?.ishover ? "container-class" : ""
              } ${isLastIndex ? "last-index-class" : ""}`}
          >
            <h6 className="text-lg md:text-2xl font-bold pb-4">
              {component?.Heading}
            </h6>
            <div
              dangerouslySetInnerHTML={{ __html: component.content }}
              className="container-class markdown"
            />
          </div>
        );
      default:
        return null;
    }
  });
};
