import { Spin } from "antd";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SearchInput from "../../../components/SearchInput";
import { ResourceCard } from "../../../components/Card/resource";
import axios from "axios";
import config from "../../../confiq/confiq";
import SearchEmpty from "../../../components/searchEmpty";
import FilterShowtext from "../../../components/filter";
import Pagination from "../../../components/paginationSet";
import FilterIcon, { ShowingText } from "../../../components/filterIcon";
import StoriesDrawer from "./storiesDrawer";
import { StaticContent } from ".";

export const MostRead = forwardRef((props, ref) => {
  const [selectedFunction, setSelectedFunction] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [product, setProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [functionFilter, setFunctionFilter] = useState(null);
  const [industryFilter, setIndustryFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredProduct, setFilteredProduct] = useState();
  const [open, setOpen] = useState(false);

  const PAGE_SIZE = 15;

  const resetFilters = () => {
    setSelectedFunction(null);
    setSelectedIndustry(null);
    setSelectedCategory(null);
    setFunctionFilter(null);
    setIndustryFilter(null);
    setCategoryFilter(null);
    fetchData();
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.apiUrl}api/story/mostread?page=${currentPage}&pageSize=${PAGE_SIZE}&ind=${industryFilter}&fun=${functionFilter}&cat=${categoryFilter}&search=${searchQuery}`,
        {},
        {
          headers: config.headers,
        }
      );
      setTotalPages(
        Math.ceil(response?.data?.data?.meta?.pagination?.total / PAGE_SIZE)
      );
      setProduct(response?.data?.data?.data);
      setFilteredProduct(response?.data?.data?.meta?.pagination?.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    PAGE_SIZE,
    industryFilter,
    functionFilter,
    categoryFilter,
    searchQuery,
  ]);

  useImperativeHandle(
    ref,
    () => ({
      fetchData: fetchData,
    }),
    [fetchData]
  );

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    PAGE_SIZE,
    industryFilter,
    functionFilter,
    categoryFilter,
    searchQuery,
    fetchData,
  ]);

  const handleFilterChange = (value, setFilter, setSelectedFilter) => {
    setFilter(value);
    setSelectedFilter(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePaginationClick = (type) => {
    setCurrentPage((prevPage) =>
      type === "prev" ? prevPage - 1 : prevPage + 1
    );
  };

  const showDrawer = () => {
    setOpen(true);
  };

  return (
    <div className="mt-2">
      <StaticContent />
      <div className="flex justify-between gap-2">
        <SearchInput
          placeholder="Try searching here"
          {...props}
          onChange={handleSearchChange}
          className="mb-4"
        />
        <FilterIcon
          condition={selectedFunction || selectedIndustry || selectedCategory}
          onClick={showDrawer}
        />
      </div>
      <div className="items-center justify-between mb-4 flex">
        <ShowingText
          product={product}
          filteredProduct={filteredProduct}
          name={"stories"}
        />
        <div className="hidden md:flex">
          <FilterShowtext
            filteredProduct={filteredProduct}
            product={product}
            selectedFunction={selectedFunction}
            selectedIndustry={selectedIndustry}
            selectedCategory={selectedCategory}
            resetFilters={resetFilters}
            handleFilterChange={handleFilterChange}
            setFunctionFilter={setFunctionFilter}
            setSelectedFunction={setSelectedFunction}
            setIndustryFilter={setIndustryFilter}
            setSelectedIndustry={setSelectedIndustry}
            setCategoryFilter={setCategoryFilter}
            setSelectedCategory={setSelectedCategory}
            functionTrue={true}
            categoryTrue={true}
            showingName={"stories"}
          />
        </div>
      </div>

      {loading ? (
        <Spin className="loader" tip="Loading..." />
      ) : product && product.length > 0 ? (
        <ResourceCard
          product={product}
          fetchData={fetchData}
          setLoading={setLoading}
          pageName={"story"}
          modalDesc="Are you sure want to delete this story?"
        />
      ) : (
        <SearchEmpty filteredProduct={filteredProduct} />
      )}
      {product && product.length > 0 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePaginationClick={handlePaginationClick}
          product={product}
          filteredProduct={filteredProduct}
          showingName={"stories"}
        />
      ) : (
        ""
      )}
      <StoriesDrawer
        open={open}
        setOpen={setOpen}
        selectedFunction={selectedFunction}
        selectedIndustry={selectedIndustry}
        selectedCategory={selectedCategory}
        resetFilters={resetFilters}
        handleFilterChange={handleFilterChange}
        setFunctionFilter={setFunctionFilter}
        setSelectedFunction={setSelectedFunction}
        setIndustryFilter={setIndustryFilter}
        setSelectedIndustry={setSelectedIndustry}
        setCategoryFilter={setCategoryFilter}
        setSelectedCategory={setSelectedCategory}
        filteredProduct={filteredProduct}
        product={product}
      />
    </div>
  );
});
