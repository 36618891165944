import React, { useEffect, useState } from "react";
import { Button, Form, message, Tag, Select, Tooltip } from "antd";
import { CheckCircleOutline, X } from "heroicons-react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AvatarIcon } from "../assets/img";
import axios from "axios";
import config from "../confiq/confiq";

const InviteUserInput = ({ handleSendInvite, setInviteEmails, emailList, userRole }) => {
  const [form] = Form.useForm();
  const [inviteSentCount, setInviteSentCount] = useState(0);
  const [emailLists, setEmailLists] = useState({});
  const [partnerAllow, setPartnerAllow] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [partnerResponse, buyerResponse, adminResponse] = await Promise.all([
          axios.get(`${config.apiUrl}api/admin/users/partner`, { headers: config.headers }),
          axios.get(`${config.apiUrl}api/admin/users/buyer`, { headers: config.headers }),
          axios.get(`${config.apiUrl}api/admin/users/admin`, { headers: config.headers }),
          axios.get(`${config.apiUrl}api/vendor/getAllApplicantEmail`, { headers: config.headers }),
        ]);

        const partnerData = partnerResponse.data?.data?.map(data => ({ email: data?.email, status: data?.status }));
        const buyerData = buyerResponse.data?.data?.map(data => ({ email: data?.email, status: data?.status }));
        const adminData = adminResponse.data?.data?.map(data => ({ email: data?.email, status: data?.status }));

        setEmailLists({
          partner: partnerData,
          buyer: buyerData,
          admin: adminData
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const tagRender = (props) => {
    const { label, onClose } = props;
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let tooltipTitle = "";

    const isValidEmail = regex.test(label);
    const isAlreadyInvited = emailLists?.partner?.some(emailObj => emailObj.email === label) ||
      emailLists?.buyer?.some(emailObj => emailObj.email === label) ||
      emailLists?.admin?.some(emailObj => emailObj.email === label);

    // if (isAlreadyInvited) {
    //   tooltipTitle = "This email id already exists";
    // }
    setPartnerAllow(isAlreadyInvited);
    return (
      <div className="userEmail">
        {isValidEmail && !isAlreadyInvited ? (
          <Tag
            // eslint-disable-next-line jsx-a11y/alt-text
            icon={<img src={AvatarIcon} className="avatar-icon" />}
            className="tag tagSuccess"
            onClose={onClose}
            closeIcon={
              <span className="close-icon">
                <X
                  style={{ display: "inline-flex", alignItems: "center" }}
                  width={14}
                />
              </span>
            }
          >
            {label}
          </Tag>
        ) : (
          <>
            <Tooltip title={tooltipTitle} className="userInviteTooltip">
              <Tag
                icon={<ExclamationCircleOutlined className="text-red" />}
                className="tag tagFail"
                onClose={onClose}
                closeIcon={
                  <span className="close-icon">
                    <X
                      style={{ display: "inline-flex", alignItems: "center" }}
                      width={14}
                    />
                  </span>
                }
              >
                {label}
              </Tag>
            </Tooltip>
          </>
        )}
      </div>
    );
  };

  const onFinish = async (values) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const validEmails = values.emails.filter((email) => emailRegex.test(email));
    const commonValues = validEmails.filter(value => !Object.values(emailLists).flat().includes(value));
    if (partnerAllow) {
      form.setFields([
        {
          errors: [
            "Email ID invalid or already in use",
          ],
          name: "emails",
        },
      ]);
    } else {
      if (commonValues.length === 0) {
        form.setFields([
          {
            errors: [
              "Email ID invalid or already in use",
            ],
            name: "emails",
          },
        ]);
      } else if (validEmails?.length > 0 && commonValues.length > 0) {
        try {
          const emailsArray = commonValues?.map((email) => email?.trim());

          if (!emailsArray || emailsArray.length === 0) {
            message.error("Please enter at least one valid email address.");
            return;
          }
          await handleSendInvite(emailsArray);
          setInviteEmails([]);
          form.resetFields();
          setInviteSentCount(emailsArray?.length);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } catch (error) {
          console.error("Error sending invite:", error);
          message.error("Failed to send invite. Please try again.");
        }
      } else if (validEmails?.length === 0) {
        form.setFields([
          {
            errors: ["Please enter at least one valid email address."],
            name: "emails",
          },
        ]);
      }
    }

  };

  return (
    <div className="invite mb-5 p-5 border border-gray-300 rounded-md">
      <div className="mb-2 flex justify-between">
        <div>
          <div className="text-lg midnight-blue font-semibold">
            Invite Users
          </div>
          <span className="text-[#717171]">Invite users via email</span>
        </div>
        {inviteSentCount > 0 && (
          <div className="flex gap-2 items-center text-[#065F46]">
            <CheckCircleOutline height={20} color="#43A047" />
            Invite sent successfully
          </div>
        )}
      </div>
      <Form form={form} onFinish={onFinish}>
        <div className="flex gap-4 justify-between w-full">
          <Form.Item
            name="emails"
            className="mb-0 w-full"
            rules={[
              {
                required: true,
                message: "Please enter at least one valid email address.",
              },
            ]}
          >
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Enter mail separated with commas"
              popupClassName="hidden"
              suffixIcon={<></>}
              size="large"
              tokenSeparators={[","]}
              tagRender={tagRender}
            />
          </Form.Item>

          <div>
            <Button type="primary" htmlType="submit">
              Send Invite
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default InviteUserInput;