import React, {
    useEffect,
    useState
} from 'react';
import {
    Button,
    Form,
    Row,
    Col,
    Checkbox,
    Spin,
} from 'antd';
import SearchInput from '../../../components/SearchInput';
import {
    useNavigate
} from 'react-router-dom';
import axios from 'axios';
import config from '../../../confiq/confiq';
import SearchEmpty from '../../../components/searchEmpty';


export const OnboardStep2 = () => {
    const [selectedTools, setSelectedTools] = useState([]);
    const [toolsData, setToolsData] = useState([]);
    const [form] = Form.useForm();
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();


    useEffect(() => {
        axios.get(`${config.apiUrl}api/tools/getAll?page=1&pageSize=50&search=${searchQuery}`, {
            headers: config.headers,
        }).then(response => {
            const tools = response?.data?.data;
            setToolsData(tools);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
        });
    }, [searchQuery])

    const handleCheckboxChange = (tool, e) => {
        const toolId = tool?.id;

        if (e?.target?.checked) {
            setSelectedTools(prevSelectedTools => [...prevSelectedTools, toolId]);
        } else {
            setSelectedTools(prevSelectedTools => prevSelectedTools?.filter(id => id !== toolId));
        }

        form.setFieldsValue({ selectedTools });
    };

    const handleFinishStep2 = (values) => {
        const newlySelectedTools = toolsData
            ?.filter(tool => tool?.is_selected)
            ?.map(tool => tool?.id);

        const allSelectedTools = [...selectedTools, ...newlySelectedTools];
        const payload = {
            tool_id: allSelectedTools,
        };

        axios.post(`${config.apiUrl}api/tools/save`, payload, {
            headers: config.headers,
        }).then(response => {
            if (response?.data) {
                navigate('/home');
            }
        }).catch(error => {
            console.error('Error saving tools:', error);
        });
    };

    const handleSkip = () => {
        navigate('/home');
    }

    const toolDataMap = [...new Set(toolsData?.map(item => item?.attributes?.category))];

    if (loading) {
        return <Spin className='loader' tip='Loading...' />;
    }
    return (
        <div className='md:mt-8 ' >
            <div className='text-center mb-8'>
                <h2 className='text-lg md:text-3xl  mb-2 font-bold'>Tools you use at work</h2>
                <div>This will help us personalise your experience </div>
            </div>
            <Form name="step2"
                onFinish={handleFinishStep2}
                layout='vertical'
                form={form}
                className='pb-24'
            >
                <Row gutter={16} >
                    <Col span={24} className='mb-4'>
                        <SearchInput
                            placeholder="Search by categories, tools, etc"
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Col>
                    <Col span={24}>
                        <div className='flex flex-col gap-4 res-tool-item'>
                            {toolDataMap?.map((category, index) => (
                                <div key={index} className='tools-section'>
                                    <h6 className='text-base md:text-lg text-black mb-2 font-medium'>{category}</h6>
                                    <Row gutter={[16, 16]} >
                                        {toolsData && toolsData?.filter(item => item?.attributes?.category === category)
                                            ?.map((tool) => {
                                                return (
                                                    <Col span={12} md={6} key={tool?.id} >
                                                        <div className='pl-2 tool-item flex gap-0 md:gap-2 flex-row items-center justify-between '>
                                                            <div className='flex flex-row gap-1 md:gap-3 items-center md:w-4/5'>
                                                                <img src={tool?.attributes?.image?.data?.attributes?.url} alt={tool?.name} className='logo-image' />
                                                                <div className='toolName w-11/12'> {tool?.attributes?.name}</div>
                                                            </div>
                                                            <div className='md:w-1/5 text-end pl-1 md:pl-0'>
                                                                <Checkbox
                                                                    onChange={(e) => handleCheckboxChange(tool, e)}
                                                                    defaultChecked={tool?.is_selected}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                );
                                            })}

                                    </Row>
                                </div>
                            ))}
                            {toolsData && toolsData?.length === 0 && <SearchEmpty />}
                        </div>
                    </Col>
                </Row>

                <div className='fixed bottom-0 left-0 right-0 bg-white text-center pt-5 pb-5 onboard-shadow'>
                    <Form.Item className='text-center mb-0'>
                        <div className='flex gap-5 justify-center mb-4'>
                            <Button type="secondary" onClick={handleSkip}>
                                Skip
                            </Button>
                            <Button type="primary" htmlType="submit" disabled={selectedTools?.length < 1}>
                                Proceed
                            </Button>
                        </div>
                        <div className='flex flex-row gap-2 justify-center'>
                            Want to learn more?
                            <a href="http://joinpavilion.com/thank-you-executive" className="text-blue-600 font-semibold" target="_blank" rel="noopener noreferrer">
                                Schedule a time to talk to us
                            </a>
                        </div>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}