import { Table } from 'antd';
import React from 'react';
import SearchEmpty from './searchEmpty';

function TableAntd(props) {
    return (
        <>
            <Table
                dataSource={props.dataSource}
                locale={{ emptyText: <div className="m-auto"> <SearchEmpty filteredProduct={props.searchEmpty} /></div> }}
                columns={props.columns}
                {...props}
            />
        </>
    );
}

export default TableAntd;