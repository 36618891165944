import { Col, Dropdown, Row, Tag, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  BookmarkOutline,
  CalendarOutline,
  DotsVerticalOutline,
  EyeOutline,
  PencilAltOutline,
  ShareOutline,
  StarOutline,
  TrashOutline,
} from "heroicons-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context";
import { DummyCardImage, postFavourtie } from "../../assets/img";
import config from "../../confiq/confiq";
import axios from "axios";
import SignInPopup from "../Modal/signIn";
import ConfirmationModal from "../Modal/confirmationDelete";
import { useUserRole } from "../../context/userContext";
import moment from "moment";

export const ResourceCard = (props) => {
  const { fetchData, setLoading, modalDesc } = props;
  const { userToken } = useAuth();
  const userRole = useUserRole();
  const [strapiUrl, setStrapiUrl] = useState("");
  const [showSignInPopup, setShowSignInPopup] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState();
  const [stories, setStories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, []);

  useEffect(() => {
    setStories(props.product);
    const apiUrl = process.env.REACT_APP_STRAPI_URL;

    if (apiUrl) {
      setStrapiUrl(apiUrl);
    } else {
      console.error("REACT_APP_STRAPI_URL is not defined in the environment.");
    }
  }, [props.product]);

  const updateBookmarkStatus = (storyId, isBookmarked) => {
    setStories((prevStories) =>
      prevStories?.map((story) =>
        story?.id === storyId ? { ...story, isBookmarked } : story
      )
    );
  };

  const handleCancel = () => {
    setIsOpenConfirmationModal(false);
  };

  const handleOk = async (selectedProjectId) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}api/${props?.pageName}/remove/${selectedProjectId}`,
        {},
        {
          headers: config.headers,
        }
      );
      if (response?.data?.statusCode === 201) {
        message.success("Story deleted successfully.");
        fetchData();
        setLoading(true);
      } else {
        message.error(
          "Oops! Something went wrong. Story could not be deleted. Please try again."
        );
        setLoading(false);
      }
    } catch (error) {
      console.error("Error validating token:", error);
    }
  };

  const borderColors = ["#EA5A54", "#55AF68", "#F7CF66", "#5D5FF3", "#EA5A54",];

  return (
    <>
      <Row gutter={[16, 16]}>
        {stories &&
          stories?.map((story, index) => {
            const storyId = story?.id;
            const partner = story?.attributes?.partner?.data?.attributes;
            const borderColor = borderColors[index % borderColors.length];
            const handleEditClick = (event) => {
              window.open(
                `${strapiUrl}admin/content-manager/collectionType/api::blog.blog/${storyId}`
              );
            };

            const handleCardClick = () => {
              const path = `/resources/${props?.pageName}/${story?.id}/${story?.attributes?.slug}`;
              localStorage.setItem("selectedStoryId", story?.id);
              localStorage.setItem("selectedPageName", props?.pageName);
              navigate(path);

              if (userRole !== "Admin") {
                const payload = {
                  metric_id: story?.id,
                  metric_type: props?.pageName === "guides" ? "guide" : "story",
                  view_count: 1,
                  recent_views: 1,
                };

                axios
                  .post(`${config.apiUrl}api/metrics/save`, payload, {
                    headers: config.headers,
                  })
                  .catch((error) => {
                    console.error("Error validating token:", error);
                  });
              }
            };

            const handleDeleteClick = async () => {
              setIsOpenConfirmationModal(true);
              setSelectedProjectId(story?.id);
            };

            const postBookmark = async () => {
              if (!userRole || !userToken) {
                setShowSignInPopup(true);
              } else {
                const payload = {
                  bookmark_id: story?.id,
                  bookmark_type: props?.pageName === "guides" ? "guide" : "story",
                };

                try {
                  const response = await axios.post(
                    `${config.apiUrl}api/bookmark/save`,
                    payload,
                    {
                      headers: config.headers,
                    }
                  );
                  if (response?.data?.statusCode === 201) {
                    updateBookmarkStatus(storyId, true);
                  } else {
                    message.error(
                      "Sorry, we couldn't add this project to your favorites. Please try again later."
                    );
                    setLoading(false);
                  }
                } catch (error) {
                  console.error("Error validating token:", error);
                }
              }
            };

            const removeBookmark = async () => {
              if (!userRole || !userToken) {
                setShowSignInPopup(true);
              } else {
                const payload = {
                  bookmark_id: story?.id,
                  bookmark_type: props?.pageName === "guides" ? "guide" : "story",
                };
                try {
                  const response = await axios.post(
                    `${config.apiUrl}api/bookmark/remove`,
                    payload,
                    {
                      headers: config.headers,
                    }
                  );
                  if (response?.data?.statusCode === 201) {
                    updateBookmarkStatus(storyId, false);
                  } else {
                    message.error(
                      "Sorry, we couldn't remove this from your saved items. Please try again later."
                    );
                    setLoading(false);
                  }
                } catch (error) {
                  console.error("Error validating token:", error);
                }
              }
            };
            return (
              <Col span={24} sm={12} md={12} lg={12} xl={8} key={index}>
                <div
                  onClick={(event) => {
                    event?.preventDefault();
                    event?.stopPropagation();
                  }}
                >
                  <div
                    className="storiesCard bordercolor-black border rounded-md dark-gray cursor-pointer mb-6"
                    onClick={handleCardClick}
                    key={story?.id}
                  >
                    {props?.pageName === "guides" &&
                      <div className="p-3  flex items-center h-[70px]"
                        style={{ background: `${borderColor}` }}
                      >
                        <h4 className="text-lg w-3/4 md:text-xl font-semibold text-white capitalize content-threeline content-oneline ">
                          {story?.attributes?.title}
                        </h4>
                      </div>
                    }
                    <div
                      className="story-image"
                      style={{
                        backgroundImage: `url(${story?.attributes?.banner_Image?.data?.attributes?.url || DummyCardImage})`,
                      }}
                    >
                    </div>
                    <div className={`p-5 story-vertical-align-card min-h-fix-${props?.pageName}`}>
                      <div>
                        <div className="flex items-self-start md:items-center justify-between mb-2 ">
                          <div className="flex gap-1 items-center flex-wrap">
                            {story?.attributes?.tags?.data?.slice(0, 2).map((tag) => (
                              <Tag key={tag?.id} color="green">
                                {tag?.attributes?.name}
                              </Tag>
                            ))}

                            {story?.attributes?.tags?.data?.length > 2 && (
                              <span className="px-2 ant-tag-green ml-1">+{story?.attributes?.tags?.data?.length - 2}</span>
                            )}
                          </div>
                          {userToken &&
                            <div
                              onClick={(event) => {
                                event?.preventDefault();
                              }}
                              className="flex justify-end  w-10 position-story"
                            >
                              <div className="card-action-star">
                                {userRole && userRole === "Admin" ? (
                                  <div
                                    onClick={(event) => event?.stopPropagation()}
                                    className=" flex justify-end items-center"
                                  >
                                    <Dropdown
                                      menu={{
                                        items: [
                                          {
                                            key: "1",
                                            icon: (
                                              <PencilAltOutline color="#1F2937" size={16} />
                                            ),
                                            label: "Edit",
                                            onClick: handleEditClick,
                                          },
                                          {
                                            key: "2",
                                            icon: (
                                              <TrashOutline color="#1F2937" size={16} />
                                            ),
                                            label: "Delete",
                                            onClick: handleDeleteClick,
                                          },
                                        ],
                                      }}
                                      placement="bottomRight"
                                    >
                                      <div
                                        className="cursor-pointer"
                                        onClick={(event) => event?.stopPropagation()}
                                      >
                                        <span className="ant-dropdown-link">
                                          <DotsVerticalOutline
                                            onClick={(event) => event?.stopPropagation()}
                                          />
                                        </span>
                                      </div>
                                    </Dropdown>
                                  </div>
                                ) : (
                                  <div onClick={(event) => event?.stopPropagation()}>
                                    {story?.isBookmarked === false ? (
                                      <StarOutline onClick={postBookmark} />
                                    ) : (
                                      <img
                                        src={postFavourtie}
                                        alt="post fav"
                                        className="h-6"
                                        onClick={removeBookmark}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          }
                        </div>
                        {props?.pageName === "story" &&
                          <h4 className="text-lg md:text-xl font-semibold text-black  mb-2 capitalize content-threeline content-twoline">
                            {story?.attributes?.title}
                          </h4>
                        }
                        <div className="mb-4 content-threeline">
                          {story?.attributes?.summary}
                        </div>
                      </div>
                      <div>
                        {story?.attributes?.partner?.data !== null &&
                          <div className="flex flex-row gap-4 items-end md:items-center justify-between mb-3">
                            <div className="flex flex-row gap-8">
                              {story?.attributes?.partner?.data ? (
                                <div className="flex gap-2  items-center">
                                  <div className=" rounded-full partner-img">
                                    <img
                                      src={partner?.image?.data?.attributes?.url}
                                      alt="Partner"
                                    />
                                  </div>
                                  <div>
                                    <div className="midnight-blue">{partner.name}</div>
                                    <div className="steel-gray flex flex-row gap-2">
                                      <span>{partner.role}</span>•
                                      <span>{partner.company_Name}</span>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        }
                        <div className="flex justify-between gap-2 items-center block-small-webscreen">
                          <div className="flex flex-row gap-2 steel-gray items-center ">
                            <CalendarOutline size={18} />
                            Published {story?.attributes?.publishedAt && moment(story?.attributes?.publishedAt).fromNow()}
                          </div>
                          <div>
                            {userRole === "Admin" ? (
                              <div className="flex flex-row gap-5 text-sm items-center steel-gray">
                                <div className="flex flex-row items-center gap-1">
                                  <EyeOutline size={18} />
                                  {story?.view_count === null ? 0 : story?.view_count}
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <BookmarkOutline size={18} />
                                  {story?.fav_count === null ? 0 : story?.fav_count}
                                </div>
                                <div className="flex flex-row items-center gap-1">
                                  <ShareOutline size={18} />
                                  {story?.share_count === null ? 0 : story?.share_count}
                                </div>
                              </div>
                            ) :
                              <div className="flex flex-row gap-1 steel-gray">
                                {story?.attributes?.read_In_Minutes} min read
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div >
              </Col>
            );
          })}
      </Row>
      <SignInPopup
        visible={showSignInPopup}
        onClose={() => setShowSignInPopup(false)}
      />
      {
        isOpenConfirmationModal && (
          <ConfirmationModal
            handleOk={handleOk}
            handleCancel={handleCancel}
            selectedProjectId={selectedProjectId}
            open={isOpenConfirmationModal}
            modalDesc={modalDesc}
          />
        )
      }
    </>
  );
};