import { Button, Col, Input, Modal, Row } from 'antd';
import React from 'react';

function ModalExternalLink({
    modalVisible,
    handleModalClose,
    handleSaveLinks,
    links,
    handleLinkChange,
    handleAddLink
}) {
    return (
        <Modal
            title="Add External Links"
            visible={modalVisible}
            centered
            destroyOnClose
            onCancel={handleModalClose}
            footer={() => (
                <div className="flex justify-end">
                    <Button
                        key="Save"
                        type="primary"
                        className='ant-btn-sm-custom'
                        onClick={handleSaveLinks}
                    >
                        Save
                    </Button>
                </div>
            )}
        >
             {links && links?.map((link, index) => (
                <div key={index} className="mb-4">
                    <div className='text-black mb-2 font-semibold text-sm'>{`Link ${index + 1}`}</div>
                    <Row gutter={[8, 8]}>
                        <Col span={6}>
                            <div className='mb-1'>Label</div>
                            <Input
                                placeholder={`Label ${index + 1}`}
                                value={link.label === "null" ? "" : link.label}
                                onChange={(e) => handleLinkChange(index, 'label', e.target.value)}
                                className="mb-2"
                            />
                        </Col>
                        <Col span={18}>
                            <div className='mb-1'>Link</div>
                            <Input
                                placeholder="Paste link here"
                                value={link.link === "null" ? "" : link.link}
                                onChange={(e) => handleLinkChange(index, 'link', e.target.value)}
                            />
                        </Col>
                    </Row>
                </div>
            ))}
            {links && links?.length < 3 && (
                <div className="purple-saas22 mt-2">
                    <span
                        className="text-blue-500 cursor-pointer"
                        onClick={handleAddLink}
                    >
                        + Add new link
                    </span>
                </div>
            )}
        </Modal>
    );
}

export default ModalExternalLink;