/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  Upload,
  message,
  Spin,
} from "antd";
import axios from "axios";
import config from "../../../confiq/confiq";
import { getBase64 } from "../../../utils";
import { useCountryTimeZoneData } from "../../../CommonApiService/commonApi";
import { useAuth } from "../../../context";
import { UploadButton } from "../../../components/common";

const { Option } = Select;

export const OnboardStep1 = ({
  handleFinishStep1,
  setSelectedLocationName,
  setImageId,
}) => {
  const [loading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [userInfo, setUserInfo] = useState({});
  const { userToken } = useAuth();
  const { countryWithTimezone } = useCountryTimeZoneData(userToken);
  const [form] = Form.useForm();
  const [reloadWindow, setReloadWindow] = useState(
    localStorage.getItem("reloadWindow") === "true" ? true : false
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        do {
          response = await axios.get(`${config.apiUrl}api/user/info`, {
            headers: config.headers,
          });
          if (response.data.statusCode === 400 && !reloadWindow) {
            localStorage.setItem("reloadWindow", "true");
            setReloadWindow(true);
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
        } while (response?.data?.statusCode !== 201);

        const userData = response?.data?.data;
        setUserInfo(userData);
        if (response?.data?.statusCode === 201) {
          localStorage.removeItem("reloadWindow");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleChange = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
      const formData = new FormData();
      formData.append("file", info.file.originFileObj);
      formData.append("type", "profile");

      axios
        .post(`${config.apiUrl}api/upload`, formData, {
          headers: config.headers,
        })
        .then((response) => {
          if (response?.data?.statuscode === 201) {
            const newImageId = response?.data?.image_id;
            setImageId(newImageId);
          }
        })
        .catch((error) => {
          console.error("Error uploading file to API:", error);
        });
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const propsLogo = {
    name: "file",
    listType: "picture-card",
    className: "avatar-uploader",
    accept: 'image/*',
    showUploadList: false,
    action: `${config.apiUrl}api/upload`,
    headers: config.headers,
    method: "POST",
    onChange: handleChange,
  };

  const commonRule = [
    {
      required: true,
      message: "",
    },
  ];

  useEffect(() => {
    if (userInfo) {
      form.setFieldsValue({
        email: userInfo?.email,
        first_name: userInfo?.first_name || "",
        last_name: userInfo?.last_name || "",
        picture: userInfo?.image?.path || "",
        phone: userInfo?.phone || "",
        title: userInfo?.title || "",
        linkedin: userInfo?.social_links?.linkedin || "",
        hearAboutUs: userInfo?.reference || "",
        companyName: userInfo?.company || "",
        country: userInfo?.country_timezone || "",
        about_company: userInfo?.about_company || "",
      });
    }
  }, [userInfo, form]);

  return (
    <div className="md:mt-8">
      <h2 className=" text-lg md:text-3xl mb-8 font-bold text-center">
        Tell us a little bit about you
      </h2>
      <div className="onboard-formbody">
        <Form
          name="step1"
          onFinish={handleFinishStep1}
          layout="vertical"
          form={form}
        >
          {userInfo ? (
            <>
              <Row gutter={16}>
                <Col span={24} xs={24} sm={24}>
                  <Form.Item name="picture" className="text-center">
                    <div>
                      <Upload {...propsLogo} className="bd-radius">
                        {imageUrl ? (
                          <img
                            src={imageUrl}
                            alt="profile"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : userInfo?.image ? (
                          <img
                            src={userInfo?.image?.path}
                            alt="profile"
                            className="img-url"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : (
                          <UploadButton loading={loading} />
                        )}
                      </Upload>
                      <button
                        className="text-blue-600 font-semibold bg-transparent hover:bg-blue-50"
                        onClick={() =>
                          document.querySelector('input[type="file"]').click()
                        }
                      >
                        Upload Image
                      </button>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={12}>
                  <Form.Item
                    name="first_name"
                    label="First Name"
                    rules={[
                      ...commonRule,
                      { max: 30, message: 'Maximum 30 characters allowed' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={12}>
                  <Form.Item
                    name="last_name"
                    label="Last Name"
                    rules={[
                      ...commonRule,
                      { max: 30, message: 'Maximum 30 characters allowed' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={12}>
                  <Form.Item
                    name="companyName"
                    label="Company Name"
                    rules={[
                      ...commonRule,
                      { max: 100, message: 'Maximum 100 characters allowed' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={12}>
                  <Form.Item name="title" label="Title"
                    rules={[
                      ...commonRule,
                      { max: 50, message: 'Maximum 50 characters allowed' },
                    ]}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={12}>
                  <Form.Item name="email" label="Email" disabled>
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={12}>
                  <Form.Item
                    name="phone"
                    label="Phone Number"
                    rules={[
                      { required: true, message: "" },
                      { max: 15, message: 'Maximum 15 characters allowed' },
                      () => ({
                        validator(_, value) {
                          const phoneNumerRegex = /^[0-9]{8,15}$/;
                          if (value && !phoneNumerRegex.test(value)) {
                            return Promise.reject(
                              new Error("Please enter a valid Phone number")
                            );
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Enter the phone number" />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={12}>
                  <Form.Item
                    name="country"
                    label="Country / Timezone"
                    rules={commonRule}
                  >
                    <Select
                      showSearch
                      placeholder="Select Country / Timezone"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countryWithTimezone && countryWithTimezone?.map((location) => (
                        <Option key={location?.id} value={location['country&timezone']}>
                          {location['country&timezone']}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={12}>
                  <Form.Item
                    name="linkedin"
                    label="LinkedIn URL"
                    rules={[{ required: true, message: "" }]}
                  >
                    <Input placeholder="Enter the URL" />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={24}>
                  <Form.Item
                    name="about_company"
                    label="Tell us more about your company"
                    rules={[
                      { max: 100, message: 'Maximum 100 characters allowed' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24} xs={24} sm={24}>
                  <Form.Item
                    name="hearAboutUs"
                    label="How did you hear about us?"
                    rules={[
                      { max: 100, message: 'Maximum 100 characters allowed' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <div className="fixed bottom-0 left-0 right-0 bg-white text-center pt-5 pb-5 onboard-shadow">
                <Form.Item className="flex justify-center">
                  <Button type="primary" htmlType="submit">
                    Save & Continue
                  </Button>
                </Form.Item>
                <div className="flex flex-row gap-2 justify-center">
                  Want to learn more?
                  <a
                    href="http://joinpavilion.com/thank-you-executive"
                    className="text-blue-600 font-semibold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Schedule a time to talk to us
                  </a>
                </div>
              </div>
            </>
          ) : (
            <Spin className="loader" tip="Loading..." />
          )}
        </Form>
      </div>
    </div>
  );
};
