import React, { useEffect, useState } from "react";
import { Carousel, Spin, Tabs } from "antd";
import { HomeGif } from "../../assets/img";
import "./index.scss";
import { getCurrentTimeGreeting } from "../../utils";
import { useAuth } from "../../context";
import { useUserInfo } from "../../CommonApiService/commonApi";
import axios from "axios";
import config from "../../confiq/confiq";
import WhatsNew from "./whatsNew";
import HomeChart from "./chart";
import Myfav from "./myFav";
import { useUserRole } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import UpcomingEvents from "./upcomingEvents";
import Notfound from "../Notfound";

export default function Home() {
  const [banners, setBanners] = useState([]);
  const [eventData, setEventData] = useState([]);
  const greeting = getCurrentTimeGreeting();
  const { userToken } = useAuth();
  const { userInfo } = useUserInfo(userToken);
  const [apiParterMetrics, setParterMetrics] = useState(null);
  const savedActiveKey = localStorage.getItem("homeActiveTabKey");
  const [activeKey, setActiveKey] = useState(savedActiveKey || "1");
  const userRole = useUserRole();
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const userToken = urlParams.get('access_token');
        const userRole = urlParams.get('role_name');

        if (userToken && userRole) {
          localStorage.setItem('userToken', userToken);
          localStorage.setItem('userRole', userRole);
          login(userRole);

          switch (userRole) {
            case 'Partner':
            case 'Admin':
              navigate('/home');
              if (window.location.pathname === '/home') {
                window.location.reload();
              }
              break;
            default:
              break;
          }
        } else {
          console.error('Incomplete or missing query parameters');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [login, navigate]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}api/events/getAll?page=1&pageSize=2&startDate=null&endDate=null&price=null&brand=null&location=null&cat=null&search=null
        `,
        {
          headers: config.headers,
        }
      );
      setEventData(response?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching banners:", error);
    } finally {
    }
  };

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}api/home/banner`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const bannersData = response.data.data.data.map((item) => ({
        imageUrl: item?.attributes?.banner_Image?.data.attributes.url,
        redirectionUrl: item?.attributes?.redirection_URL,
      }));
      setBanners(bannersData);
    } catch (error) {
      console.error("Error fetching banners:", error);
    }
  };

  const partnerTicketMetrics = () => {
    axios.get(`${config.apiUrl}api/vendor/ticketMetrics`, {
      headers: config.headers,
    }).then((response) => {
      const { closed, open, pending, resolved, total } =
        response?.data?.data;
      const formattedData = [
        { name: "Total", value: total },
        { name: "Open", value: open },
        { name: "Pending", value: pending },
        { name: "Resolved", value: resolved },
        { name: "Closed", value: closed },
      ];
      setParterMetrics(formattedData);
    }).catch((error) => {
      console.error("Error fetching data:", error);
    });
  };

  useEffect(() => {
    // fetchProjectData();
    partnerTicketMetrics()
    fetchBanners();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const items = [
    {
      key: "1",
      label: <div className="text-base md:text-xl">What’s New? </div>,
      children: <WhatsNew />,
    },
    ...(userRole !== "Admin" && userToken
      ? [
        {
          key: "2",
          label: <div className="text-base md:text-xl">My Favourites </div>,
          children: <Myfav />,
        },
      ]
      : []),
  ];
  const handleTabChange = (key) => {
    localStorage.setItem("homeActiveTabKey", key);
    setActiveKey(key);
  };

  const handleBannerClick = (redirectionUrl) => {
    if (redirectionUrl) {
      const currentBaseUrl = window.location.origin;
      const redirectionBaseUrl = new URL(redirectionUrl).origin;
      if (currentBaseUrl === redirectionBaseUrl) {
        navigate(redirectionUrl.replace(currentBaseUrl, ""));
      } else {
        window.open(redirectionUrl, "_blank", "noopener,noreferrer");
      }
    }
  };
  const shouldRenderChart = apiParterMetrics?.some((item) => item?.value !== 0);

  return (
    <>
      {userInfo?.first_name ? (
        <>
          <div
            className={`block md:flex justify-between items-center ${banners && banners?.length > 0 && "mb-5"
              }`}
          >
            <div className="w-full lg:w-[820px]">
              {userInfo && (
                <div className={`text-xl md:text-2xl mb-4`}>
                  {greeting},{" "}
                  <span className="font-bold capitalize">{`${userInfo && userInfo?.first_name
                    }!`}</span>
                </div>
              )}
              {banners && (
                <Carousel autoplay effect="fade" className="banner-effect-img ">
                  {banners?.map((banner, index) => {
                    const bannerContent = (
                      <>
                        <div
                          className="banner-effect-img "
                          style={{
                            backgroundImage: `url(${banner.imageUrl})`,
                          }}
                        ></div>
                        <div className="feature-sec">
                          <div className="feature-text text-xs md:text-xl font-semibold">
                            Featured
                          </div>
                        </div>
                      </>
                    );

                    return (
                      <div
                        key={index}
                        className="banner-effect"
                        onClick={() => handleBannerClick(banner.redirectionUrl)}
                      >
                        {banner.redirectionUrl ? (
                          <div className="flex w-full cursor-pointer">
                            {bannerContent}
                          </div>
                        ) : (
                          bannerContent
                        )}
                      </div>
                    );
                  })}
                </Carousel>
              )}
            </div>
            {banners && banners?.length > 0 && (
              <div className="hidden  lg:w-1/4 md:flex justify-center parent-home-gif-img">
                <img src={HomeGif} alt="home gif" className="home-gif-img" />
              </div>
            )}
          </div>
          <div className="flex flex-col lg:flex-row gap-4 w-full">
            {shouldRenderChart && userRole === "Partner" && (
              <HomeChart
                chartData={apiParterMetrics}
                eventData={eventData}
                chart={'Requests'}
              />
            )}

            {eventData.length > 0 && (
              <UpcomingEvents
                eventData={eventData}
                className={
                  (shouldRenderChart && userRole === "Partner") ? " lg:w-96	md:w-full" : "w-full"
                }
              />
            )}
          </div>
          <div>
            <Tabs
              activeKey={activeKey}
              defaultActiveKey="1"
              items={items}
              className="primary-tab homeTab"
              onChange={handleTabChange}
            />
          </div>
        </>
      ) : (
        <Notfound />
      )}
    </>
  );
}
