import React from 'react';
import { NoInternetImage } from '../assets/img';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context';
import { useUserRole } from '../context/userContext';

function NoInternet(props) {
    const navigate = useNavigate();
    const { userToken } = useAuth();
    const userRole = useUserRole();

    const handleRetry = () => {
        if (userRole && userToken) {
            navigate('/home');
        } else {
            navigate('/resources');
        }
        window.location.reload();
    };

    return (
        <div className='not-found-page flex items-center justify-center flex-col text-center gap-4'>
            <img src={NoInternetImage} alt='not found' className='mb-2' />
            <div className='font-semibold'>No internet connection</div>
            <div className='mb-2'>
                Please check your internet connection and try again
            </div>
            <Button type='primary' onClick={handleRetry}>
                Retry
            </Button>
        </div >
    );
}

export default NoInternet;