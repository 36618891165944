import React, { useState } from 'react';
import {
    Button,
    Col,
    Form,
    Input,
    Row,
} from 'antd';
import {
    PasswordReset,
} from '../../assets/img';
import {
    useLocation,
    useNavigate
} from 'react-router-dom';
import axios from 'axios';
import config from '../../confiq/confiq';
import { ErrorMessage } from './signError';
import { AuthLogo } from './signIn';

function PasswordRecovery() {
    const [emailError, setEmailError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const emailFromState = location?.state?.email;

    const onFinish = async (values) => {
        const payload = {
            email: values?.email,
        };

        try {
            const response = await axios.post(`${config.apiUrl}api/user/password/reset/email`, payload);


            if (response?.data?.statusCode === 201) {
                localStorage.setItem('verificationEmail', values?.email);
                const path = `/emailVerification`;
                const state = {
                    email: values?.email,
                    reset: "resetpassword"
                };
                navigate(path, { state });
            } else if (response?.data?.statusCode === 401) {
                setEmailError('Email already exist')
            } else if (response?.data?.statusCode === 400) {
                setEmailError(`This email address is already associated with an account. Please Sign in
                using your email and password, or Google account.`)
            } else {
                // console.log('error');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='container mx-auto sign-page'>
            <AuthLogo />
            <Row gutter={16}>
                <Col xs={24} sm={12} className='flex items-center text-center justify-center'>
                    <div>
                        <ErrorMessage error={emailError} />
                        <div className="sign-card">
                            <div className='mb-8'>
                                <h2 className="text-3xl font-bold mb-1 text-black">Password Recovery</h2>
                                <div className='text-[#6B7280]'>Provide your email for password reset instructions</div>
                            </div>
                            <Form
                                name="signinForm"
                                onFinish={onFinish}
                                layout="vertical"
                                initialValues={{ email: emailFromState }}
                            >
                                <Form.Item
                                    label="Business Email"
                                    name="email"
                                >
                                    <Input placeholder="Enter your business email" disabled />
                                </Form.Item>
                                <Form.Item className='mt-10'>
                                    <Button htmlType="submit" className='purple-btn' block>
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} className='hidden md:flex items-center text-center justify-center'>
                    <img src={PasswordReset} alt='signInIllustration' className='h-3/5' />
                </Col>
            </Row>
        </div >
    );
}

export default PasswordRecovery;