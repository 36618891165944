import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, Row } from "antd";
import { GIcon, signUpIllustration } from "../../assets/img";

import { Link, useNavigate, useLocation } from "react-router-dom";
import config from "../../confiq/confiq";
import axios from "axios";
import { ErrorMessage } from "./signError";
import { AuthLogo } from "./signIn";

function SignUp() {
  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const rn = queryParams.get("rn");

      if (!rn) {
        console.error("Email not found in URL");
        return;
      }

      try {
        const response = await axios.post(
          `${config.apiUrl}api/user/confirm`,
          { rn: rn },
          {
            headers: config.headers,
          }
        );

        if (response?.data?.statusCode === 400) {
          navigate("/linkExpired");
        }
      } catch (error) {
        console.error("Error confirming email:", error);
      }
    };

    fetchData();
  }, [location, navigate]);



  const onFinish = async (values) => {
    const payload = {
      email: values?.email,
    };

    try {
      const response = await axios.post(
        `${config.apiUrl}api/user/signupValidation`,
        payload
      );

      if (response?.data?.statusCode === 201) {
        localStorage.setItem("verificationEmail", values?.email);
        const path = `/setPassword`;
        const state = {
          email: values?.email,
        };

        navigate(path, { state });
      } else if (response?.data?.statusCode === 400) {
        setEmailError(response?.data?.message);
      } else {
        // console.log("error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto sign-page">
      <AuthLogo />
      <Row gutter={16}>
        <Col
          xs={24}
          sm={12}
          className="flex items-center text-center justify-center"
        >
          <div>
            <ErrorMessage error={emailError} />
            <div className="sign-card">
              <div className="mb-8">
                <h2 className="text-3xl font-bold mb-3 text-black">
                  Create your account
                </h2>
                <div className="">
                  Already have an account?{" "}
                  <Link to={"/signin"} className="text-blue-600 font-semibold">
                    Sign In
                  </Link>
                </div>
              </div>

              <div>
                <a
                  href={`${config.apiUrl}api/auth/google`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    block
                    className="flex items-center justify-center google-btn"
                  >
                    <img src={GIcon} alt="google-icon" className="g-icon" />{" "}
                    Google
                  </Button>
                </a>
              </div>
              <Divider>Or continue with</Divider>
              <Form name="signinForm" onFinish={onFinish} layout="vertical">
                <Form.Item
                  label="Business Email"
                  name="email"
                  className="mb-10"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your business email!",
                    },
                    {
                      type: "email",
                      message: "Please enter a valid email address!",
                    },
                    // {
                    //   validator: async (_, value) => {
                    //     const disallowedDomains = [
                    //       "gmail.com",
                    //       "email.com",
                    //       "yahoo.com",
                    //       "outlook.com",
                    //     ];
                    //     const domain = value?.split("@")[1];

                    //     if (disallowedDomains.includes(domain)) {
                    //       throw new Error(
                    //         "Email must belong to your business domain."
                    //       );
                    //     }
                    //   },
                    // },
                  ]}
                  hasFeedback
                >
                  <Input placeholder="Enter your business email" />
                </Form.Item>

                <Form.Item className="mt-4">
                  <Button htmlType="submit" className="purple-btn" block>
                    Create an Account
                  </Button>
                </Form.Item>
                <div className="mt-2">
                  By signing up, you agree to our{" "}
                  <a
                    href="https://www.saas22.com/terms-and-conditions"
                    className="text-blue-600 font-semibold"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms & conditions
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={12}
          className="hidden md:flex items-center text-center justify-center"
        >
          <img src={signUpIllustration} alt="signInIllustration" className="h-3/5" />
        </Col>
      </Row>
    </div>
  );
}

export default SignUp;
