import { Button, Checkbox, Col, Form, Row, Spin, message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../../confiq/confiq';
import { Link, useNavigate } from 'react-router-dom';
import SearchInput from '../../components/SearchInput';
import { ChevronLeftOutline } from 'heroicons-react';
import SearchEmpty from '../../components/searchEmpty';


function AddTools(props) {
    const [selectedTools, setSelectedTools] = useState([]);
    const [uncheckedTools, setUncheckedTools] = useState([]);
    const [toolsData, setToolsData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const fetchData = () => {
        setLoading(true);
        axios.get(`${config.apiUrl}api/tools/getAll?page=1&pageSize=50&search=${searchQuery}`, {
            headers: config.headers,
        }).then(response => {
            const tools = response?.data?.data;
            setToolsData(tools);
        }).catch(error => {
            console.error('Error fetching data:', error);
        }).finally(() => {
            setLoading(false);
        });
    }


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery])

    const handleCheckboxChange = (tool, e) => {
        const toolId = tool.id;

        if (e.target.checked) {
            setSelectedTools(prevSelectedTools => [...prevSelectedTools, toolId]);
            setUncheckedTools(prevUncheckedTools => prevUncheckedTools.filter(id => id !== toolId));

        } else {
            setSelectedTools(prevSelectedTools => prevSelectedTools.filter(id => id !== toolId));
            setUncheckedTools(prevUncheckedTools => [...prevUncheckedTools, toolId]);
        }

        form.setFieldsValue({ selectedTools });
    };

    const newlySelectedTools = toolsData
        .filter(tool => tool.is_selected)
        .map(tool => tool.id);

    const allSelectedTools = [...selectedTools, ...newlySelectedTools];
    const filteredSelectedTools = allSelectedTools.filter(toolId => !uncheckedTools.includes(toolId));

    const handleFinishStep2 = (values) => {
        setLoading(true);
        if (selectedTools.length === 0 && filteredSelectedTools.length === 0) {
            navigate('/profile')
        }
        const payload = {
            tool_id: filteredSelectedTools,
        };

        axios.post(`${config.apiUrl}api/tools/save`, payload, {
            headers: config.headers,
        })
            .then(response => {
                if (response?.data?.statusCode === 201) {
                    fetchData();
                    setSelectedTools([]);
                    navigate('/profile')
                    message.success("Tools updated successfully");
                } else {
                    console.error('error');
                }
            })
            .catch(error => {
                console.error('Error submitting form:', error);
            });
    };

    const toolDataMap = [...new Set(toolsData?.map(item => item?.attributes?.category))];

    return (
        <div>
            <div className="flex flex-row gap-2 items-center mb-5">
                <Link to="/profile" className="text-black">
                    <ChevronLeftOutline />
                </Link>
                <h3 className="text-lg text-black font-semibold capitalize flex gap-2 items-center">
                    Tools
                </h3>
            </div>
            <Form name="step2"
                onFinish={handleFinishStep2}
                layout='vertical'
                form={form}
                className='pb-24'
            >
                <Row gutter={16} className='mb-4 items-center'>
                    <Col span={14} md={20}>
                        <SearchInput
                            placeholder="Search by tools"
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </Col>
                    <Col span={10} md={4} className='text-end'>
                        <Button type='primary' htmlType="submit"
                            disabled={filteredSelectedTools.length === allSelectedTools.length && selectedTools.length === 0}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
                <Row gutter={16} >
                    <Col span={24}>
                        {loading ? (
                            <Spin className='loader' tip='Loading...' />
                        ) : (
                            <div className='flex flex-col gap-4'>
                                {toolDataMap.length === 0 ? (
                                    <SearchEmpty />
                                ) : (
                                    toolDataMap.map((category, index) => (
                                        <div key={index} className='tools-section mb-4'>
                                            <h6 className='font-semibold text-base md:text-lg text-black mb-2'>{category}</h6>
                                            <Row gutter={[16, 16]} >
                                                {toolsData?.filter(item => item?.attributes?.category === category)
                                                    .map((tool) => {
                                                        return (
                                                            <Col span={12} md={6} key={tool.id} >
                                                                <div className='pl-2 tool-item flex gap-0 md:gap-2 flex-row items-center justify-between '>
                                                                    <div className='flex flex-row gap-3 items-center w-4/5'>
                                                                        <img src={tool?.attributes?.image?.data?.attributes?.url} alt={tool.name} className='logo-image' />
                                                                        <div className='toolName w-11/12'> {tool?.attributes?.name}</div>
                                                                    </div>
                                                                    <div className='mdw-1/5 text-end'>
                                                                        <Checkbox
                                                                            onChange={(e) => handleCheckboxChange(tool, e)}
                                                                            defaultChecked={tool.is_selected}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        );
                                                    })}
                                            </Row>
                                        </div>
                                    ))
                                )}
                            </div>
                        )}
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AddTools;