import { DatePicker, Spin, Select, Drawer, Button } from "antd";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import SearchInput from "../../../components/SearchInput";
import axios from "axios";
import config from "../../../confiq/confiq";
import SearchEmpty from "../../../components/searchEmpty";
import { WebfeedCard } from "../../../components/Card/webfeed";
import {
  functionOptions,
  guideCategoryOptions,
} from "../../../components/filter";
import Pagination from "../../../components/paginationSet";
import { dateFormat } from "../../../utils";
import FilterIcon, { ShowingText } from "../../../components/filterIcon";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const AllWebfeed = forwardRef((props, ref) => {
  const [selectedFunction, setSelectedFunction] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [product, setProduct] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [functionFilter, setFunctionFilter] = useState(null);
  const [industryFilter, setIndustryFilter] = useState(null);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredProduct, setFilteredProduct] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [open, setOpen] = useState(false);
  const [eventBrandFilter, setEventBrandFilter] = useState(null);
  const [selectedBrandType, setSelectedBrandType] = useState(null);
  const [brands, setBrands] = useState([]);

  const PAGE_SIZE = 10;

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const startDate = selectedDateRange[0]?.toISOString() || null;
      const endDate = selectedDateRange[1]?.toISOString() || null;

      const response = await axios.get(
        `${config.apiUrl}api/webFeed/getAll?page=${currentPage}&pageSize=${PAGE_SIZE}&startDate=${startDate}&endDate=${endDate}&fun=${functionFilter}&cat=${categoryFilter}&search=${searchQuery}&brand=${eventBrandFilter}`,
        {
          headers: config.headers,
        }
      );

      setTotalPages(
        Math.ceil(response?.data?.data?.meta?.pagination?.total / PAGE_SIZE)
      );
      setProduct(response?.data?.data?.data);
      setFilteredProduct(response?.data?.data?.meta?.pagination?.total);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [
    currentPage,
    PAGE_SIZE,
    functionFilter,
    categoryFilter,
    searchQuery,
    selectedDateRange,
    eventBrandFilter
  ]);

  useImperativeHandle(
    ref,
    () => ({
      fetchData: fetchData,
    }),
    [fetchData]
  );

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    PAGE_SIZE,
    industryFilter,
    functionFilter,
    categoryFilter,
    searchQuery,
    fetchData,
  ]);

  const resetFilters = () => {
    setSelectedFunction(null);
    setSelectedIndustry(null);
    setSelectedCategory(null);
    setFunctionFilter(null);
    setIndustryFilter(null);
    setCategoryFilter(null);
    setSelectedDateRange([]);
    fetchData();
    setSelectedBrandType(null);
    setEventBrandFilter(null);
  };

  const handleDateRangeChange = (dates) => {
    const newDates = Array.isArray(dates) ? dates : [];
    setSelectedDateRange(newDates);
  };

  const handleFilterChange = (value, setFilter, setSelectedFilter) => {
    setFilter(value);
    setSelectedFilter(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePaginationClick = (type) => {
    setCurrentPage((prevPage) =>
      type === "prev" ? prevPage - 1 : prevPage + 1
    );
  };

  const fetchBrands = useCallback(async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}api/brand/getAll?page=1&pageSize=10&search=null`,
        { headers: config.headers }
      );
      setBrands(response?.data?.data?.data);
    } catch (error) {
      console.error("Error fetching brands:", error);
    }
  }, []);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  return (
    <div className="mt-2">
      <div className="flex justify-between gap-2">
        <SearchInput
          placeholder="Try searching here"
          {...props}
          onChange={handleSearchChange}
          className="mb-4"
        />
        <FilterIcon
          condition={(selectedFunction ||
            selectedIndustry ||
            selectedCategory ||
            selectedBrandType ||
            selectedDateRange.length > 0)}
          onClick={() => setOpen(true)}
        />
      </div>
      <div className='items-center justify-between mb-4 flex'>
        <ShowingText
          product={product}
          filteredProduct={filteredProduct}
          name={'feeds'}
        />
        <div className="hidden md:flex">
          <WebfeedFilter
            selectedFunction={selectedFunction}
            selectedIndustry={selectedIndustry}
            selectedCategory={selectedCategory}
            selectedDateRange={selectedDateRange}
            setSelectedFunction={setSelectedFunction}
            setSelectedCategory={setSelectedCategory}
            handleFilterChange={handleFilterChange}
            handleDateRangeChange={handleDateRangeChange}
            resetFilters={resetFilters}
            setFunctionFilter={setFunctionFilter}
            setCategoryFilter={setCategoryFilter}
            selectedBrandType={selectedBrandType}
            setEventBrandFilter={setEventBrandFilter}
            setSelectedBrandType={setSelectedBrandType}
            brands={brands}
          />
        </div>
      </div>
      {loading ? (
        <Spin className="loader" tip="Loading..." />
      ) : product && product.length > 0 ? (
        <WebfeedCard
          product={product}
          fetchData={fetchData}
          setLoading={setLoading}
        />
      ) : (
        <SearchEmpty filteredProduct={filteredProduct} />
      )}

      {product && product.length > 0 ? (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handlePaginationClick={handlePaginationClick}
          product={product}
          filteredProduct={filteredProduct}
          showingName={"feeds"}
        />
      ) : (
        ""
      )}
      <Drawer
        title={"Filters"}
        placement={"bottom"}
        className="drawer-filter"
        height={450}
        open={open}
        onClose={() => setOpen(false)}
        footer={
          <div className="flex md:hidden w-full">
            <Button
              type="secondary"
              className="w-full"
              onClick={() => {
                resetFilters();
                setOpen(false);
              }}
              disabled={!(selectedFunction ||
                selectedIndustry ||
                selectedCategory ||
                selectedBrandType ||
                selectedDateRange.length > 0)}
            >
              Clear Filters
            </Button>
          </div>
        }
      >
        <WebfeedFilter
          selectedFunction={selectedFunction}
          selectedIndustry={selectedIndustry}
          selectedCategory={selectedCategory}
          selectedDateRange={selectedDateRange}
          setSelectedFunction={setSelectedFunction}
          setSelectedCategory={setSelectedCategory}
          handleFilterChange={handleFilterChange}
          handleDateRangeChange={handleDateRangeChange}
          resetFilters={resetFilters}
          setFunctionFilter={setFunctionFilter}
          setCategoryFilter={setCategoryFilter}
          selectedBrandType={selectedBrandType}
          setEventBrandFilter={setEventBrandFilter}
          setSelectedBrandType={setSelectedBrandType}
          brands={brands}
        />
      </Drawer>
    </div>

  );
});

const WebfeedFilter = ({
  selectedFunction,
  selectedIndustry,
  selectedCategory,
  selectedDateRange,
  setSelectedFunction,
  setSelectedCategory,
  handleFilterChange,
  handleDateRangeChange,
  resetFilters,
  setFunctionFilter,
  setCategoryFilter,
  selectedBrandType,
  setEventBrandFilter,
  setSelectedBrandType,
  brands
}) => {
  return (
    <div className="grid md:flex flex-row items-center gap-2 responsive-filter w-full event-page">
      <div className="hidden md:flex">
        {(selectedFunction ||
          selectedIndustry ||
          selectedCategory ||
          selectedBrandType ||
          selectedDateRange.length > 0) && (
            <div
              className="cursor-pointer purple-saas22"
              onClick={resetFilters}
            >
              Clear Filters
            </div>
          )}
      </div>
      <RangePicker
        value={selectedDateRange}
        format={dateFormat}
        className="range-picker"
        onChange={handleDateRangeChange}
        allowClear={selectedDateRange.length > 0}
      />

      <Select
        value={selectedFunction}
        onChange={(value) =>
          handleFilterChange(value, setFunctionFilter, setSelectedFunction)
        }
        className="custom-select"
        style={{ width: 150 }}
        placeholder={"Function"}
      >
        <Option value="" disabled>
          Function
        </Option>
        {functionOptions?.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>

      <Select
        value={selectedCategory}
        onChange={(value) =>
          handleFilterChange(value, setCategoryFilter, setSelectedCategory)
        }
        style={{ width: 150 }}
        placeholder={"Category"}
        className="custom-select"
      >
        <Option value="" disabled>
          Category
        </Option>
        {guideCategoryOptions?.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>
      <Select
        value={selectedBrandType}
        onChange={(value) =>
          handleFilterChange(value, setEventBrandFilter, setSelectedBrandType)
        }
        placeholder={"Company"}
        className="custom-select"
        style={{ width: 120 }}
      >
        <Option value="" disabled>
          Company
        </Option>
        {brands &&
          brands?.map((item) => (
            <Option
              key={item?.id}
              value={item?.attributes?.vendor_Name}
              className="capitalize"
            >
              {item?.attributes?.vendor_Name}
            </Option>
          ))}
      </Select>
    </div>
  );
};
