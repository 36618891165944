import React, { useEffect, useState } from 'react'
import Savedempty from '../assets/img/empty.svg';
import { Spin } from 'antd';

function SavedEmpty() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ?
        <div className='flex items-center justify-center h-[200px]'>
          <Spin className='loader' tip='Loading...' />
        </div>
        :
        <div className='text-center justify-center mt-10'>
          <div className='text-center flex justify-center mb-2'>
            <img src={Savedempty} alt='' className='h-[200px] w-auto' />
          </div>
          <div className='midnight-blue font-semibold mb-2'>No content found</div>
        </div>
      }
    </>
  )
}

export default SavedEmpty