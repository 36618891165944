import React from "react";
import { SignInlinkexpired } from "../../assets/img";
import { AuthLogo } from "./signIn";

function LinkExpired() {
  return (
    <div className="text-center mx-auto justify-center grid linkexpired flex-col">
      <AuthLogo />
      <div className=" flex items-center">
        <div className="grid gap-4">
          <div className="mx-auto text-center flex justify-center">
            <img src={SignInlinkexpired} alt="emailVerificationIllustration" />
          </div>
          <div className="text-black font-semibold">
            Your invitation has expired!
          </div>
          <div>
            <div className="text-secondary">
              Oops! Your invitation link is no longer active.
            </div>
            <div className="text-secondary">
              {" "}
              Reach out to the person who initially invited you to obtain a new
              link.
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white fixed bottom-5">
        Resend invitation link?{" "}
        <a href="https://www.saas22.com/contact-us" target="_blank" rel="noreferrer">
          <span className="text-blue-600 font-semibold">Contact us</span>
        </a>
      </div>
    </div>
  );
}

export default LinkExpired;
