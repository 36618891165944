import { Button, Skeleton } from "antd";
import React from "react";

const Pagination = ({
  currentPage,
  totalPages,
  handlePaginationClick,
  product,
  filteredProduct,
  showingName,
}) => {
  return (
    <div className="flex justify-between mt-4 gap-4 items-center">
      {product && product.length > 0 ? (
        <div>
          {product?.length > 3 && (
            <>
              Showing {product ? product.length : 0} of{" "}
              {filteredProduct ? filteredProduct : 0} {showingName}
            </>
          )}
        </div>
      ) : (
        <Skeleton.Input style={{ width: 200 }} active={true} size="default" />
      )}

      <div className="flex gap-4">
        <Button
          onClick={() => handlePaginationClick("prev")}
          disabled={currentPage <= 1}
          className="btn-default"
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePaginationClick("next")}
          disabled={currentPage >= totalPages}
          className="btn-default"
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
