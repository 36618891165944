import React, {
    useEffect,
    useMemo,
    useState
} from 'react';
import { Tabs } from 'antd';
import EndingSoon from './endingSoon';
import Free from './free';
import Hot from './hot';
import { ChevronLeftOutline } from 'heroicons-react';
import {
    Link,
    useLocation,
    useNavigate
} from 'react-router-dom';

export default function ViewAllDeals() {
    const [activeKey, setActiveKey] = useState(() => {
        const savedActiveKey = localStorage.getItem('dealsActiveTabKey');
        return savedActiveKey || '1';
    });
    const navigate = useNavigate();
    const location = useLocation();

    const items = useMemo(() => [
        {
            key: '1',
            label: 'What’s hot ',
            children: <Hot />,
        },
        {
            key: '2',
            label: 'Ending soon',
            children: <EndingSoon />,
        },
        {
            key: '3',
            label: 'Freebies',
            children: <Free />,
        },
    ], []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tabKey = searchParams.get('tab');
        if (tabKey && items.some(item => item.key === tabKey)) {
            setActiveKey(tabKey);
            localStorage.setItem('dealsActiveTabKey', tabKey);
        }
    }, [location.search, items]);

    const handleTabChange = (key) => {
        localStorage.setItem('dealsActiveTabKey', key);
        setActiveKey(key);
        let route;
        switch (key) {
            case '1':
            case '2':
            case '3':
                route = '/discount/viewAll';
                break;
            default:
                route = '/discount/viewAll';
        }

        navigate(route);
        localStorage.setItem('dealsActiveTabKey', key);
    };

    const OperationsSlot = {
        left:
            <div className="back-icon-align mr-3">
                <Link to={"/discount"} className="text-black">
                    <ChevronLeftOutline />
                </Link>
            </div>
    };

    return (
        <div>
            <Tabs
                activeKey={activeKey}
                tabBarExtraContent={OperationsSlot}
                defaultActiveKey="1"
                items={items}
                className="primary-tab child-tab deals-tab"
                onChange={handleTabChange}
            />
        </div>
    );
}