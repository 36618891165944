import React, { useState } from 'react';
import {
    Button,
    Form,
    Input,
    Upload,
    message
} from 'antd';
import { PaperClipOutline } from 'heroicons-react';
import { CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import config from '../../confiq/confiq';
import moment from 'moment';
import { ArrowBendUpLeft } from '@phosphor-icons/react';
import { useUserRole } from '../../context/userContext';

const { TextArea } = Input;

function Reply({
    requestData,
    id,
    setLoading,
    conversation,
    fetchConversation,
    form,
    fileList,
    setFileList,
    textAreaValue,
    setTextAreaValue,
    replyEnabled,
    setReplyEnabled,
    handleReplyClick
}) {
    const [submitting, setSubmitting] = useState(false);
    const userRole = useUserRole();

    const onFinish = async (values) => {
        setSubmitting(true);
        const formData = new FormData();
        formData.append('ticket_id', id);
        formData.append('body', values.body);

        if (fileList.length > 0) {
            fileList.forEach((file) => {
                formData.append('attachments[]', file.originFileObj);
            });
        }
        try {
            const response = await axios.post(`${config.apiUrl}api/${userRole === "Buyer" ? "user" : "vendor"}/reply`, formData, {
                headers: config.headers,
            });
            if (response?.data?.statusCode === 201) {
                message.success('Replied successfully');
                form.resetFields();
                setFileList([]);
                setTextAreaValue('');
                setReplyEnabled(true);
                fetchConversation(id);
            }
        } catch (error) {
            console.error("Error creating note:", error);
            setLoading(false);
        } finally {
            setSubmitting(false);
        }
    };

    const handleFileChange = ({ fileList }) => {
        const filteredList = fileList.filter(file => {
            const isImageOrPdf = file.type.startsWith('image/') || file.type === 'application/pdf';
            if (!isImageOrPdf) {
                message.error(`${file.name} is not a valid file type (only images and PDFs are allowed).`);
            }
            return isImageOrPdf;
        });
        setFileList(filteredList);
    };

    const handleRemoveFile = (file) => {
        setFileList(fileList.filter(f => f.uid !== file.uid));
    };

    const handleTextAreaChange = (e) => {
        setTextAreaValue(e.target.value);
    };



    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
            .then((values) => {
                onFinish(values);
            })
            .catch(() => {
                // console.log(errorInfo);
            });
    };
    function getBackgroundColor(userRole) {
        switch (userRole) {
            case "Buyer":
                return "#5D5FF3";
            case "Partner":
                return "#55AF68";
            case "Admin":
                return "#EA5A54";
            case "Agent":
                return "#55AF68";
            default:
                return "#5D5FF3";
        }
    }

    function getBackgroundColorParent(userRole) {
        switch (userRole) {
            case "Buyer":
                return "rgba(241, 241, 241, 0.4)";
            case "Partner":
                return "rgba(247, 247, 254, 1)";
            case "Admin":
                return "rgba(255, 247, 247, 1)";
            case "Agent":
                return "rgba(247, 247, 254, 1)";
            default:
                return "rgba(241, 241, 241, 0.4)";
        }
    }
    return (
        <div className=' reply'>
            <div className="flex flex-row gap-2 mb-4 padding-radius" style={{ backgroundColor: getBackgroundColorParent(requestData?.requester_role) }}>
                <div className="w-[25px]">
                    <div className="request-img-h">
                        {requestData?.requester_avatar ? (
                            <img src={requestData.requester_avatar} alt="img" />
                        ) : (
                            <div
                                className="flex items-center justify-center rounded-full w-full h-full"
                                style={{ backgroundColor: getBackgroundColor(requestData?.requester_role) }}
                            >
                                <span className="text-white">
                                    {userRole === "Buyer" ? requestData?.buyer_name?.charAt(0).toUpperCase() : requestData?.requester_name?.charAt(0).toUpperCase()}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    <div className="block md:flex flex-row  gap-3 mb-2">
                        <div className="midnight-blue font-semibold capitalize content-threeline content-oneline max-w-[200px]">
                            {userRole === "Buyer" ? requestData?.buyer_name : requestData?.requester_name}
                        </div>
                        <div className="text-[#6B7280]">
                            created a request • {moment(requestData?.created_at).format("DD-MMM-YYYY h:mm A")}
                        </div>
                    </div>
                    <div>
                        {requestData?.additional_details}
                    </div>
                </div>
            </div>
            {conversation && conversation?.map((requestData, index) => {
                const backgroundColor = getBackgroundColor(requestData?.user_role);
                return (
                    <div className="flex flex-row gap-2 mb-4 padding-radius" style={{ backgroundColor: getBackgroundColorParent(requestData?.user_role) }}>
                        <div className="w-[25px]">
                            <div className="request-img-h">
                                {requestData?.user_avatar ? (
                                    <img src={requestData?.user_avatar} alt="img" />
                                ) : (
                                    <div
                                        className="flex items-center justify-center rounded-full w-full h-full"
                                        style={{ backgroundColor }}
                                    >
                                        <span className="text-white ">
                                            {requestData?.user_name?.charAt(0).toUpperCase()}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className="block md:flex flex-row  gap-3 mb-2">
                                <div className="midnight-blue font-semibold capitalize content-threeline content-oneline max-w-[200px]">
                                    {requestData?.user_name}
                                </div>
                                <div className="text-[#6B7280]">
                                    replied •  {moment(requestData?.created_at).format("DD-MMM-YYYY h:mm A")}
                                </div>
                            </div>
                            <div >
                                {requestData?.body}
                            </div>
                            <div >
                                <div className="font-semibold text-[#333333]">
                                    {requestData?.attachments &&
                                        requestData?.attachments?.map((attach) => (
                                            <div key={attach?.attachment_id} className="uploaded-files w-fit">
                                                <div className="uploaded-file uploaded-file-style flex justify-between items-center mb-2">
                                                    <div className="flex flex-row gap-2 items-center">
                                                        <PaperClipOutline color="#7B7B7B" height={15} />
                                                        <a
                                                            href={attach?.attachment_url}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            {attach?.name || "Attachment"}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })}
            {replyEnabled ? (
                <div
                    className="flex flex-row gap-2 cursor-pointer items-center text-black font-semibold "
                    onClick={handleReplyClick}
                >
                    <span className="w-[40px]"></span><ArrowBendUpLeft height={20} />  {textAreaValue || fileList?.length > 0 ? "Draft" : "Reply"}
                </div>
            ) : (
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    className='mt-4 pr-2'
                >
                    <div className="flex flex-row gap-2 mb-4">
                        <div className="w-[25px]">
                            <div className="request-img-h">
                                {(userRole === "Partner" && requestData?.responder_avatar) || (userRole === "Buyer" && requestData?.requester_avatar) ? (
                                    <img
                                        src={userRole === "Buyer"
                                            ? requestData?.requester_avatar
                                            : userRole === "Partner"
                                                ? requestData?.responder_avatar
                                                : ""}
                                        alt=""
                                    />
                                ) : (
                                    <div
                                        className="flex items-center justify-center rounded-full w-full h-full"
                                        style={{ backgroundColor: getBackgroundColor(requestData?.responder_role) }}
                                    >
                                        <span className="text-white">
                                            {userRole === "Buyer"
                                                ? requestData?.buyer_name?.charAt(0).toUpperCase()
                                                : requestData?.responder_name?.charAt(0).toUpperCase()}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='box-reply pt-3'>
                            <div className='mb-2 px-3'>
                                From: &nbsp;<span className='text-[#111827] font-medium capitalize'>
                                    {userRole === "Buyer" ? requestData?.buyer_name : requestData?.responder_name}
                                </span>
                            </div>
                            <Form.Item
                                name="body"
                                className='mb-0'
                                style={{
                                    height: 80,
                                }}
                                rules={[{ required: true, message: '' }]}
                            >
                                <TextArea
                                    placeholder=""
                                    value={textAreaValue}
                                    onChange={handleTextAreaChange}
                                    style={{
                                        height: 80,
                                        resize: 'none',
                                    }}
                                />
                            </Form.Item>
                            <div className='px-3 w-auto'>
                                {fileList && fileList?.length > 0 && (
                                    <div>
                                        {fileList.map((file) => (
                                            <div key={file?.uid} className="uploaded-files w-fit">
                                                <div className="uploaded-file uploaded-file-style flex justify-start items-center mb-3 gap-2">
                                                    <div className="flex flex-row gap-2 items-center w-auto">
                                                        <PaperClipOutline color="#7B7B7B" height={15} />
                                                        <span>{file?.name}</span>
                                                    </div>
                                                    <CloseOutlined onClick={() => handleRemoveFile(file)} className="cursor-pointer" />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className='bottom-notes flex justify-between items-center px-3 notes-field py-3 rounded-none'>
                                <Form.Item
                                    name="attachments"
                                    valuePropName="fileList"
                                    getValueFromEvent={(e) => Array.isArray(e) ? e : e && e.fileList}
                                >
                                    <Upload
                                        listType="text"
                                        beforeUpload={() => false}
                                        onChange={handleFileChange}
                                        fileList={fileList}
                                    >
                                        <div className="flex flex-row gap-2 items-center text-black cursor-pointer">
                                            <PaperClipOutline color="#000" height={20} /> Attach Files
                                        </div>
                                    </Upload>
                                </Form.Item>
                                <div className='flex gap-4 items-center'>
                                    <span
                                        className='text-[#333333] font-bold cursor-pointer'
                                        onClick={() => {
                                            form.resetFields();
                                            setFileList([]);
                                            setReplyEnabled(true);
                                            setTextAreaValue('');
                                        }}
                                    >
                                        Cancel
                                    </span>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        onClick={handleSubmit}
                                        disabled={!textAreaValue.trim() || submitting}
                                    >
                                        Send
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            )}
        </div>
    );
}

export default Reply;