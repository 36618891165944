import React, { useState, useEffect, createContext, useContext } from 'react';
import axios from 'axios';
import config from '../confiq/confiq';

const UserRoleContext = createContext();

export const useUserRole = () => useContext(UserRoleContext);

const UserProvider = ({ children }) => {
    const [userRole, setUserRole] = useState();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${config.apiUrl}api/user/info`, {
                    headers: config.headers,
                });
                const userdetail = response?.data?.data;
                setUserRole(userdetail?.role?.name);
            } catch{
                // console.log(error);
            }
        };

        fetchData();
    }, []);

    return (
        <UserRoleContext.Provider value={userRole}>
            {children}
        </UserRoleContext.Provider>
    );
};

export default UserProvider;
