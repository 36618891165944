import axios from "axios";
import config from "../../confiq/confiq";

export const getServicePartner = async () => {
  try {
    const response = await axios.get(
      `${config.apiUrl}api/vendorService/partner`,
      {
        headers: config.headers,
      }
    );
    return response.data;
  } catch (error) {
    // Handle error here
    console.error("Error fetching data: ", error);
    throw error;
  }
};

export const getAreaList = async () => {
  try {
    const response = await axios.get(
      `${config.apiUrl}api/vendorService/expert`,
      {
        headers: config.headers,
      }
    );
    return response.data;
  } catch (error) {
    // Handle error here
    console.error("Error fetching data: ", error);
    throw error;
  }
};

export const validateEmail = async (emailBody) => {
  try {
    const response = await axios.post(
      `${config.apiUrl}api/user/partner_email/validate`,
      emailBody,
      {
        headers: config.headers,
      }
    );
    return response.data;
  } catch (error) {
    // Handle error here
    console.error("Error fetching data: ", error);
    throw error;
  }
};

export const postPartnerProgram = async (payload) => {
  try {
    const response = await axios.post(
      `${config.apiUrl}api/vendor/apply`,
      payload
    );
    return response?.data;
  } catch (error) {
    // Handle error here
    console.error("Error fetching data: ", error);
    throw error;
  }
};
export const postPartnerProgramRegister = async (payload) => {
  try {
    const response = await axios.post(
      `${config.apiUrl}api/vendor/updateInfo`,
      payload,
      {
        headers: config.headers,
      }
    );
    return response?.data;
  } catch (error) {
    // Handle error here
    console.error("Error fetching data: ", error);
    throw error;
  }
};

