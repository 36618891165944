import React, { useState } from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
} from 'antd';
import {
    GIcon, Saas22Logo,
    signInIllustration2
} from '../../assets/img';
import {
    // Link, useLocation,
    useNavigate
} from 'react-router-dom';
import config from '../../confiq/confiq';
import axios from 'axios';
import { ErrorMessage } from './signError';
import { useAuth } from '../../context';

const SignIn = () => {
    const [emailError, setEmailError] = useState(null);
    // const location = useLocation();
    const navigate = useNavigate();
    const onFinish = async (values) => {
        const payload = {
            email: values?.email,
        };
        try {
            const response = await axios.post(`${config.apiUrl}api/user/email/validate`, payload);

            if (response?.data?.statusCode === 201) {
                const path = `/welcomeBackSignIn`;
                const state = {
                    email: values?.email,
                }
                navigate(path, { state });

            } else if (response?.data?.statusCode === 400) {
                setEmailError('Sorry, we couldn’t find an account. Please double-check and try again.')
            }

        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className='container mx-auto sign-page'>
            <AuthLogo />
            <Row gutter={16}>
                <Col xs={24} sm={12} className='flex items-center text-center justify-center'>
                    <div>
                        <ErrorMessage error={emailError} />
                        <div className="sign-card">
                            <div className='mb-8'>
                                <h2 className="text-3xl font-bold mb-3 text-black">Sign in to your account</h2>
                                {/* {location?.state && location?.state?.signinHide !== undefined ? (
                                    null
                                ) :
                                    <div className=''>
                                        Don’t have an account? <Link to={'/signup'} className="text-blue-600 font-semibold">Sign Up</Link>
                                    </div>
                                } */}
                            </div>
                            <div>
                                <a href={`${config.apiUrl}api/auth/google`}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <Button block className="flex items-center justify-center google-btn">
                                        <img src={GIcon} alt='google-icon' className='g-icon' /> Google
                                    </Button>
                                </a>
                            </div>
                            <Divider>Or continue with</Divider>
                            <Form
                                name="signinForm"
                                onFinish={onFinish}
                                layout="vertical"
                            >
                                <Form.Item
                                    label="Business Email"
                                    name="email"
                                    className='mb-10'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your business email!',
                                        },
                                        {
                                            type: 'email',
                                            message: 'Please enter a valid email address!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input placeholder="Enter your business email"
                                    />
                                </Form.Item>

                                <Form.Item className='mt-4'>
                                    <Button htmlType="submit" className='purple-btn' block>
                                        Sign In
                                    </Button>
                                </Form.Item>
                                <div className='mt-2'>
                                    By signing up, you agree to our <a href="https://www.saas22.com/terms-and-conditions" className="text-blue-600 font-semibold" target="_blank" rel="noopener noreferrer">Terms & conditions</a>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col xs={24} sm={12} className='hidden md:flex items-center text-center justify-center'>
                    <img src={signInIllustration2} alt='signInIllustration' className='w-3/4' />
                </Col>
            </Row>

        </div >
    );
};


export const AuthLogo = () => {
    const navigate = useNavigate();
    const { userToken } = useAuth();

    const handleLogoOnclick = () => {
        if (userToken) {
            navigate('/home')
        } else {
            navigate('/resources')
        }
    }
    return (
        <div className='auth-logo-parent fixed top-0 left-0 bg-white w-full pl-6 py-2 pt-3 z-10'>
            <img src={Saas22Logo} alt='auth-logo' className='auth-logo cursor-pointer' onClick={handleLogoOnclick} />
        </div>
    );
};

export default SignIn;