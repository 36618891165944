import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";

function MainLayout({ children, online }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [isSignInOrSignUpPage, setIsSignInOrSignUpPage] = useState(false);
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsSignInOrSignUpPage(
      hiddenHeaderAndSidebarPaths?.includes(location?.pathname)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const toggleSidebar = () => {
    setTimeout(() => {
      setSidebarOpen(!sidebarOpen);
    }, 100);
    setIsActive(!isActive);
  };
  const handleSidebarToggle = () => {
    setIsActive(!isActive);
  };

  const hiddenHeaderAndSidebarPaths = [
    "/signin",
    "/admin/signin",
    "/signup",
    "/onboardingForm",
    "/welcomeBackSignIn",
    "/setPassword",
    "/emailVerification",
    "/linkExpired",
    "/passwordRecovery",
    "/resetPassword",
    "/404",
    "/no-internet",
    "/partnerProgramForm",
    "/registrationPortal",
    "/successPage",
    "/invalidLogin",
    "/applyToJoinForm"
  ];
  const isPathExcluded = !hiddenHeaderAndSidebarPaths?.includes(
    location.pathname
  );

  return (
    <div className="flex flex-col">
      {!isSignInOrSignUpPage && online && (
        <Header
          isSidebar={sidebarOpen}
          sidebarOpen={toggleSidebar}
          sidebarClose={toggleSidebar}
        />
      )}

      <div className="flex flex-grow">
        <div className="hidden md:block">
          {!isSignInOrSignUpPage && online && (
            <Sidebar
              className="h-110"
              sidebarOpen={sidebarOpen}
              isActive={isActive}
              handleSidebarToggle={handleSidebarToggle}
            />
          )}
        </div>

        <main
          className={`flex-grow overflow-x-hidden overflow-y-auto py-4 pl-4 pr-4 container mx-auto ${isPathExcluded ? "pr-4 md:pr-28" : ""
            }`}
        >
          {children}
        </main>
      </div>
    </div>
  );
}

export default MainLayout;
