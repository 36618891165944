import {
    SortableContext,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {
    Badge,
    message
} from "antd";
import {
    MailOutline,
    PhoneOutline
} from "heroicons-react";
import { CSS } from "@dnd-kit/utilities";
import { Link } from "react-router-dom";

export const SectionItem = (props) => {
    const { id, items, name, data, isSortingContainer, dragOverlay } = props;
    const {
        //active,
        attributes,
        isDragging,
        listeners,
        //over,
        setNodeRef,
        setActivatorNodeRef,
        transition,
        transform
    } = useSortable({
        id: id,
        data: {
            type: "SECTION"
        }
    });

    const getColumnHeight = () => {
        let h = document.getElementsByClassName("kanban-column")[0].clientHeight;
        return h;
    };

    const style = {
        transform: CSS.Translate.toString(transform),
        height: dragOverlay ? `${getColumnHeight() + "px"}` : null,
        transition,
        opacity: isDragging ? 0.5 : 1,
        boxShadow: dragOverlay
            ? "0 0 0 calc(1px / 1) rgba(63, 63, 68, 0.05), -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)"
            : "",
        touchAction:
            "ontouchstart" in window ||
                navigator.MaxTouchPoints > 0 ||
                navigator.msMaxTouchPoints > 0
                ? "manipulation"
                : "none"
    };

    return (
        <div
            ref={setNodeRef}
            className="kanban-column"
            style={style}
        //{...attributes}
        //{...listeners}
        >
            <div
                ref={setActivatorNodeRef}
                {...attributes}
                {...listeners}
                className="kanban-column-header flex items-center"
                style={{
                    cursor: dragOverlay ? "grabbing" : "grab"
                }}
            >
                {name}
                <Badge
                    count={items?.length ? items?.length : 0}
                    showZero={true}
                    className="kanban-view-badge"
                    style={{
                        backgroundColor: "#E0E7FF",
                        color: "#3730A3",
                        marginLeft: "10px",
                        border: "1px solid #E0E7FF"
                    }}
                />
            </div>
            <div className="kanban-column-list">
                <div className='kanban-div'>
                    <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                    >
                        {items?.map((item, _index) => {
                            return (
                                <FieldItem
                                    id={item}
                                    key={item}
                                    item={data?.filter((d) => "task-" + d?.id === item)[0]}
                                    disabled={isSortingContainer}
                                />
                            );
                        })}
                    </SortableContext>
                </div>
            </div>
        </div>
    );
};

export const FieldItem = (props) => {
    const { id, item, dragOverlay } = props;

    const {
        setNodeRef,
        //setActivatorNodeRef,
        listeners,
        isDragging,
        //isSorting,
        //over,
        //overIndex,
        transform,
        transition,
        attributes
    } = useSortable({
        id: id,
        disabled: props.disabled,
        data: {
            type: "FIELD"
        }
    });

    const getClassNames = (isDragging, dragOverlay) => {
        let classNames = "card";

        if (isDragging) {
            classNames += " dragging";
        }
        if (dragOverlay) {
            classNames += " drag-overlay";
        }

        return classNames;
    };
    
    const className = getClassNames(isDragging, dragOverlay);

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                message.success('Copied successfully')
            })
            .catch(err => {
                console.error('Error copying text to clipboard', err);
            });
    };
    return (
        <div
            ref={props.disabled ? null : setNodeRef}
            className={className}
            style={{
                transform: CSS.Translate.toString(transform),
                transition
            }}
            {...attributes}
            {...listeners}
        >
            <div>
                <div className='text-sm mb-2 text-black font-semibold content-threeline content-oneline'>{item?.title}</div>
                <div className='text-sm  capitalize '>{item?.name}</div>
                {item?.company_name && <div className='flex gap-2 flex-row items-center  Grayish mb-1'>
                    <div className='text-sm'>{item?.buyer_designation}</div>•
                    <div className='text-sm'>{item?.company_name}</div>
                </div>
                }
                <div className='flex justify-between items-center gap-2'>
                    <Link to={`/request/detail/${item?.id}`} className="royal-blue w-fit h-7 flex items-center justify-between">
                        <div className='royal-blue cursor-pointer text-sm font-semibold'>View</div>
                    </Link>
                    <div className='flex items-center gap-3 Grayish'>
                        <Link to={`/request/detail/${item?.id}`} className='w-fit h-7 flex items-center justify-between'>
                            <MailOutline width={18} className="cursor-pointer" />
                        </Link>
                        <div onClick={() => handleCopyToClipboard(item?.buyer_phone)} className="cursor-pointer w-fit py-1 h-7 flex items-center justify-between">
                            <PhoneOutline width={18} className=" hover:text-black" />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};