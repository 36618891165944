import React from 'react';
import { ExclamationOutline } from 'heroicons-react';

export const ErrorMessage = ({ error }) => (
    <div className={`mb-3 error-div flex flex-row gap-2 ${error ? 'visible' : 'hidden'}`}>
        <div>
            <ExclamationOutline color=' #F87171' />
        </div>
        <div className='text-start text-[#B91C1C]'>
            {error}
        </div>
    </div>
);
