import { Tabs } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { fetchAllFavData, fetchFavGuideData, fetchFavStoryData, fetchFavWebfeedtData, fetchFavprojectData } from './apiService';
import EmptyState from '../../components/emptyState';
import { useUserRole } from '../../context/userContext';
import { useAuth } from '../../context';

const DashboardCard = React.lazy(() => import('./card'));

function Myfav() {
    const savedHometabActiveKey = localStorage.getItem('homechildTabKey1');
    const [activeKey, setActiveKey] = useState(savedHometabActiveKey || '1');
    const [data, setData] = useState([]);
    const [dataProject, setDataProject] = useState([]);
    const [dataStory, setDataStory] = useState([]);
    const [dataWebfeed, setDataWebfeed] = useState([]);
    const [dataGuide, setDataGuide] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { userToken } = useAuth();
    const userRole = useUserRole();
    const PAGE_SIZE = 1000;

    useEffect(() => {
        fetchAllFavData(currentPage, { PAGE_SIZE, setData });
        fetchFavprojectData(currentPage, { PAGE_SIZE, setData: setDataProject });
        fetchFavWebfeedtData(currentPage, { PAGE_SIZE, setData: setDataWebfeed });
        fetchFavStoryData(currentPage, { PAGE_SIZE, setData: setDataStory });
        fetchFavGuideData(currentPage, { PAGE_SIZE, setData: setDataGuide });
    }, [currentPage]);

    const renderDashboardCard = (data, label, labelMessage, belowContent) => {
        return data && data.length === 0 ?
            <EmptyState labelMessage={labelMessage} belowContent={belowContent} /> :
            <DashboardCard data={data} Label={label} fav={true} />;
    };

    const items = [
        {
            key: '1',
            label: "All",
            children: renderDashboardCard(data, "Favourites", "Empty", "It seems that you need to mark something as favourite in order to view it here.")
        },
        // ...(userRole !== 'Partner' && userToken
        //     ? [{
        //         key: '2',
        //         label: "Projects",
        //         children: renderDashboardCard(dataProject, "project", "No Projects yet!", "It seems that you need to mark something as favourite in order to view it here.")
        //     },
        //     ]
        //     : []),
        {
            key: '3',
            label: "Web Feed",
            children: renderDashboardCard(dataWebfeed, "webFeed", "No Web Feed yet!", "It seems that you need to mark something as favourite in order to view it here.")
        },
        {
            key: '4',
            label: "Stories",
            children: renderDashboardCard(dataStory, "story", "No Stories yet!", "It seems that you need to mark something as favourite in order to view it here.")
        },
        // {
        //     key: '5',
        //     label: "Guides",
        //     children: renderDashboardCard(dataGuide, "guide", "No Guides yet!", "It seems that you need to mark something as favourite in order to view it here.")
        // },
    ];

    const handleTabChange = (key) => {
        localStorage.setItem('homechildTabKey1', key);
        setActiveKey(key);
    };

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };


    const getItemClass = (key) => {
        switch (key) {
            case '2':
                return 'home-sub-tab-projects';
            case '3':
                return 'home-sub-tab-web-feed';
            case '4':
                return 'home-sub-tab-stories';
            // case '5':
            //     return 'home-sub-tab-guides';
            default:
                return 'home-sub-tab-all';
        }
    };

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <Tabs
                activeKey={activeKey}
                defaultActiveKey="1"
                items={items}
                className={`primary-tab home-sub-tab  ${getItemClass(activeKey)}`}
                onChange={handleTabChange}
            >
                <div className="whats-new-scroll" onScroll={handleScroll}>
                    {items.find(item => item.key === activeKey)?.children}
                </div>
            </Tabs>
        </Suspense>
    );
}

export default Myfav;
