import { DatePicker, Segmented, Select } from 'antd';
import {
    TableOutline,
    TemplateOutline
} from 'heroicons-react';
import React, {
    useState,
    useEffect,
    useCallback
} from 'react';
import SearchInput from '../../components/SearchInput';
import TableView from './tableView';
import FunnelView from './funnelView';
import SupportView from './supportView';
import "./index.scss";
import { dateFormat } from '../../utils';
import axios from 'axios';
import config from '../../confiq/confiq';
import { fetchDataLocation } from './apiService';
import { Pipeline } from '../../assets/img';

const { Option } = Select;
const { RangePicker } = DatePicker;

function Request({ props }) {
    const [selectedView, setSelectedView] = useState('3');
    const [searchQuery, setSearchQuery] = useState(null);
    const [getFetchData, setGetFetchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState("");
    const [company, setCompany] = useState(null);
    const [companyData, setCompanyData] = useState([]);
    const [selectedDateRange, setSelectedDateRange] = useState([]);
    const [tasksTotal, setTasksTotal] = useState(null);

    useEffect(() => {
        const savedView = localStorage.getItem('selectedView');
        if (savedView) {
            setSelectedView(savedView);
        }
        fetchDataLocation(setCompanyData);
    }, []);

    const startDate = selectedDateRange[0]?.toISOString().split('T')[0] || null;
    const endDate = selectedDateRange[1]?.toISOString().split('T')[0] || null;
    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${config.apiUrl}api/vendor/getTickets?startDate=${startDate}&endDate=${endDate}&company=${company}&search=${searchQuery}`,
                {
                    headers: config.headers,
                }
            );
            let newArray = [];

            response?.data?.data?.tickets?.forEach((data) => {
                newArray.push(data?.email);
            });
            setTasksTotal(response?.data?.data?.total);
            setGetFetchData(response?.data?.data?.tickets);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    }, [company, endDate, searchQuery, startDate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const SegmentedValue = [
        {
            value: '1',
            label: "Table View",
            icon: <TableOutline width={20} />,
        },
        {
            value: '2',
            label: "Funnel View",
            icon: <TemplateOutline width={20} />,
        },
        {
            value: '3',
            label: "Support View",
            icon: <img src={Pipeline} alt='Pipeline' />,
        },
    ];

    const handleSegmentedChange = (value) => {
        setSelectedView(value);
        localStorage.setItem('selectedView', value);
    };

    const handleFilterChange = (value, setFilter, setSelectedFilter) => {
        setFilter(value);
        setSelectedFilter(value);
    };

    const resetFilters = () => {
        setSelectedCompany(null);
        setCompany(null);
        setSelectedDateRange([]);
    };

    const handleDateRangeChange = (dates) => {
        const newDates = Array.isArray(dates) ? dates : [];
        setSelectedDateRange(newDates);
    };

    const handleSearchChange = (event) => {
        if (!event.target.value) {
            setSearchQuery(null);
        } else {
            setSearchQuery(event.target.value);
        }
    };

    return (
        <div className='projectRequestpage'>
            <h4 className='text-black font-semibold text-lg mb-4'>Requests</h4>
            <div className="mb-4">
                <SearchInput
                    placeholder="Search"
                    width={300}
                    onChange={handleSearchChange}
                />
            </div>
            <div className='flex justify-between gap-3 mb-5 event-page'>
                <div>
                    <Segmented
                        options={SegmentedValue}
                        onChange={handleSegmentedChange}
                        value={selectedView}
                    />
                </div>
                <div className="grid md:flex flex-row items-center gap-2">
                    <div className="hidden md:flex">
                        {(selectedCompany || selectedDateRange.length > 0) && (
                            <div className="cursor-pointer purple-saas22" onClick={resetFilters}>
                                Clear Filters
                            </div>
                        )}
                    </div>
                    <Select
                        showSearch
                        value={selectedCompany}
                        onChange={(value) =>
                            handleFilterChange(value, setCompany, setSelectedCompany)
                        }
                        placeholder={"Company"}
                        className="custom-select"
                        style={{ width: 150 }}
                    >
                        <Option value="" disabled>
                            Company
                        </Option>
                        {companyData &&
                            companyData.map((item) => (
                                <Option
                                    key={item?.id}
                                    value={item?.company}
                                    className="capitalize"
                                >
                                    {item?.company}
                                </Option>
                            ))}
                    </Select>

                    <RangePicker
                        value={selectedDateRange}
                        format={dateFormat}
                        className="range-picker"
                        onChange={handleDateRangeChange}
                        allowClear={selectedDateRange?.length > 0}
                    />
                </div>
            </div>
            {
                selectedView === '1' ?
                    <TableView getFetchData={getFetchData} loading={loading} tasksTotal={tasksTotal} />
                    :
                    selectedView === '2' ?
                        <FunnelView
                            searchQuery={searchQuery}
                            startDate={startDate}
                            endDate={endDate}
                            company={company}
                            loading={loading}
                            setLoading={setLoading}
                        />
                        :
                        <SupportView
                            searchQuery={searchQuery}
                            startDate={startDate}
                            endDate={endDate}
                            company={company}
                            loading={loading}
                            setLoading={setLoading}
                        />

            }
        </div>
    );
}

export default Request;