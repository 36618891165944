import React from "react";
import { FilterOutline } from "heroicons-react";

const FilterIcon = ({ condition, onClick }) => {
    return (
        <div className={`filter-icon md:hidden flex items-center justify-center ${condition ? 'dot' : ''}`} onClick={onClick}>
            <FilterOutline width={20} />
        </div>
    );
};

export default FilterIcon;

export const ShowingText = ({
    product,
    filteredProduct,
    name
}) => {
    return (
        <div>
            Showing {product && product ? product?.length : 0} of{" "}
            {filteredProduct ? filteredProduct : 0} {name}
        </div>
    );
};
