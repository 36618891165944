/* eslint-disable array-callback-return */
import { Row, Col, Form, Input, Button, Select, Checkbox, Radio } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCountryTimeZoneData } from "../../CommonApiService/commonApi";
import { useAuth } from "../../context";
import { Saas22Logo } from "../../assets/img";
import axios from "axios";
import config from "../../confiq/confiq";
import { postPartnerProgramRegister, } from "./apiService";

const { TextArea } = Input;
const { Option } = Select;

export const RegistrationPortal = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { userToken } = useAuth();
    const { countryWithTimezone } = useCountryTimeZoneData(userToken);
    const [fields, setFields] = useState();
    const [emailFromURL, setEmailFromURL] = useState()

    useEffect(() => {
        const fetchData = async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const emailFromURL = queryParams.get("email");
            setEmailFromURL(emailFromURL);
            if (!emailFromURL) {
                console.error("Email not found in URL");
                return;
            }
            try {
                const response = await axios.get(
                    `${config.apiUrl}api/vendor/applicationinfo?email=${emailFromURL}`,
                    {
                        headers: config.headers,
                    }
                );
                setFields(response?.data?.data?.attributes)
                if (response?.data?.statusCode === 400) {
                    navigate("/linkExpired");
                }
            } catch (error) {
                console.error("Error confirming email:", error);
            }
        };

        fetchData();
    }, [navigate, emailFromURL]);

    useEffect(() => {
        const initialValues = {
            first_Name: fields?.first_Name,
            last_Name: fields?.last_Name,
            title: fields?.title,
            email: (emailFromURL && emailFromURL) || fields?.email,
            company: fields?.company,
            partner_Feature_Consent: fields?.partner_Feature_Consent === "No" ? "no" : fields?.partner_Feature_Consent === "Yes" ? "yes" : fields?.partner_Feature_Consent || "yes",
            update_subscription: fields?.update_Subscription || true,
        };

        form.setFieldsValue(initialValues);

    }, [form, fields, emailFromURL]);

    const onFinish = (values) => {
        const payload = {
            first_name: values.first_Name,
            last_name: values.last_Name,
            email: values.email,
            company: values.company ? values.company : "",
            title: values.title ? values.title : "",
            phone: values.phonenumber,
            country_timezone: values.country,
            social_links: {
                linkedin: values.linkedURL,
            },
            services: values.servicesOffered,
            website: values.website,
            type: "partner",
            description: fields?.additional_Info || "",
            updates_subscription: fields?.update_subscription || values.update_subscription,
            blog_subscription: fields?.partner_Feature_Consent === "Yes" ? true : fields?.partner_Feature_Consent === "No" ? false : values.partner_Feature_Consent === "yes" ? true : values.partner_Feature_Consent === "no" ? false : true,
            image_id: null
        };

        postPartnerProgramRegister(payload).then((data) => {
            if (data?.statusCode === 201) {
                navigate("/successPage");
            }
        });
    };

    const handleLogoOnclick = () => {
        window.location.href = 'https://www.saas22.com/partners';
    }

    return (
        <div className="pt-8 items-center flex flex-col gap-6 partner-page">
            <div className='auth-logo-parent fixed top-0 left-0 bg-white w-full pl-6 py-2 pt-3 z-10'>
                <img src={Saas22Logo} alt='auth-logo' className='auth-logo cursor-pointer' onClick={handleLogoOnclick} />
            </div>
            <div className="mt-10 md:mt-0 md:w-10/12 w-full">
                <h1 className="text-xl md:text-3xl font-bold mb-5 text-center">Registration for SaaS22 Partner Portal</h1>
                <Form
                    name="partner_program"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    className="bg-whitemx-auto rounded px-2 md:px-8 pb-8 mb-4 "
                >
                    <Form.Item className="mb-2">
                        <p className="text-lg md:text-xl font-semibold">Personal Information</p>
                    </Form.Item>
                    <Row gutter={16}>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="first_Name"
                                label="First Name"
                                rules={[
                                    { required: true, message: "" },
                                    { max: 30, message: 'Maximum 30 characters allowed' },
                                ]}
                            >
                                <Input placeholder="Enter the first name" disabled={!!fields?.first_Name} />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="last_Name"
                                label="Last Name"
                                rules={[{ required: true, message: "" },
                                { max: 30, message: 'Maximum 30 characters allowed' },]}
                            >
                                <Input placeholder="Enter the last name" disabled={!!fields?.last_Name} />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="country"
                                label="Country / Timezone"
                                rules={[{ required: true, message: "" }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select Country / Timezone"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {countryWithTimezone && countryWithTimezone?.map((location) => (
                                        <Option key={location?.id} value={location['country&timezone']}>
                                            {location['country&timezone']}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="phonenumber"
                                label="Phone Number"
                                rules={[
                                    { required: true, message: "" },
                                    { max: 15, message: 'Maximum 15 characters allowed' },
                                    () => ({
                                        validator(_, value) {
                                            const phoneNumerRegex = /^[0-9]{8,15}$/;
                                            if (value && !phoneNumerRegex.test(value)) {
                                                return Promise.reject(
                                                    new Error("Please enter a valid Phone number")
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    placeholder="Enter the phone number"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    { required: true, message: "" },
                                    {
                                        type: "email",
                                        message: "Please enter a valid email address",
                                    },
                                ]}
                            >
                                <Input placeholder="Enter your work email" disabled={!!fields?.email || emailFromURL} />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="title"
                                label="Title"
                                rules={[{ required: true, message: "" },
                                { max: 50, message: 'Maximum 50 characters allowed' },]}
                            >
                                <Input placeholder="Eg: Product Manager" disabled={!!fields?.title} />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="company"
                                label="Company"
                                rules={[{ required: true, message: "" },
                                { max: 100, message: 'Maximum 100 characters allowed' },]}
                            >
                                <Input placeholder="Enter the company name" disabled={!!fields?.company} />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="linkedURL"
                                label="LinkedIn URL"
                                rules={[
                                    { required: true, message: '' },
                                ]}
                            >
                                <Input placeholder="Enter the URL" />
                            </Form.Item>
                        </Col>
                        <Form.Item className="mb-2">
                            <p className="text-xl font-semibold ">Additional Information</p>
                        </Form.Item>
                        <Col span={24} md={24}>
                            <Form.Item
                                name="servicesOffered"
                                label="Services Provided"
                                rules={[{ required: true, message: "" }]}
                            >
                                <TextArea
                                    maxLength={400}
                                    style={{ height: 120, resize: "none" }}
                                    placeholder="Eg: ERP Consulting and SI, Web Development, ... "
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={12}>
                            <Form.Item
                                name="website"
                                label="Website"
                                rules={[
                                    { required: true, message: "" },
                                    () => ({
                                        validator(_, value) {
                                            const urlPattern =
                                                /(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[\dA-Za-z]{2,}(\.[\dA-Za-z]{2,})(\.[\dA-Za-z]{2,})?/;

                                            if (value && !urlPattern.test(value)) {
                                                return Promise.reject(
                                                    new Error("Please enter a valid URL")
                                                );
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input placeholder="Website URL" />
                            </Form.Item>
                        </Col>

                        <Col span={24} md={24}>
                            <Form.Item name="update_subscription" valuePropName="checked" >
                                <Checkbox disabled={fields?.update_Subscription === true || fields?.update_Subscription === false}>
                                    I'd like to sign up for the latest news and updates from SaaS22
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24} md={24}>
                            <Form.Item
                                name="partner_Feature_Consent"
                                label="We will be running a blog which features Partners from across the globe. Would you like to be featured?"
                                rules={[
                                    {
                                        required: true,
                                        message: "",
                                    },
                                ]}
                            >
                                <Radio.Group disabled={fields?.partner_Feature_Consent}>
                                    <Radio value="yes">Yes</Radio>
                                    <Radio value="no">No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item className="flex justify-center gap-4 items-center ">
                        <div className="flex justify-center items-center">
                            <Button
                                type="primary"
                                className="ant-btn-secondary"
                                htmlType="button"
                            >
                                Cancel
                            </Button>
                            <Button className="ml-4" type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};