import React, {
    useEffect,
    useState
} from 'react';
import {
    Steps,
    Row,
    Col,
    message,
    Spin
} from 'antd';
import axios from 'axios';
import config from '../../../confiq/confiq';
import { AuthLogo } from '../signIn';
import { OnboardStep1 } from './step1';
import { OnboardStep2 } from './step2';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from '../../../CommonApiService/commonApi';
import { useAuth } from '../../../context';

const OnboardingForm = () => {
    const { userToken } = useAuth();
    const { userInfo } = useUserInfo(userToken);
    const [currentStep, setCurrentStep] = useState(() => {
        switch (userInfo?.role?.name) {
            case 'Partner':
                window.location.reload();
                return 1;
            case 'Buyer':
                window.location.reload();
                return 2;
            default:
                return 0;
        }
    });
    const [loading, setLoading] = useState(true);
    const [imageId, setImageId] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setLoading(false);
        }, 2000);
        return () => {
            clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        if (userInfo && userInfo?.role?.name === 'Partner') {
            setCurrentStep(1);
        }
    }, [userInfo]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const userToken = urlParams.get('access_token');
                const userRole = urlParams.get('role_name');
                const onboardingStatus = urlParams.get('onboarding_status');

                if (userToken && userRole && onboardingStatus) {
                    localStorage.setItem('userToken', userToken);
                    localStorage.setItem('userRole', userRole);

                    switch (userRole) {
                        case 'Admin':
                            navigate('/home');
                            if (window.location.pathname === '/home') {
                                window.location.reload();
                            }
                            break;
                        case 'Partner':
                            if (onboardingStatus === 'false') {
                                setCurrentStep(1);
                            } else if (onboardingStatus === 'true') {
                                navigate('/home');
                                if (window.location.pathname === '/home') {
                                    window.location.reload();
                                }
                            }
                            break;
                        case 'Buyer':
                            if (onboardingStatus === 'true') {
                                navigate('/home');
                                if (window.location.pathname === '/home') {
                                    window.location.reload();
                                }
                            } else if (onboardingStatus === 'false') {
                                navigate('/onboardingForm');
                                if (window.location.pathname === '/onboardingForm') {
                                    window.location.reload();
                                }
                            } else {
                                // message.warning('Please update onboarding form before login');
                            }
                            break;
                        default:
                            break;
                    }
                } else {
                    console.error('Incomplete or missing query parameters');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [navigate, setCurrentStep]);


    const handleFinishStep1 = (values) => {

        setCurrentStep(1);
        const payload = {
            first_name: values?.first_name,
            last_name: values?.last_name,
            email: values?.email,
            company: values?.companyName,
            title: values?.title,
            phone: values?.phone,
            country_timezone: values?.country,
            image_id: userInfo?.image_id || String(imageId) || "",
            social_links: {
                linkedin: values?.linkedin
            },
            reference: values?.hearAboutUs,
            about_company: values?.about_company
        }
        axios.post(`${config.apiUrl}api/user/info`, payload, {
            headers: config.headers,
        })
            .then(response => {
                if (response?.status === 201) {
                    message.success('user Info updated successfully');
                    setCurrentStep(currentStep + 1);
                } else if (response?.status === 409) {
                    message.error('The user already exists.');
                }
            })
            .catch(error => {
                console.error('Error submitting form:', error);
            });
    };

    const steps = [
        {
            content: <OnboardStep1
                handleFinishStep1={handleFinishStep1}
                setImageId={setImageId}
            />,
        },
        {
            content: <OnboardStep2 />,
        },
    ];

    return (
        <div className='container mx-auto '>
            {(loading && userInfo?.email) ? (
                <Spin className='loader' tip='Loading...' />
            ) : (
                <>
                    <AuthLogo />
                    <Row gutter={16} className='justify-center'>
                        <Col span={24} lg={16} sm={24} className='mx-auto '>
                            <div className="form-container">
                                <Steps current={currentStep} size="small" items={steps?.map(step => ({ title: step.title }))} />
                                <div className="steps-content">{steps[currentStep]?.content}</div>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </div>
    );
};

export default OnboardingForm;