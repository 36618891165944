import {
    Spin,
} from 'antd';
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import axios from 'axios';
import config from '../../../confiq/confiq';
import Pagination from '../../../components/paginationSet';
import { WebfeedCard } from '../../../components/Card/webfeed';
import SavedEmpty from '../../../components/SavedEmpty';

export const Favourites = forwardRef((props, ref) => {
    const [product, setProduct] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filteredProduct, setFilteredProduct] = useState();

    const PAGE_SIZE = 10;


    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${config.apiUrl}api/bookmark/webfeed?page=1&pageSize=10&ind=null&fun=null&cat=null&search=null`, {
                headers: config.headers,
            });
            setTotalPages(Math.ceil(response?.data?.data?.meta?.pagination?.total / PAGE_SIZE));
            setProduct(response?.data?.data?.data);
            setFilteredProduct(response?.data?.data?.meta?.pagination?.total);
        } catch (error) {
            console.error('Error fetching bookmark data:', error);
        } finally {
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, PAGE_SIZE]);

    useImperativeHandle(ref, () => ({
        fetchData: fetchData,
    }), [fetchData]);

    useEffect(() => {
        fetchData();
    }, [currentPage, PAGE_SIZE, fetchData]);


    const handlePaginationClick = (type) => {
        setCurrentPage((prevPage) => type === 'prev' ? prevPage - 1 : prevPage + 1);
    };

    return (
        <div className='mt-2'>
            {loading ? (
                <Spin className='loader' tip='Loading...' />
            ) : (
                product && product.length > 0 ? (
                    <WebfeedCard
                        product={product}
                        fetchData={fetchData}
                        setLoading={setLoading}
                    />
                ) : (
                    <SavedEmpty />
                )
            )}
            {product && product.length > 0 ?
                < Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    handlePaginationClick={handlePaginationClick}
                    product={product}
                    filteredProduct={filteredProduct}
                    showingName={'feeds'}
                /> : ""
            }
        </div>
    );
});