import axios from 'axios';
import React, {
    useEffect,
    useState
} from 'react';
import config from '../../confiq/confiq';
import {
    Dropdown,
    message,
    Spin,
    Table,
} from 'antd';
import { DotsVerticalOutline } from 'heroicons-react';
import StatusTag from '../../components/statusTag';
import { UserDetailsDrawer } from './userDetailDrawer';
import { useNavigate } from 'react-router-dom';
import SavedEmpty from '../../components/SavedEmpty';
import InviteUserInput from '../../components/inviteEmail';


function ManageUserPartner(props) {
    const [getPartnerData, setGetPartnerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [selectedUserRole, setSelectedUserRole] = useState(null);
    const navigate = useNavigate();
    const [, setInviteEmails] = useState([]);
    const [emailList, setEmailList] = useState([]);

    const fetchData = () => {
        axios.get(`${config.apiUrl}api/admin/users/partner`, {
            headers: config.headers,
        }
        ).then(response => {
            let newArray = [];
            // eslint-disable-next-line array-callback-return
            response?.data?.data?.map((data) => {
                newArray.push(data?.email);
            });
            setEmailList(newArray);
            setGetPartnerData(response?.data?.data);
            setLoading(false);
        }).catch(error => {
            console.error('Error fetching data:', error);
            setLoading(false);
        });
    }
    useEffect(() => {
        fetchData();
    }, []);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <div className='royal-blue cursor-pointer'
                    onClick={() => {
                        setSelectedUserId(text);
                        setSelectedUserRole(record?.role?.name);
                    }}
                >
                    {record.first_name || record.last_name || 'N/A'}
                </div>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => <StatusTag status={status && status.toLowerCase() === 'revoke' ? 'Revoked' : status} />,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => {
                let items = [];
                switch (record?.status?.toLowerCase()) {
                    case 'approved':
                        items = [
                            {
                                key: '1',
                                label: "View",
                                onClick: () => handleView(record),
                            },  
                            {
                                key: '2',
                                label: "Edit",
                                onClick: () => handleEdit(record),
                            },
                            {
                                key: '3',
                                label: "Revoke",
                                onClick: () => handleRevokeAccess(record, "revoked"),
                            },
                        ];
                        break;
                    case 'rejected':
                        items = [
                            {
                                key: '1',
                                label: "View",
                                onClick: () => handleView(record),
                            },
                            {
                                key: '2',
                                label: "Approve",
                                onClick: () => handleRevokeAccess(record, "approved"),
                            },
                        ];
                        break;
                    case 'revoked':
                        items = [
                            {
                                key: '1',
                                label: "View",
                                onClick: () => handleView(record),
                            },
                            {
                                key: '2',
                                label: "Reinstate",
                                onClick: () => handleRevokeAccess(record, "approved"),
                            },
                        ];
                        break;
                    default:
                        items = [
                            {
                                key: '1',
                                label: "View",
                                onClick: () => handleView(record),
                            },
                        ];
                        break;
                }

                return (
                    <Dropdown
                        menu={{ items }}
                        placement="bottomRight"
                    >
                        <div className="cursor-pointer">
                            <span className="ant-dropdown-link">
                                <DotsVerticalOutline width={18} />
                            </span>
                        </div>
                    </Dropdown>
                );
            },
        }
    ];

    const handleView = (record) => {
        setSelectedUserId(record.id);
        setSelectedUserRole(record?.role?.name);
    };

    const handleEdit = (record) => {
        navigate(`/users/edit-partner/${record?.id}`);
    };

    const handleRevokeAccess = async (record, text) => {
        const status =
            text === "approved" ? "approved" :
                text === "revoke" ? "revoked" :
                    text === "rejected" ? "rejected" :
                        record?.status === "revoke" ? "approved" : "revoked";

        const payload = {
            id: record?.id,
            email: record?.email,
            status: status
        }

        try {
            const response = await axios.post(
                `${config.apiUrl}api/admin/updateStatus`, payload,
                {
                    headers: config.headers,
                }
            );
            if (response?.data?.statusCode === 201) {
                fetchData();
                setSelectedUserId(null);
            }
        } catch (error) {
            console.error('Error updating user status:', error);
        }
    };
    // const handleResendInvite = async (record) => {
    //     try {
    //         const payload = {
    //             data: [{ email: record.email, role: "partner" }],
    //         };

    //         const response = await axios.post(
    //             `${config.apiUrl}api/admin/inviteUser`,
    //             payload,
    //             {
    //                 headers: config.headers,
    //             }
    //         );
    //         if (response?.status === 201) {
    //             message.success("Invite resent successfully.");
    //         }
    //     } catch (error) {
    //         console.error("Error resending invite:", error);
    //         message.error("Failed to resend invite. Please try again.");
    //     }
    // };
    // const handleSendInvite = async (emailsArray) => {
    //     try {
    //         const payload = {
    //             data: emailsArray.map((email) => ({ email, role: "partner" })),
    //         };

    //         const response = await axios.post(
    //             `${config.apiUrl}api/admin/inviteUser`,
    //             payload,
    //             {
    //                 headers: config.headers,
    //             }
    //         );
    //         if (response?.status === 201) {
    //             axios
    //                 .get(`${config.apiUrl}api/admin/users/partner`, {
    //                     headers: config.headers,
    //                 })
    //                 .then((response) => {
    //                     setGetPartnerData(response?.data?.data);
    //                     setInviteEmails([]);
    //                     setLoading(false);
    //                 })
    //                 .catch((error) => {
    //                     console.error("Error fetching data:", error);
    //                     setLoading(false);
    //                 });
    //         }
    //     } catch (error) {
    //         console.error("Error sending invite:", error);
    //         message.error("Failed to send invite. Please try again.");
    //     }
    // };

    return (
        <>
            {/* <InviteUserInput
                handleSendInvite={handleSendInvite}
                setInviteEmails={setInviteEmails}
                emailList={emailList}
                userRole="partner"
            /> */}
            <Table
                dataSource={getPartnerData}
                locale={{ emptyText: <div className="w-40 -mt-14 m-auto"> <SavedEmpty /></div> }}
                columns={columns}
            />

            {selectedUserId && (
                <UserDetailsDrawer
                    userId={selectedUserId}
                    userRole={selectedUserRole}
                    onClose={() => setSelectedUserId(null)}
                    handleRevokeAccess={handleRevokeAccess}
                />
            )}
        </>
    );
};

export default ManageUserPartner;