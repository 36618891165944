import React, { useCallback } from 'react';
import {
    useEffect,
    useState
} from "react";
import Tasks from '../../components/kanban/index.js';
import { getStatusMatch, getTickets } from './apiService.js';
import { Spin } from 'antd';

function FunnelView({
    searchQuery,
    startDate,
    endDate,
    company,
    loading,
    setLoading
}) {
    const [tasks, setTasks] = useState([]);
    const [tasksTotal, setTasksTotal] = useState(null);
    const [columns,] = useState([
        { id: 1, name: "New", order: 1 },
        { id: 2, name: "Engaged", order: 2 },
        { id: 3, name: "Qualified", order: 3 },
        { id: 4, name: "Opportunity", order: 4 },
        { id: 5, name: "Win", order: 5 },
        { id: 6, name: "Loss", order: 6 },
    ]);

    const fetchTasks = useCallback(async () => {
        setLoading(true);
        try {
            const { tickets, totalCount } = await getTickets(
                searchQuery || null,
                startDate || null,
                endDate || null,
                company || null
            );

            const formattedTasks = tickets?.map((ticket) => ({
                id: ticket?.id,
                col_id: getStatusMatch(ticket?.leads_stage) || 1,
                name: ticket?.buyer_name || "N/A",
                priority_id: 1,
                assignees_ids: [],
                company_name: ticket?.company_name || "N/A",
                buyer_email: ticket?.buyer_email || "N/A",
                buyer_designation: ticket?.buyer_designation || "N/A",
                title: ticket?.title || "N/A",
            }));

            setTasksTotal(totalCount);
            setTasks(formattedTasks);
        } catch (error) {
            console.error('Failed to fetch tasks', error);
        } finally {
            setLoading(false);
        }
    }, [searchQuery, startDate, endDate, company, setLoading]);

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    return (
        <div>
            {loading ?
                <div className='flex items-center justify-center h-[200px]'>
                    <Spin className='loader' tip='Loading...' />
                </div>
                :
                <Tasks tasks={tasks} columns={columns} view={"FView"} tasksTotal={tasksTotal} />
            }
        </div>
    );
}

export default FunnelView;