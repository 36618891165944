import React from 'react';
import { Tag } from 'antd';

const StatusTag = ({ status }) => {
    let tagColor;

    switch (status?.toLowerCase()) {
        case 'deleted':
            tagColor = 'delete';
            break;
        case 'close':
            tagColor = 'close';
            break;
        case 'open':
            tagColor = 'open';
            break;
        case 'partner':
            tagColor = 'open';
            break;
        case 'expert':
            tagColor = 'expert';
            break;
        case 'access revoked':
            tagColor = 'revoked';
            break;
        case 'revoked':
            tagColor = 'revoked';
            break;
        case 'revoke':
            tagColor = 'revoked';
            break;
        case 'rejected':
            tagColor = 'rejected';
            break;
        case 'pending':
            tagColor = 'pending';
            break;
        case 'invited':
            tagColor = 'pending';
            break;
        case 'Accepted':
            tagColor = 'approved';
            break;
        case 'accepted':
            tagColor = 'approved';
            break;
        case 'active':
            tagColor = 'blue';
            break;
        case 'approved':
            tagColor = 'approved';
            break;
        case 'inprogress':
            tagColor = 'approved';
            status = 'In Progress';
            break;
        case 'admin':
            tagColor = 'admin';
            break;
        case 'webFeed':
            tagColor = 'webFeed';
            break;
        case 'webfeed':
            tagColor = 'webFeed';
            break;
        case 'project':
            tagColor = 'project';
            break;
        case 'guide':
            tagColor = 'guide';
            break;
        case 'story':
            tagColor = 'story';
            break;
        case 'in review':
            tagColor = 'open';
            break;
        default:
            tagColor = 'default';
    }

    return <Tag className={tagColor === 'default' ? 'capitalize' : `${tagColor}-tag capitalize`}>{status}</Tag>;
};

export default StatusTag;
