import React from 'react';
import { Input } from 'antd';
import { SearchOutline, XOutline } from 'heroicons-react';

export default function SearchInput(props) {
    return (
        <div className='flex md:block gap-2 w-full'>
            <Input
                allowClear={{ clearIcon: <XOutline className='search-suffixClear' /> }}
                prefix={<SearchOutline className='search-prefix' />}
                size='small'
                onChange={(e) => props.onChange(e.target.value)}
                {...props}
            />
        </div>
    );
}