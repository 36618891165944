import React from "react";
import { Button, Drawer } from "antd";
import FilterShowtext from "../../../components/filter";

const StoriesDrawer = ({
    open,
    setOpen,
    selectedFunction,
    selectedIndustry,
    selectedCategory,
    resetFilters,
    handleFilterChange,
    setFunctionFilter,
    setSelectedFunction,
    setIndustryFilter,
    setSelectedIndustry,
    setCategoryFilter,
    setSelectedCategory,
    filteredProduct,
    product,
}) => {
    return (
        <Drawer
            title={"Filters"}
            placement={"bottom"}
            className="drawer-filter"
            height={450}
            open={open}
            onClose={() => setOpen(false)}
            footer={
                <div className="flex md:hidden w-full">
                    <Button
                        type="secondary"
                        className="w-full"
                        onClick={() => {
                            resetFilters();
                            setOpen(false);
                        }}
                        disabled={!(selectedFunction || selectedIndustry || selectedCategory)}
                    >
                        Clear Filters
                    </Button>
                </div>
            }
        >
            <FilterShowtext
                filteredProduct={filteredProduct}
                product={product}
                selectedFunction={selectedFunction}
                selectedIndustry={selectedIndustry}
                selectedCategory={selectedCategory}
                resetFilters={resetFilters}
                handleFilterChange={handleFilterChange}
                setFunctionFilter={setFunctionFilter}
                setSelectedFunction={setSelectedFunction}
                setIndustryFilter={setIndustryFilter}
                setSelectedIndustry={setSelectedIndustry}
                setCategoryFilter={setCategoryFilter}
                setSelectedCategory={setSelectedCategory}
                functionTrue={true}
                showingName={"stories"}
            />
        </Drawer>
    );
};

export default StoriesDrawer;