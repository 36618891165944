import React, { useEffect } from "react";
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import SignIn from "../pages/auth/signIn";
import SignUp from "../pages/auth/signUp";
import OnboardingForm from "../pages/auth/onboarding/onboardingForm";
import EmailVerification from "../pages/auth/sendEmailVerification";
import SetPassword from "../pages/auth/setPassword";
import WelcomeBackSignIn from "../pages/auth/welcomeBack";
import LinkExpired from "../pages/auth/linkExpired";
import Home from "../pages/home";
import Projects from "../pages/projects";
import Resources from "../pages/resources";
// import Jobs from "../pages/jobs";
import Profile from "../pages/profile";
import Content from "../pages/content";
import Users from "../pages/users";
import Inquiries from "../pages/inquiries";
import ResourceDetailPage from "../pages/resources/detailPage";
import ProjectsDetailPage from "../pages/projects/detailPage";
import { useAuth } from "../context";
import Notfound from "../pages/Notfound";
import MyRequestDetail from "../pages/projects/myRequestDetail";
import InquiriesDetail from "../pages/inquiries/detail";
import PartnerEdit from "../pages/users/partnerEdit";
import NoInternet from "../pages/noInternet";
import PasswordRecovery from "../pages/auth/passwordRecovery";
import ResetPassword from "../pages/auth/resetPassword";
import { PartnerProgramFormPage } from "../pages/forms/partnerProgramForm";
import AddTools from "../pages/profile/addTools";
import SuccessPage from "../pages/forms/successPage";
import AdminSignIn from "../pages/auth/adminSignIn";
// import JobDetail from "../pages/jobs/detail";
// import ApplicationList from "../pages/jobs/applicationList";
import { useUserRole } from "../context/userContext";
import Events from "../pages/events";
import Deals from "../pages/deals";
import ViewAllDeals from "../pages/deals/detailList";
// import { useUserInfo } from "../CommonApiService/commonApi";
import InvalidLogin from "../pages/auth/invalidLogin";
import ApplyToJoin from "../pages/applyToJoin";
import Request from "../pages/request";
import RequestDetails from "../pages/request/details";
// import AccessDenied from "../pages/accessDenied";
import { RegistrationPortal } from "../pages/forms/registrationPortal";

function AppRoutes() {
  const { login, userToken } = useAuth();
  // const { userInfo } = useUserInfo(userToken);
  const userRole = useUserRole();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!login && userRole) {
      login(userRole);
    } else if (
      location.pathname === "/users" &&
      !localStorage.getItem("userToken")
    ) {
      navigate(`/signin?path=${location.pathname}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login, userRole]);

  // useEffect(() => {
  //   if (
  //     userToken &&
  //     userInfo?.onboarding_status === true &&
  //     location.pathname !== "/onboardingForm" &&
  //     location.pathname !== "/partnerProgramForm" &&
  //     location.pathname !== "/applyToJoinForm" &&
  //     ((userRole && userRole === "Buyer") ||
  //       (userRole && userRole === "Partner")) &&
  //     !document.querySelector("#widgetScript")
  //   ) {
  //     const script = document.createElement("script");
  //     script.src = "//in.fw-cdn.com/31764612/906616.js";
  //     script.chat = "true";
  //     script.id = "widgetScript";
  //     document.body.appendChild(script);
  //     script.onload = () => {
  //       window.fwcrm.on("user:created", function () {
  //         window.fcWidget.setExternalId("dev@saas22.com");
  //         window.fcWidget.user.setFirstName(userInfo?.first_name);
  //         window.fcWidget.user.setLastName(userInfo?.last_name);
  //         window.fcWidget.user.setEmail(userInfo?.email);
  //       });
  //     }
  //   }
  // }, [userToken, userRole, userInfo, location]);

  const PrivateRoute = ({ element }) => {
    if (login === null && !nonSignInState.includes(location?.pathname)) {
      return <Navigate to="/signin" />;
    }

    return login ? element : <Navigate to="/signin" />;
  };

  const nonSignInState = [
    "/signin",
    "/admin/signin",
    "/signup",
    "/onboardingForm",
    "/welcomeBackSignIn",
    "/setPassword",
    "/emailVerification",
    "/linkExpired",
    "/passwordRecovery",
    "/resetPassword",
    "/404",
    "/partnerProgramForm",
    "/successPage",
    "/invalidLogin",
  ];

  // const allowForAllUsers = location?.pathname.startsWith("/resources");

  // if (!nonSignInState?.includes(location?.pathname) && !userToken && !allowForAllUsers) {
  //   return <Navigate to="/404" />;
  // }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            userToken && userRole ? (
              <Navigate to="/home" />
            ) : (
              <Navigate to="/resources" />
            )
          }
        />

        {/* Auth routes */}
        <Route path="/signin" element={<SignIn />} />
        <Route path="/admin/signin" element={<AdminSignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/onboardingForm" element={<OnboardingForm />} />
        <Route path="/emailVerification" element={<EmailVerification />} />
        <Route path="/setPassword" element={<SetPassword />} />
        <Route path="/welcomeBackSignIn" element={<WelcomeBackSignIn />} />
        <Route path="/linkExpired" element={<LinkExpired />} />
        <Route path="/passwordRecovery" element={<PasswordRecovery />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/invalidLogin" element={<InvalidLogin />} />
        <Route path="/applyToJoinForm" element={<ApplyToJoin />} />
        {/* non sign in-state pages */}
        <Route path="/resources" element={<Resources />} />
        <Route path="/resources/:pageName/:id/:slug" element={<ResourceDetailPage />} />
        <Route
          path="/partnerProgramForm"
          element={<PartnerProgramFormPage />}
        />
        <Route
          path="/registrationPortal"
          element={<RegistrationPortal />}
        />
        <Route path="successPage" element={<SuccessPage />} />
        <Route
          path="/events"
          element={<PrivateRoute element={<Events />} />}
        />
        {login &&
          <Route path="/home" element={<PrivateRoute element={<Home />} />} />
        }
        {/* Private routes */}
        {login && userRole && (
          <>
            {userRole === "Buyer" &&
              <>
                <Route
                  path="/projects"
                  element={<PrivateRoute element={<Projects />} />}
                />
                {/* <Route
                  path="/projects/:id/:slug"
                  element={<PrivateRoute element={<ProjectsDetailPage />} />}
                /> */}
              </>
            }
            {/* <Route path="/home" element={<PrivateRoute element={<Home />} />} /> */}

            <Route
              path="/discount"
              element={<PrivateRoute element={<Deals />} />}
            />
            <Route
              path="/discount/viewAll"
              element={<PrivateRoute element={<ViewAllDeals />} />}
            />
            {userRole !== "Partner" ? (
              <>

                <Route
                  path="/projects/my-request/:id"
                  element={<PrivateRoute element={<MyRequestDetail />} />}
                />
                {/* <Route
                  path="/jobs"
                  element={<PrivateRoute element={<Jobs />} />}
                />
                <Route
                  path="/jobs/:id"
                  element={<PrivateRoute element={<JobDetail />} />}
                /> */}
              </>
            ) :
              <>
                <Route
                  path="/request"
                  element={<PrivateRoute element={<Request />} />}
                />
                <Route
                  path="/request/detail/:id"
                  element={<PrivateRoute element={<RequestDetails />} />}
                />
              </>
            }

            {userRole === "Admin" ? (
              <>
                {/* <Route
                  path="/jobs/applications/:id"
                  element={<PrivateRoute element={<ApplicationList />} />}
                /> */}
                <Route
                  path="/content"
                  element={<PrivateRoute element={<Content />} />}
                />
                <Route
                  path="/users"
                  element={<PrivateRoute element={<Users />} />}
                />
                <Route
                  path="/inquiries"
                  element={<PrivateRoute element={<Inquiries />} />}
                />
                <Route
                  path="/inquiries/:id"
                  element={<PrivateRoute element={<InquiriesDetail />} />}
                />

                <Route
                  path="/users/edit-partner/:id"
                  element={<PrivateRoute element={<PartnerEdit />} />}
                />
              </>
            ) : (
              <>
                <Route
                  path="/profile"
                  element={<PrivateRoute element={<Profile />} />}
                />
                <Route
                  path="/profile/add"
                  element={<PrivateRoute element={<AddTools />} />}
                />
              </>
            )}
          </>
        )}

        <Route path="/404" element={<Notfound />} />
        <Route path="/no-internet" element={<NoInternet />} />
        <Route path="*" element={<Notfound />} />

      </Routes>
    </>
  );
}

export default AppRoutes;
